import React from 'react';
import { InviteTokenDTO } from 'types/user';

export function JoinCommunityAsUserMessage({ token }: { token: InviteTokenDTO }) {
  return (
    <p className="text-x-large u-section-spacing-y">
      Meet companies and investors, evaluate and connect{' '}
      {token ? (
        <>
          in the <b>{token.companyName}</b> community
        </>
      ) : (
        ''
      )}{' '}
      at CrowdWorks.
    </p>
  );
}
