import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CalendarIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18 2v2M6 2v2M2.5 12.2c0-4.3 0-6.5 1.3-7.8C5 3 7 3 11 3h1.8c4 0 6.1 0 7.3 1.4 1.3 1.3 1.3 3.5 1.3 7.8v.6c0 4.3 0 6.5-1.3 7.8C19 22 17 22 13 22h-1.8c-4 0-6.1 0-7.3-1.4-1.3-1.3-1.3-3.5-1.3-7.8v-.6ZM3 8h18" />
    </SvgIcon>
  );
}
