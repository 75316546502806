import React, { useState } from 'react';
import Layout from 'ui/views/layouts/BaseLayout';
import Network from './network';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import Resources from 'util/resource/Resources';
import { DashboardData, DashboardMessage } from 'apis/DashboardAPI';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { CompanyListItem, ICompany } from 'types/company';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { userUrls } from 'urls';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import featureToggle from 'featureToggle';
import { LoadingScreen } from 'auth/AuthenticatedRoutes';
import { SelfUserProfile } from 'types/user';
import Tabs from 'ui/modules/tabs/Tabs';
import Tab from 'ui/modules/tabs/Tab';
import useRoute from 'hooks/useRoute';
import NotFound from 'pages/common/error/NotFound';
import UserUserConversationDialog from 'domain/conversations/UserUserConversationDialog';
import { Home } from './Home';
import { Manage } from './manage/Manage';
import { companiesCommunitiesByRole } from 'domain/companies/companyUtils';
import { useUserDashboardData } from './useUserDashboardData';
import { useFeaturedMatches } from 'apis/CompanyAPI/users/useFeaturedMatches';
import Shortlists from 'pages/Shortlists';
import { MyCommunity } from 'types/company/community';
import useWindowWidth from 'hooks/useWindowWidth';
import { contentSpacing } from 'ui/theme/themeConstants';

export default function Dashboard() {
  const companiesResource = useCompanies();
  const communitiesResource = useCommunities();
  const { resource: userResource } = useSelfUserProfile();
  const { resource: matchesResource } = useFeaturedMatches();
  const { resource: dashboardDataResource } = useUserDashboardData();

  return (
    <Resources
      resources={[
        companiesResource.resource,
        communitiesResource.resource,
        dashboardDataResource,
        userResource,
        matchesResource, // Eager load for a smoother experience 😎
      ]}
      renderLoading={() => (
        <div className="u-flex u-flex-center u-flex-grow">
          <LoadingScreen />
        </div>
      )}
    >
      {([companies, communities, dashboardData, userProfile]) => (
        <DashboardContent
          communities={communities.values}
          companies={companies.values}
          dashboardData={dashboardData}
          userProfile={userProfile}
        />
      )}
    </Resources>
  );
}

export type WizardType = { type: 'company'; company: ICompany } | { type: 'user' } | { type: 'none' };

function getGreeting() {
  const hour = new Date().getHours();
  return hour > 18 || hour < 5 ? 'Good evening' : hour < 12 ? 'Good morning' : 'Good afternoon';
}

const tabs = ['home', 'manage', 'network', 'collections'] as const;

// This requires special attention because home is at the root path
export function isHomeTab(currentPath: string) {
  return currentPath === userUrls.dashboard.home();
}

function DashboardContent({
  companies,
  communities,
  dashboardData,
  userProfile,
}: {
  communities: MyCommunity[];
  companies: CompanyListItem[];
  dashboardData: DashboardData;
  userProfile: SelfUserProfile;
}) {
  const [profilePreviewVisible, setProfilePreviewVisible] = useState(false);

  const toggleProfilePreview = () => {
    setProfilePreviewVisible(!profilePreviewVisible);
  };

  const { companiesIManage, communitiesIManage } = companiesCommunitiesByRole(companies, communities);
  const hasProfiles = companiesIManage.length + communitiesIManage.length > 0;
  const manageTabName = getManageTabName(communitiesIManage, companiesIManage);

  const isFounderIn100PitchesCommunitySlug = communities.find(
    c => featureToggle.communityIs100Pitches(c.id) && c.roles.includes('company_admin_in_community'),
  )?.slug;
  const hundredPitchesMessage: DashboardMessage | undefined = isFounderIn100PitchesCommunitySlug
    ? { type: 'is-founder-in-100-pitches', communitySlug: isFounderIn100PitchesCommunitySlug }
    : undefined;

  const { push } = useRoute();

  const location = useLocation();
  const activeTab = isHomeTab(location.pathname) ? 'home' : tabs.filter(tab => location.pathname.includes(tab))[0];
  const windowWidth = useWindowWidth();

  return (
    <Layout
      title="Home"
      heading={
        <>
          <div className="u-flex u-flex-align-center">
            <div>
              <h2 className="text-h3" style={{ marginBottom: 0 }}>
                {getGreeting()}
                {userProfile && (
                  <>
                    , <button onClick={toggleProfilePreview}>{userProfile?.name ?? ''}</button>
                  </>
                )}
              </h2>
              <div>
                {/* The tabs component has a hard time conforming to the boundries it is given. 
                Don't let it take more than the screen width (minus padding) */}
                <Tabs
                  style={{ maxWidth: `calc(${windowWidth}px - ${contentSpacing})` }}
                  className="u-content-spacing-top"
                  activeTab={activeTab}
                >
                  <Tab
                    data-intercom-target="dashboard-home"
                    onClick={() => push(userUrls.dashboard.home())}
                    value="home"
                    label="Home"
                  />
                  {hasProfiles && (
                    <Tab
                      data-intercom-target="dashboard-manage"
                      onClick={() => push(userUrls.dashboard.manage())}
                      value="manage"
                      label={manageTabName}
                    />
                  )}
                  <Tab
                    data-intercom-target="dashboard-network"
                    onClick={() => push(userUrls.dashboard.network.overview())}
                    value="network"
                    label="Network"
                  />
                  <Tab
                    data-intercom-target="dashboard-collections"
                    onClick={() => push(userUrls.dashboard.collections())}
                    value="collections"
                    label="Collections"
                  />
                </Tabs>
              </div>
            </div>
          </div>
        </>
      }
    >
      <Switch>
        <Route path="/network" render={() => <Network communities={communities} companies={companies} />} />
        {hasProfiles && (
          <Route exact path="/manage" render={() => <Manage companies={companies} communities={communities} />} />
        )}
        {profilePreviewVisible && <UserProfileDialog cwUserId={userProfile.cwUserId} onClose={toggleProfilePreview} />}
        <Route path="/collections" component={Shortlists} />
        <Route
          exact
          path="/"
          render={() => <Home hundredPitchesMessage={hundredPitchesMessage} dashboardData={dashboardData} />}
        />
        <Route
          exact
          path="/conversation/:conversationId"
          render={(props: RouteComponentProps<{ conversationId: UUID }>) => (
            <UserUserConversationDialog conversationId={props.match.params.conversationId} />
          )}
        ></Route>
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
}
function getManageTabName(communitiesIManage: MyCommunity[], companiesIManage: CompanyListItem[]) {
  if (communitiesIManage.length === 0 && companiesIManage.length === 1) {
    return 'Company';
  } else if (communitiesIManage.length === 1 && companiesIManage.length === 0) {
    return 'Community';
  } else if (communitiesIManage.length === 0 && companiesIManage.length > 1) {
    return 'Companies';
  } else if (communitiesIManage.length > 1 && companiesIManage.length === 0) {
    return 'Communities';
  }
  return 'Manage';
}
