import { externalAPI } from 'apis';
import DocumentAPI from 'apis/DocumentAPI';
import { DocumentDownloadDTO, UploadDocumentResponse } from 'types/documents';
import { SUPPORTED_DOCUMENT_FILE_TYPES } from 'util/constants';
import { splitFilenameExtension } from 'util/stringUtils';

export async function uploadFileToGCP(
  document: File,

  getUploadUrl: () => Promise<UploadDocumentResponse>,
  cleanupFile?: (fileId: UUID) => Promise<any>,
) {
  try {
    const { url, documentId } = await getUploadUrl();
    try {
      const mimeType = document.type // Sometimes the browser doesn't supply a mime type
        ? document.type
        : SUPPORTED_DOCUMENT_FILE_TYPES.find(
            t => t.extension === splitFilenameExtension(document.name).extension?.toLowerCase(),
          )?.mimes;

      await externalAPI.put(url, document, {
        headers: {
          'Content-Type': mimeType,
        },
      });

      return documentId;
    } catch (e) {
      cleanupFile && cleanupFile(documentId);
      throw {
        message: `Failed to upload ${document.name}. We have been notified about it, and will try and fix it. Please try again later.`,
      };
    }
  } catch (error) {
    throw { message: error?.data?.message ?? error?.message };
  }
}

export async function uploadFile(folderName: string, file: File) {
  return uploadFileToGCP(file, () =>
    DocumentAPI.uploadFile(folderName, {
      filename: file.name,
    }),
  );
}

export async function downloadBlob(response: DocumentDownloadDTO) {
  const blobResponse = await externalAPI.get(response.path, {
    responseType: 'blob',
  });

  const blob = new Blob([blobResponse.data], { type: response.contentType });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', response.filename);
  document.body.appendChild(link);
  link.click();
}
