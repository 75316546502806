import React, { useState } from 'react';
import NotifyAPI from 'apis/NotifyAPI';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { SwipeableDrawer } from '@mui/material';
import BellIcon from 'ui/elements/icons/BellIcon';
import NotificationList from './components/NotificationList';
import styles from './components/styles.scss';
import { OrangeBadge } from '../../ui/elements/OrangeBadge';
import { getOrUndefined } from 'util/resource';

export default function NotificationEntry({ style, className }: { className?: string; style?: React.CSSProperties }) {
  const [resource, setResource] = useResourceLegacy(NotifyAPI.notifications.get, []);
  const [isOpen, setOpen] = useState(false);
  const markAsSeen = () => {
    setResource(prevState => ({
      ...prevState,
      metadata: { ...prevState.metadata, unseen: 0 },
      values: prevState.values.map(v => ({ ...v, seen: true })),
    }));
  };
  const handleClick = () => {
    if (isOpen) {
      NotifyAPI.notifications.markAllAsSeen();
      markAsSeen();
    }
    setOpen(!isOpen);
  };
  const handleClose = () => {
    setOpen(false);
    NotifyAPI.notifications.markAllAsSeen();
    markAsSeen();
  };

  return (
    <>
      <button className={className} style={{ whiteSpace: 'nowrap', ...style }} onClick={handleClick}>
        <OrangeBadge badgeContent={getOrUndefined(resource)?.metadata.unseen}>
          <BellIcon />
        </OrangeBadge>
      </button>
      <SwipeableDrawer
        sx={{
          '.MuiPaper-root': {
            width: '85%',
            maxWidth: '550px',
          },
        }}
        onOpen={() => {}}
        disableSwipeToOpen
        onClose={handleClose}
        anchor="right"
        open={isOpen}
      >
        <div className={styles.contentWrapper}>
          <NotificationList resource={resource} onClick={handleClose} />
        </div>
      </SwipeableDrawer>
    </>
  );
}
