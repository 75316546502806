import React from 'react';
import { companyUrls } from 'urls';
import { Todo } from './Todo';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { ICompany } from 'types/company';
import { differenceInDays, startOfDay } from 'date-fns';
import themeBluePlanet from 'ui/theme/themeBluePlanet';
import Tooltip from 'ui/elements/Tooltip';
import QuestionIcon from 'ui/elements/icons/QuestionIcon';
import { yearsMonthsDaysSince } from 'util/dateUtils';

interface Props {
  company: ICompany;
  lastUpdatedProfile: DateString;
}

export default function UpdateCompanyProfile({ company, lastUpdatedProfile }: Props) {
  const timeSinceUpdate = differenceInDays(startOfDay(new Date()), startOfDay(new Date(lastUpdatedProfile)));
  const color =
    timeSinceUpdate < 85
      ? themeBluePlanet.bluePlanetPalette.blue.main
      : timeSinceUpdate < 175
      ? themeBluePlanet.bluePlanetPalette.orange.medium
      : themeBluePlanet.bluePlanetPalette.red.main;

  return (
    <Todo
      href={companyUrls.overview(company.slug, 'profile')}
      action={<span className="text-link">Go to company profile</span>}
    >
      <div>
        <IconAvatar color="blue" size={{ width: '40px', height: '40px' }}>
          <b style={{ color: color }}>{timeSinceUpdate}</b>
        </IconAvatar>
      </div>
      <div className="u-flex">
        Days since you updated your company profile
        <Tooltip
          className="u-half-spacing-left"
          color="light"
          title={
            <p className="text-body" style={{ fontWeight: '400' }}>
              It has been {yearsMonthsDaysSince(lastUpdatedProfile)} since you last updated your company information,
              perhaps some information is outdated? Review your profile and make sure it&apos;s up to date.
            </p>
          }
        >
          <div>
            <QuestionIcon fontSize="small"></QuestionIcon>
          </div>
        </Tooltip>
      </div>
    </Todo>
  );
}
