import React, { useState } from 'react';
import { Comment } from 'types/content';
import { UserProfile } from 'types/user';
import { OnCommentDeleted } from './CommentsContainer';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import { AuthorImage, asUserAuthor } from 'domain/shared/author';
import { AuthorLink, AuthorRole } from 'ui/domain/Byline';
import { insertLinksForUrlsWithoutAnchorTags } from 'util/stringUtils';
import Timestamp from 'ui/elements/Timestamp';
import useDialogHandler from 'hooks/useDialogHandler';
import styled from '@emotion/styled';
import { contentSpacing } from 'ui/theme/themeConstants';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import Button from 'ui/elements/buttons/Button';
import LinkButton from 'ui/elements/buttons/LinkButton';
import { bluePlanetTheme } from 'ui/theme';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0User } from 'types';
import { getAdminRights } from 'auth/util';
import RichText from 'ui/elements/text/RichText';

interface CommentCardProps {
  userProfiles: UserProfile[];
  comment: Comment;
  showResponseForm: boolean;
  respondable?: boolean;
  responses?: number;
  toggleShow: () => void;
  onDeleteComment: OnCommentDeleted;
  updateId: string;
  loggedInUser?: UserProfile;
  isResponse?: boolean;
}

const Message = styled.div(
  (props: { isResponse: boolean }) => `
  display: flex;
  max-width: 550px;
  
  ${
    props.isResponse
      ? `
        margin: ${contentSpacing} 50px 0;
        `
      : ''
  }
`,
);

const DeleteLinkButton = styled(LinkButton)`
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main} !important;

  &:hover,
  &:focus {
    color: ${bluePlanetTheme.bluePlanetPalette.red.main} !important;
  }
`;

export default function CommentCard(props: CommentCardProps) {
  const userProfile = props.userProfiles.find(cu => cu.cwUserId === props.comment.creatorCwUserId);
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');
  const isCommenter = props.loggedInUser && props.comment.creatorCwUserId === props.loggedInUser.cwUserId;
  const canDelete = !props.comment.deleted && (isCommenter || canAccessBackoffice);

  const userAuthor = asUserAuthor(userProfile);

  const [userDialogId, setUserDialogId] = useState('');
  const onAuthorClick = userProfile?.cwUserId ? () => setUserDialogId(userProfile?.cwUserId) : undefined;
  const dialogHandler = useDialogHandler();

  const onConfirmDelete = () => {
    dialogHandler.close();
    props.onDeleteComment(props.comment.id, props.comment.responseTo);
  };

  return (
    <>
      <Message isResponse={props.isResponse || false}>
        <div>
          <AuthorImage size={40} onClick={onAuthorClick} author={userAuthor} />
        </div>
        <div className="text-small u-flex-grow u-half-spacing-left">
          <div>
            <span className="text-weight-medium text-small text-link-dark">
              <AuthorLink className="text-align-left" name={userAuthor.name} onClick={onAuthorClick} />
            </span>
            <span className="text-metadata">
              <AuthorRole author={userAuthor} />
            </span>
          </div>
          <div>
            <RichText initiallyExpanded={true} className={props.comment.deleted ? 'text-italic' : undefined}>
              {() => insertLinksForUrlsWithoutAnchorTags(props.comment.content)}
            </RichText>
            <div className="text-metadata u-quarter-spacing-top u-flex">
              <Timestamp timestamp={props.comment.createdAt} />
              {canDelete && (
                <DeleteLinkButton size="small" className="u-half-spacing-left" onClick={() => dialogHandler.open()}>
                  Delete
                </DeleteLinkButton>
              )}
              {props.respondable && !props.showResponseForm && (
                <LinkButton size="small" className="u-half-spacing-left" onClick={props.toggleShow}>
                  Reply
                </LinkButton>
              )}
            </div>
          </div>
        </div>
      </Message>
      <ConfirmDialog
        isOpen={dialogHandler.isOpen}
        onClose={dialogHandler.close}
        title="You are about to remove this comment"
        confirmButton={
          <Button color="red" kind="primary" onClick={onConfirmDelete}>
            Yes, remove
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <RichText initiallyExpanded={true} className={'text-italic'}>
          {() => insertLinksForUrlsWithoutAnchorTags(props.comment.content)}
        </RichText>
        <p>Are you sure?</p>
      </ConfirmDialog>
      {userDialogId && <UserProfileDialog cwUserId={userDialogId} onClose={() => setUserDialogId('')} />}
    </>
  );
}
