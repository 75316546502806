import { useImpactGoals } from 'apis/CompanyAPI/companies/useImpactGoals';
import ImpactGoalContainer from 'domain/companies/ImpactGoals/ImpactGoalContainer';
import React, { useState } from 'react';
import EditAction from 'ui/elements/actions/EditAction';
import UploadCtaButton from 'ui/elements/buttons/UploadCtaButton';
import AddIcon from 'ui/elements/icons/AddIcon';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import { getOrUndefined } from 'util/resource';
import EditImpactGoalsDialog from './EditImpactGoalsDialog';
import { useAiOnboardingSessionStorage } from 'domain/onboarding/company/Wizard/AIOnboarding';
import AIChip from '../AiChip';

interface Props {
  companyId: number;
  canEditCompany: boolean;
}

export default function ImpactGoalsSection({ companyId, canEditCompany }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { resource: impactGoalsResource } = useImpactGoals(companyId);
  const activeImpactGoals = getOrUndefined(impactGoalsResource);
  const [isAiOnboarding] = useAiOnboardingSessionStorage();

  if (activeImpactGoals == null || (activeImpactGoals.length === 0 && !canEditCompany)) {
    return null;
  }

  return (
    <div>
      <SectionHeading
        heading={
          <>
            Impact goals
            {isAiOnboarding && <AIChip className="u-half-spacing-left" onClick={() => setIsDialogOpen(true)} />}
          </>
        }
        addMobileMargin
      >
        {canEditCompany && (
          <button onClick={() => setIsDialogOpen(true)} aria-label="Edit section">
            <EditAction tooltip="Edit section" />
          </button>
        )}
      </SectionHeading>
      <Card>
        <p className="u-content-spacing-bottom text-body">
          We are currently working towards the following UN 17 sustainable development goals.
        </p>
        <ImpactGoalContainer readOnly>
          {activeImpactGoals.map(goal => (
            <img key={goal.id} draggable={false} src={goal.imageUrl} title={goal.name} />
          ))}
          {canEditCompany && (
            <UploadCtaButton height={100} onClick={() => setIsDialogOpen(true)}>
              <AddIcon />
            </UploadCtaButton>
          )}
        </ImpactGoalContainer>
      </Card>
      {isDialogOpen && (
        <EditImpactGoalsDialog
          activeImpactGoals={activeImpactGoals}
          companyId={companyId}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
}
