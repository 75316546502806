import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styles from './styles.scss';
import { useFormik } from 'formik';
import debounce from 'lodash.debounce';
import { Mention } from 'ui/elements/form/RichTextEditor';
import Button from 'ui/elements/buttons/Button';
import { PostConversationMessage } from 'apis/ContentAPI/conversationTypes';
import {
  communityMentionSearchFunction,
  companyUserMentionSearchFunction,
  mentionModule,
  roomMentionSearchFunction,
} from 'ui/elements/form/RichTextEditor/utils';
import MagicUrl from 'quill-magic-url';
import 'quill-mention/dist/quill.mention.css';
import 'quill-mention';
import 'styles/global/overrides/quill-editor-overrides.scss';
import 'ui/elements/form/RichTextEditor/components/BlockBlot';
import 'ui/elements/form/RichTextEditor/components/MentionBlot';
import useClickableMentions from 'ui/elements/form/RichTextEditor/useClickableMentions';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import PublicProfileDialog from 'domain/companies/ProfileDialog/ProfileDialog';
import { UpdateVisibility } from 'types/content';
import { TinyCommunity } from 'types/company/community';

export type UpdateContext =
  | {
      type: 'room';
      roomId: string;
    }
  | {
      type: 'community';
      community: TinyCommunity;
      visibility: UpdateVisibility;
    }
  | {
      type: 'company';
      companyId: number;
      visibility: UpdateVisibility;
    }
  | {
      type: 'boardroom';
      companyId: number;
    };

interface Props {
  className?: string;
  updateId: string;
  onPost: (comment: PostConversationMessage) => Promise<void>;
  onCancel?: () => void;
  context: UpdateContext;
  autoFocus: boolean;
}

export default function CommentField(props: Props) {
  const quillRef = useRef<ReactQuill>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [, setQuillCurrentSelection] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (props.autoFocus) {
      quillRef.current?.editor?.focus();
    }
  }, [props.autoFocus]);

  const { userProfileIdToShow, setUserProfileIdToShow, companyProfileIdToShow, setCompanyProfileIdToShow } =
    useClickableMentions(containerRef, quillRef, [props.context, quillRef]);

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    onSubmit: (values, { resetForm }) => {
      setIsSaving(true);
      props.onPost({ content: values.comment }).then(() => {
        setIsSaving(false);
        quillRef.current?.editor?.deleteText(0, quillRef.current?.editor?.getContents().length());
        resetForm();
      });
    },
  });

  const handleChangeFunc = (content: string, delta: any, source: any, editor: any) => {
    formik.setFieldValue('comment', editor.getHTML());
  };

  const handleMentionFunc = async function (
    searchTerm: string,
    renderList: (data: (Mention & { disabled?: boolean })[], searchTerm: string) => void,
  ) {
    const result =
      props.context.type === 'room'
        ? await roomMentionSearchFunction(searchTerm, props.context.roomId)
        : props.context.type === 'community'
        ? await communityMentionSearchFunction(searchTerm, props.context.visibility, props.context.community.id)
        : props.context.type === 'company'
        ? await companyUserMentionSearchFunction(searchTerm, props.context.visibility, props.context.companyId)
        : await Promise.resolve([]);

    renderList(
      result.length !== 0 ? [{ id: 'header', value: 'header', type: 'user', disabled: true }, ...result] : [],
      searchTerm,
    );
  };

  const debouncedChangeFunc = debounce(handleChangeFunc, 200);
  const debouncedMentionFunc = debounce(handleMentionFunc, 200);

  const formats = ['link', 'styled-mention'];

  const modules = useMemo(
    () => ({
      keyboard: {
        bindings: {
          // default behaviour is that tabbing inside editor will insert a tab character
          // disabling this will make the tab key change focus to the next element in the dom.
          tab: false,
        },
      },
      magicUrl: true,
      toolbar: false,
      mention: mentionModule(debouncedMentionFunc),
    }),
    [],
  );

  return (
    <div
      id={`editorContainer - ${props.updateId}`}
      ref={containerRef}
      className={`u-flex u-flex--gap-half ${props.className}`}
    >
      <ReactQuill
        key={props.updateId}
        ref={quillRef}
        className={styles.editor}
        onChangeSelection={selection => {
          if (selection) {
            setQuillCurrentSelection(selection.index);
          }
        }}
        onChange={debouncedChangeFunc}
        placeholder="Write a reply"
        modules={{ ...modules }}
        formats={formats}
        preserveWhitespace
      />
      <div className={styles.messageFormButtons}>
        {props.onCancel && (
          <span className="u-half-spacing-right">
            <Button kind="tertiary" onClick={props.onCancel}>
              Cancel
            </Button>
          </span>
        )}
        <Button
          size="large"
          kind="primary"
          isLoading={isSaving}
          disabled={formik.values.comment.length === 0 || quillRef.current?.editor?.getText().length === 1}
          className={styles.submitButton}
          onClick={() => formik.handleSubmit()}
        >
          Send
        </Button>
      </div>
      {userProfileIdToShow && (
        <UserProfileDialog cwUserId={userProfileIdToShow} onClose={() => setUserProfileIdToShow(undefined)} />
      )}
      {companyProfileIdToShow && (
        <PublicProfileDialog companyId={companyProfileIdToShow} onClose={() => setCompanyProfileIdToShow(undefined)} />
      )}
    </div>
  );
}

Quill.register('modules/magicUrl', MagicUrl);
