import React from 'react';
import { SUPPORT_EMAIL } from 'util/constants';

export default function SupportEmailLink() {
  return (
    <a className="text-link" style={{ fontSize: 'inherit' }} href={`mailto:${SUPPORT_EMAIL}`}>
      {SUPPORT_EMAIL}
    </a>
  );
}
