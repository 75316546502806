import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { halfSpacing } from 'ui/theme/themeConstants';

const Sticky = styled.div(
  ({ top = 0, horizontalBleed }: { top?: number; horizontalBleed?: string }) => `
    position: sticky;
    top:${top}px;
    z-index: 20;
    background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
    padding: ${halfSpacing} 0;

    ${bluePlanetTheme.breakpoints.up('sm')} {
      padding: ${halfSpacing} ${horizontalBleed ?? 0};
      margin: 0 -${horizontalBleed ?? 0};
    }
}`,
);

export default Sticky;
