import { UpdateVisibility } from 'types/content';

export const visibilityDisplayString = (visibility: UpdateVisibility, communityName?: string) => {
  switch (visibility) {
    case 'Public':
      return 'Anyone who can see your profile';
    case 'Private':
      return 'Shareholders and board members';
    case 'CommunityMembers':
      return `Everyone in ${communityName ?? 'the community'}`;
    case 'Founders':
      return 'Founders';
    case 'CommunityMembersExceptFounders':
      return 'Investors, mentors & advisors';
    default:
      return '';
  }
};
