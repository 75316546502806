import EventAPI from 'apis/EventAPI';
import React from 'react';
import Resource from 'util/resource/Resource';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { ShowEvent } from './ShowEvent';
import Dialog, { Content, Title } from 'ui/views/dialogs/Dialog';

export default function EventDialog({ eventId, onClose }: { eventId: UUID; onClose: () => void }) {
  const [eventResource] = useResourceLegacy(() => EventAPI.get(eventId), [eventId]);

  return (
    <Dialog open onClose={onClose}>
      <Resource resource={eventResource}>
        {event => (
          <>
            <Title onClose={onClose}>{event.title}</Title>
            <Content>
              <ShowEvent event={event} />
            </Content>
          </>
        )}
      </Resource>
    </Dialog>
  );
}
