import styled from '@emotion/styled';
import { SwipeableDrawer } from '@mui/material';
import React from 'react';
import bluePlanetTheme, { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';

export const DrawerList = styled.div`
  padding: ${halfSpacing} 0;
  max-height: 90vh;
`;

export const DrawerItem = styled.button`
  text-align: left;
  min-height: 60px;
  font-size: ${fontSizeSmall};
  font-weight: 500;
  padding: ${halfSpacing} ${contentSpacing};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${bluePlanetTheme.bluePlanetPalette.grey.light};
  }
`;

export const DrawerItemText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function DrawerBottom({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <SwipeableDrawer
      sx={{ zIndex: 1400 }} // zIndex must be higher than dialog zIndex
      disableSwipeToOpen
      onOpen={() => {}}
      onClose={onClose}
      anchor="bottom"
      open={isOpen}
    >
      <DrawerList>{children}</DrawerList>
    </SwipeableDrawer>
  );
}
