import { useAuth0 } from '@auth0/auth0-react';
import { currentRelativePath } from './util';
import { RegistrationDetails, setRegistrationDetails } from 'pages/Registration/common/CompleteRegistration';

// This supports signup with redirect back to this page for new users
// We want to use this hook both for redirects to login and signup as the user can change their mind
// after they are redirected to auth0 signup/login screen
// If a user follows a normal login flow, 'appState' will handle the redirect.
// If a user signs up and have to verify their email, they will loose the appState as they will probably verify the email in a new tab, and continue browsing from it,
//so we need to fall back on localStorage to ensure they are redirect back to this page.

const KEY = 'return-to-after-signup';

export function useLoginWithRedirect() {
  const { loginWithRedirect } = useAuth0();

  const setReturnTo = (path: string) => window?.localStorage?.setItem(KEY, path);

  const login = (screenHint: 'signup' | 'login', returnTo?: string) => {
    const registrationDetails: RegistrationDetails = {
      type: 'user',
      originUrl: window.location.toString(),
    };

    setRegistrationDetails(registrationDetails);
    setReturnTo(returnTo ?? currentRelativePath());

    loginWithRedirect({
      screen_hint: screenHint,
      appState: {
        returnTo: returnTo ?? currentRelativePath(),
      },
    });
  };

  const getReturnTo = () => window?.localStorage?.getItem(KEY);
  const removeFromLocalStorage = () => window?.localStorage?.removeItem(KEY);

  return { loginWithRedirect: login, getReturnTo, removeFromLocalStorage, setReturnTo };
}
