import { Author, asCompanyAuthor } from 'domain/shared/author';
import React, { useState } from 'react';
import { CommunityUpdate } from 'types/content';
import { UserProfile } from 'types/user';
import EditUpdateDialog from './EditUpdateDialog';
import { ICompany } from 'types/company';

import { getOrUndefined } from 'util/resource';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import ShowUpdate from 'domain/Updates/shared/Show';
import FollowButton from 'domain/companies/Follow/FollowButton';
import { Hidden } from '@mui/material';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import ContentHeadingActions from 'domain/Updates/shared/Show/ContentHeadingActions';
import { TinyCommunity } from 'types/company/community';

interface Props {
  className?: string;
  update: CommunityUpdate;
  creator?: UserProfile;
  author?: Author;
  community: TinyCommunity;
  postedByCompany?: ICompany;
  viewCompanyHref?: string;
  onArchive: (contentId: string) => void;
  onDelete?: () => void;
  canEditPost: boolean;
  onEdit: () => void;
  canPinToFeed?: boolean;
  viewType?: ViewType;
  headingActions?: JSX.Element;
}

export type ViewType = 'preview' | 'comment' | 'read more';

export default function Update(props: Props) {
  const { update, creator, community, postedByCompany } = props;
  const [editUpdate, setEditUpdate] = useState(false);
  const [isPinned, setPinned] = useState<boolean>(update.isPinned || false);
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const [viewType, setViewType] = useState<ViewType>(props.viewType || 'preview');

  const updateIsPinned = (isPinned: boolean) => {
    setPinned(isPinned);
    props.onEdit();
  };

  const author = () => {
    if (!update.postedByCompanyId) {
      return asCompanyAuthor(creator, community);
    }
    return asCompanyAuthor(creator, postedByCompany, props.viewCompanyHref);
  };

  const api = communityUpdateAPI(community.id);

  return (
    <>
      <ShowUpdate
        className={props.className}
        api={api}
        update={props.update.update}
        author={props.author ?? author()}
        creator={props.creator}
        updateContext={{ type: 'community', community: community, visibility: update.visibility }}
        viewType={viewType}
        canEditUpdate={props.canEditPost}
        showIsPinned={isPinned}
        actions={
          <>
            {postedByCompany && (
              <Hidden smDown>
                {/* Users can still follow trough the menu on the update */}
                <FollowButton
                  mode="compact"
                  company={postedByCompany}
                  kind="tertiary"
                  color="indigo"
                  trackingKey="community-update-request-to-follow"
                />
              </Hidden>
            )}

            <ContentHeadingActions
              api={api}
              update={update.update}
              companyId={update.communityId}
              company={postedByCompany ?? community}
              companyHref={postedByCompany && props.viewCompanyHref}
              authorId={userProfile?.cwUserId !== creator?.cwUserId ? creator?.cwUserId : undefined}
              onEdit={() => setEditUpdate(true)}
              canEditPost={props.canEditPost}
              onArchive={props.onArchive}
              onDelete={props.onDelete}
              setPinned={updateIsPinned}
              isPinned={isPinned}
              canPinToFeed={props.canPinToFeed}
              trackingKey="community-update-request-to-follow"
            />
            {props.headingActions}
          </>
        }
      />
      {editUpdate && (
        <EditUpdateDialog
          community={community}
          setActive={setEditUpdate}
          update={props.update}
          user={userProfile}
          onComplete={() => {
            props.onEdit();
            setViewType('read more');
            setEditUpdate(false);
          }}
        />
      )}
    </>
  );
}
