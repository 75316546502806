import { notify } from 'actions/snackbar';
import DocumentAPI from 'apis/DocumentAPI';

import { downloadBlob } from 'domain/shared/Files/actions';
import useLazyResource from 'util/resource/useLazyResource';
import { invalidate } from 'hooks/useSWR';
import { documentCenterDocumentsKey, refetchUnreadDocuments } from 'apis/DocumentAPI/useDocuments';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';

export const useDownloadDocument = (companyId: number) => {
  return useLazyResource(
    (args: { documentId: string; folderId?: string }) =>
      DocumentAPI.download(companyId, args.documentId).then(response => downloadBlob(response)),
    {
      onSuccess: (_, args) => {
        refetchUnreadDocuments(companyId);
        args.folderId && invalidate(documentCenterDocumentsKey(companyId, args.folderId));
      },
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );
};

export const useDownloadPitchDocument = (companyId: number) => {
  return useLazyResource(
    (documentId: string) =>
      companiesApi.profile.pitchDocuments.download(companyId, documentId).then(response => downloadBlob(response)),
    {
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );
};
