import React from 'react';
import ClickableCard from 'ui/views/cards/ClickableCard';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { timeSince } from 'util/dateUtils';
import { CompanyActivity } from 'types/company';
import RocketIcon from 'ui/elements/icons/RocketIcon';
import { CompanyOverviewSection, communityUrls } from 'urls';
import DollarSignIcon from 'ui/elements/icons/DollarSignIcon';
import NetworkIcon from 'ui/elements/icons/NetworkIcon';
import PiggybankIcon from 'ui/elements/icons/PiggybankIcon';
import { capitalize } from 'util/stringUtils';
import ChartIcon from 'ui/elements/icons/ChartIcon';
import PitchIcon from 'ui/elements/icons/PitchIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import PdfIcon from 'ui/elements/icons/FileIcons/PdfIcon';
import { CompanyViewAs } from 'domain/companies/roleUtils';

function getActivityIcon(activity: CompanyActivity) {
  switch (activity.type) {
    case 'JoinedCrowdWorks':
      return <RocketIcon />;
    case 'JoinedCommunity':
      return <NetworkIcon />;
    case 'StartedFundingRound':
      return <DollarSignIcon />;
    case 'ClosedFundingRound':
      return <PiggybankIcon />;
    case 'UpdatedKpis':
      return <ChartIcon />;
    case 'UpdatedProfile':
      return <PitchIcon />;
    case 'UpdatedTeam':
      return <UsersIcon />;
    case 'UpdatedPitchDeck':
      return <PdfIcon />;
  }
}

function getActivityText(activity: CompanyActivity): string {
  switch (activity.type) {
    case 'JoinedCrowdWorks':
      return 'Joined CrowdWorks';
    case 'JoinedCommunity':
      return `Joined ${activity.community.name}`;
    case 'StartedFundingRound':
      return 'Started funding round';
    case 'ClosedFundingRound':
      return 'Closed funding round';
    case 'UpdatedKpis':
      return 'Updated KPIs';
    case 'UpdatedPitchDeck':
      return 'Updated pitch deck';
    case 'UpdatedProfile':
      return 'Updated profile information';
    case 'UpdatedTeam':
      return 'Updated team information';
  }
}

function getActivityLink(
  activity: CompanyActivity,
  viewAs: CompanyViewAs,
  onChangeSection: (section: CompanyOverviewSection) => string | (() => void),
): string | (() => void) | undefined {
  const canViewKpis = viewAs === 'Admin' || viewAs === 'Shareholder' || viewAs === 'Board member';
  switch (activity.type) {
    case 'JoinedCommunity':
      return communityUrls.overview(activity.community.slug);
    case 'UpdatedKpis':
      return canViewKpis ? onChangeSection('kpis') : undefined;
    case 'UpdatedPitchDeck':
      return onChangeSection('profile');
    case 'UpdatedProfile':
      return onChangeSection('profile');
    case 'UpdatedTeam':
      return onChangeSection('team');
    default:
      return undefined;
  }
}

export default function ActivityCard({
  activity,
  viewAs,
  onChangeSection,
}: {
  activity: CompanyActivity;
  viewAs: CompanyViewAs;
  onChangeSection: (section: CompanyOverviewSection) => string | (() => void);
}) {
  const linkOrOnClick = getActivityLink(activity, viewAs, onChangeSection);
  return (
    <ClickableCard
      key={`${activity.type}-${activity.timestamp}`}
      className="u-flex-align-baseline text-align-center u-content-spacing-bottom"
      avatar={<IconAvatar size={{ width: '50px', height: '50px' }}>{getActivityIcon(activity)}</IconAvatar>}
      title={getActivityText(activity)}
      href={typeof linkOrOnClick === 'string' ? linkOrOnClick : undefined}
      onClick={typeof linkOrOnClick === 'function' ? linkOrOnClick : undefined}
    >
      <span className="text-metadata">{capitalize(timeSince(activity.timestamp))}</span>
    </ClickableCard>
  );
}
