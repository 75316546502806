import React from 'react';
import { CompanyListItem, ICompany } from 'types/company';
import { TinyCommunity } from 'types/company/community';
import { UserFeedData } from '../useUserFeed';
import ShowRoomUpdate from 'pages/Rooms/Updates/Show/Update';
import CommunityUpdate from 'pages/Community/Overview/CommunityUpdates/Update';
import BoardroomUpdate from 'pages/Boardroom/Updates/Update';
import { communityUrls, companyUrls } from 'urls';
import Update from 'pages/Company/Overview/sections/updates/Show/Update';
import { asBoardRoomAuthor, asCompanyAuthor, asRoomAuthor } from 'domain/shared/author';
import { UserProfile } from 'types/user';
import { Room } from 'domain/rooms/types';

export default function ShowUpdate({
  communities,
  companies,
  update,
  userProfiles,
  companyProfiles,
  rooms,
}: {
  update: UserFeedData;
  communities: TinyCommunity[];
  companies: CompanyListItem[];
  userProfiles: UserProfile[];
  companyProfiles: ICompany[];
  rooms: Room[];
}) {
  if (update.type === 'room') {
    const creator = userProfiles.find(cu => cu.cwUserId === update.roomUpdate.update.creatorCwUserId);
    const room = rooms.find(r => r.id === update.roomUpdate.roomId);
    const company = companies.find(c => c.id === room?.companyId);
    const viewRoomHref = company && room && companyUrls.rooms.view(company.slug, room.id);
    const viewCompanyHref = company && companyUrls.overview(company.slug, 'profile');
    return (
      <ShowRoomUpdate
        key={`update-${update.roomUpdate.update.id}`}
        update={update.roomUpdate}
        creator={creator}
        onChange={() => {}}
        canEditPost={false}
        author={asRoomAuthor(creator, company, viewCompanyHref, room?.name, viewRoomHref)}
      />
    );
  } else if (update.type === 'boardroom') {
    const creator = userProfiles.find(cu => cu.cwUserId === update.boardroomUpdate.update.creatorCwUserId);
    const maybeCompany = companies.find(c => c.id === update.boardroomUpdate.companyId);
    const maybeCommunity = communities.find(c => c.id === update.boardroomUpdate.companyId);
    const companyHref = maybeCommunity
      ? communityUrls.overview(maybeCommunity.slug)
      : maybeCompany
      ? companyUrls.overview(maybeCompany.slug, 'updates')
      : undefined;
    const roomHref = maybeCommunity
      ? communityUrls.overview(maybeCommunity.slug)
      : maybeCompany
      ? companyUrls.boardroom.overview(maybeCompany.slug)
      : undefined;

    return (
      <BoardroomUpdate
        key={`update-${update.boardroomUpdate.update.id}`}
        boardroomUpdate={update.boardroomUpdate}
        creator={creator}
        companyId={update.boardroomUpdate.companyId}
        canEditPost={false}
        onChange={() => {}}
        author={asBoardRoomAuthor(creator, maybeCompany || maybeCommunity, companyHref, roomHref)}
      />
    );
  } else if (update.type === 'community') {
    const creator = userProfiles.find(cu => cu.cwUserId === update.communityUpdate.update.creatorCwUserId);
    const community = communities.find(c => c.id === update.communityUpdate.communityId);
    const postedByCompany = companyProfiles.find(c => c.id === update.communityUpdate.postedByCompanyId);

    if (!community) return null;
    const author = update.communityUpdate.postedByCompanyId
      ? asCompanyAuthor(
          creator,
          postedByCompany,
          postedByCompany
            ? communityUrls.companies.viewFromOverview(community.slug, postedByCompany?.id, 'community-feed')
            : undefined,
        )
      : asCompanyAuthor(creator, community, communityUrls.overview(community.slug));
    return (
      <CommunityUpdate
        key={`update-${update.communityUpdate.update.id}`}
        update={update.communityUpdate}
        creator={creator}
        canEditPost={false}
        onEdit={() => {}}
        author={author}
        community={community}
        onArchive={() => {}}
        postedByCompany={postedByCompany}
        viewCompanyHref={
          postedByCompany
            ? communityUrls.companies.viewFromOverview(
                update.communityUpdate.communityId,
                postedByCompany.id,
                'community-feed',
              )
            : undefined
        }
      />
    );
  } else if (update.type === 'company') {
    const creator = userProfiles.find(cu => cu.cwUserId === update.companyUpdate.update.creatorCwUserId);
    const company =
      companyProfiles.find(c => c.id === update.companyUpdate.companyId) ||
      companies.find(c => c.id === update.companyUpdate.companyId);
    return company ? (
      <Update
        key={`update-${update.companyUpdate.update.id}`}
        update={update.companyUpdate}
        creator={creator}
        canEditPost={false}
        company={company}
        author={asCompanyAuthor(creator, company, company ? companyUrls.overview(company.slug, 'updates') : undefined)}
        onArchive={() => {}}
        onEdit={() => {}}
      />
    ) : null;
  }
  return null;
}
