import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import UploadCtaButton from 'ui/elements/buttons/UploadCtaButton';
import IconButton from 'ui/elements/icons/IconButton';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import { Backdrop, CircularProgress, Fade } from '@mui/material';
import styled from '@emotion/styled';
import FontRedHat from 'ui/theme/FontRedHat';
import { fontSizeSmall } from 'ui/theme/themeBluePlanet';
import { quarterSpacing } from 'ui/theme/themeConstants';

interface Props<T> {
  onConfirm: (value: T) => Promise<any> | void;
  renderInputComponent: (onChange: (val: T) => void, value: T) => React.ReactElement;
  valueComponent: React.ReactNode;
  defaultValue: T;
  explanation?: string;
  buttonStyle?: React.CSSProperties;
  inputWrapperStyle?: React.CSSProperties;
  label: string;
  disableEnterKey?: boolean;
  error?: string;
}

const ErrorCard = styled.div`
  position: absolute;
  top: calc(100% + ${quarterSpacing});
  padding: ${quarterSpacing};
  background-color: ${bluePlanetTheme.bluePlanetPalette.red.main};
  font-family: ${FontRedHat.text};
  font-size: ${fontSizeSmall};
  border-radius: ${bluePlanetTheme.shape.borderRadius}px;
  color: ${bluePlanetTheme.bluePlanetPalette.white};
`;

export default function InlineEditableField<T>({
  renderInputComponent,
  valueComponent,
  defaultValue,
  label,
  error,
  explanation,
  buttonStyle,
  inputWrapperStyle,
  disableEnterKey,
  onConfirm,
}: Props<T>) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);

  const inputComponent = renderInputComponent((value: T) => {
    setValue(value);
  }, value);

  const onSubmit = () => {
    if (defaultValue === value) {
      setIsEditing(false);
      return;
    }
    if (!error) {
      const result = onConfirm(value);
      if (result instanceof Promise) {
        setIsSubmitting(true);
        result
          .then(() => {
            setIsEditing(false);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        setIsEditing(false);
      }
    }
  };

  const handleKeyboard: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter' && !disableEnterKey) {
      e.preventDefault();
      onSubmit();
      return;
    }
    if (e.key === 'Escape' || e.keyCode === 27) {
      e.preventDefault();
      setIsEditing(false);
      return;
    }
  };

  const hasValue = defaultValue instanceof Array ? defaultValue.length > 0 : !!defaultValue;

  return (
    <div style={{ position: 'relative' }}>
      {!hasValue ? (
        <UploadCtaButton style={buttonStyle} onClick={() => setIsEditing(true)} explanation={explanation}>
          {label}
        </UploadCtaButton>
      ) : (
        <div className="text-font-display u-flex-inline-align-center" onClick={() => setIsEditing(true)}>
          {valueComponent}
        </div>
      )}
      {isEditing && (
        <div>
          {/* must be higher than z-index of Sticky! */}
          <Backdrop style={{ zIndex: 21 }} open onClick={onSubmit} />
          <div
            style={{
              borderRadius: 4,
              zIndex: 22,
              position: 'absolute',
              top: -9,
              left: -11,
              right: -11,
              ...inputWrapperStyle,
            }}
            onKeyDown={handleKeyboard}
          >
            <div
              className="u-flex-align-stretch"
              style={{
                position: 'relative',
              }}
            >
              {inputComponent}
              {error && <ErrorCard>{error}</ErrorCard>}
              <div
                style={{
                  border: `1px solid ${
                    error ? bluePlanetTheme.bluePlanetPalette.red.main : bluePlanetTheme.bluePlanetPalette.grey.main
                  }`,
                  background: 'white',

                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  marginLeft: -4,
                }}
                className="u-quarter-padding-x u-flex-align-center z-layer-1"
              >
                <IconButton
                  size="small"
                  color="blue"
                  onClick={onSubmit}
                  className="u-quarter-spacing-right"
                  sx={{
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <Fade in={isSubmitting} unmountOnExit exit={false}>
                    <CircularProgress disableShrink size={16} color="inherit" />
                  </Fade>
                  {!isSubmitting && <CheckmarkIcon fontSize="inherit" />}
                </IconButton>
                <IconButton
                  size="small"
                  color="grey"
                  onClick={() => setIsEditing(false)}
                  sx={{
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
