import compareDesc from 'date-fns/compareDesc';
import Update from 'pages/Company/Overview/sections/updates/Show/Update';
import UpdateDialog from 'pages/Company/Overview/sections/updates/Show/UpdateDialog';
import useRoute from 'hooks/useRoute';
import React from 'react';
import sort from 'ramda/src/sort';
import { Route, useRouteMatch } from 'react-router';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { CompanyActivity, ICompany } from 'types/company';
import { CompanyUpdate, UpdateVisibility } from 'types/content';
import { UserProfile } from 'types/user';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import Card from 'ui/views/cards/Card';
import { notEmpty } from 'util/arrayUtils';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import ActivityCard from './ActivityCard';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import 'styles/global/overrides/quill-editor-overrides.scss';
import { CompanyOverviewSection } from 'urls';

interface Props {
  company: ICompany;
  updates: PaginatedResult<CompanyUpdate>;
  shouldShowArchivedUpdates: boolean;
  userProfiles: PaginatedResult<UserProfile>;
  activities: CompanyActivity[];
  viewAs: CompanyViewAs;
  onSeeMore: (paginationOptions: PaginationOptions) => void;
  reloadUpdates: () => void;
  onChangeSection: (section: CompanyOverviewSection) => string | (() => void);
}

function getUpdate(
  company: ICompany,
  userProfiles: UserProfile[],
  companyUpdate: CompanyUpdate,
  viewAs: CompanyViewAs,
  reloadUpdates: () => void,
) {
  const canViewUpdates = (visibility: UpdateVisibility) => {
    return (
      (viewAs === 'Follower' && visibility === 'Public') ||
      (viewAs === 'Visitor' && visibility === 'Public') ||
      viewAs === 'Admin' ||
      viewAs === 'Shareholder' ||
      viewAs === 'Board member'
    );
  };
  const { update } = companyUpdate;
  const creator = userProfiles.find(cu => cu.cwUserId === update.creatorCwUserId);

  if (!canViewUpdates(companyUpdate.visibility)) {
    return null;
  }

  return {
    timestamp: update.createdAt,
    isPinned: companyUpdate.isPinned,
    element: (
      <Update
        className="u-content-spacing-bottom"
        key={`update-preview-${update.id}`}
        update={companyUpdate}
        creator={creator}
        company={company}
        onArchive={reloadUpdates}
        onDelete={reloadUpdates}
        onEdit={reloadUpdates}
        canEditPost={viewAs === 'Admin'}
        canPinToFeed={viewAs === 'Admin'}
      />
    ),
  };
}

export default function ActivityFeed({
  company,
  shouldShowArchivedUpdates,
  updates,
  userProfiles,
  activities,
  viewAs,
  onSeeMore,
  reloadUpdates,
  onChangeSection,
}: Props) {
  const { push } = useRoute();
  const match = useRouteMatch();

  const updatesInFeed = updates.values
    .map(update => getUpdate(company, userProfiles.values, update, viewAs, reloadUpdates))
    .filter(notEmpty);

  // If there are more updates left to be shown, we remove the last activity to be filtered in with the rest, as it is the "joined crowdworks" activity,
  // and it will always be the first event in the list. To avoid updates being added before it when we click "see more", we need to only show it when
  // all updates are shown
  const allUpdatesAreVisible = updates.total === updates.values.length;
  const activitiesToShow = shouldShowArchivedUpdates
    ? [] // If the archive filter is set, we only show archived updates, and not any of the company activity
    : allUpdatesAreVisible
    ? activities
    : activities.slice(0, activities.length - 1);
  const activitiesInFeed = activitiesToShow.map(activity => ({
    timestamp: activity.timestamp,
    isPinned: false,
    element: (
      <ActivityCard
        key={`${activity.type}-${activity.timestamp}`}
        activity={activity}
        viewAs={viewAs}
        onChangeSection={onChangeSection}
      />
    ),
  }));
  const pinnedUpdates = updatesInFeed.filter(update => update.isPinned);
  const notPinnedUpdates = updatesInFeed.filter(update => !update.isPinned);
  const sortedFeed = [
    // pinned updates should always be displayed first
    ...pinnedUpdates,
    ...sort(
      (a, b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)),
      [...notPinnedUpdates, ...activitiesInFeed],
    ),
  ];
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  // While there are still updates to be paginated, we only show the number of posts equivalent to the number of updates fetched
  const feedToShow = allUpdatesAreVisible ? sortedFeed : sortedFeed.slice(0, updates.limit * updates.page);

  return (
    <>
      <div data-intercom-target="updates-widget">
        {feedToShow.length === 0 && shouldShowArchivedUpdates && viewAs === 'Admin' && (
          <Card>
            <p className="text-body">We could not find any archived updates</p>
          </Card>
        )}
        {updates.total === 0 && activities.length === 0 && viewAs !== 'Admin' && (
          <Card>
            <p className="text-body">{company.name} haven&apos;t posted any updates yet. Stay tuned!</p>
          </Card>
        )}
        {feedToShow.map(item => item.element)}
        {updates.total > 0 && (
          <SeeMore
            className={isMobile ? 'u-content-spacing-left' : undefined}
            resource={updates}
            loadResource={onSeeMore}
            limit={updates.limit}
          />
        )}
      </div>
      <Route
        exact
        path={`${match.path}/:updateId`}
        render={routeProps => (
          <UpdateDialog
            updateId={routeProps.match.params.updateId}
            dialogHandler={{ isOpen: true, open: () => {}, close: () => push(match.url) }}
            onDelete={() => {
              reloadUpdates();
              push(match.url);
            }}
            company={company}
          />
        )}
      />
    </>
  );
}
