import { calculateCompletedPercentage } from 'domain/onboarding/utils';
import React from 'react';
import ProgressPie from 'ui/elements/Progress/ProgressPie';
import DashboardMessage from './DashboardMessage';
import InvestorWizard from 'domain/onboarding/investor/UserOnboardingWizard';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resources from 'util/resource/Resources';
import Dialog from 'ui/views/dialogs/Dialog';
import useResource from 'util/resource/useResource';
import { Onboarding, OnboardingAPI, onboardingApiUrls } from 'apis/OnboardingAPI';
import useLazyResource from 'util/resource/useLazyResource';

export default function FinishUserProfile() {
  const { resource: userProfileResource } = useSelfUserProfile();

  const [investorWizardIsVisible, setInvestorWizardIsVisible] = React.useState(false);
  const { resource: onboardingResource } = useResource<Onboarding>(onboardingApiUrls.getUserOnboarding);

  const [patchUserOnboarding] = useLazyResource((status: 'Skipped' | 'Completed') =>
    OnboardingAPI.patchUserOnboarding(status),
  );

  return (
    <>
      <Resources renderLoading="Nothing" renderError="Nothing" resources={[onboardingResource, userProfileResource]}>
        {([userOnboarding, user]) => (
          <>
            <DashboardMessage
              key="user-profile-onboarding"
              color="indigo"
              topElement={
                <ProgressPie
                  width={70}
                  thickness={4}
                  fontSize="normal"
                  percentage={calculateCompletedPercentage(userOnboarding.wizard.steps)}
                />
              }
            >
              <p>Nearly there, complete your profile {user.isInvestor ? 'to get more relevant matches' : ''}</p>
              <button
                className="text-link-light data-track-dashboard-cta-go-to-user-profile"
                onClick={() => setInvestorWizardIsVisible(true)}
              >
                Complete now
              </button>
            </DashboardMessage>

            <Dialog open={investorWizardIsVisible} backdropColor="solid" verticalAlign="top" maxWidth="md">
              <InvestorWizard
                onShowCompanyWizard={() => {}} // User has already completed this step at this point, so it's not needed
                user={user}
                onboarding={userOnboarding.wizard}
                onClose={() => setInvestorWizardIsVisible(false)}
                onWizardCompleted={() => {
                  setInvestorWizardIsVisible(false);
                  patchUserOnboarding('Completed');
                }}
              />
            </Dialog>
          </>
        )}
      </Resources>
    </>
  );
}
