import styled from '@emotion/styled';
import { alpha, darken } from '@mui/material';
import React, { CSSProperties } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import FontRedHat from 'ui/theme/FontRedHat';
import { fontSizeTiny } from 'ui/theme/themeBluePlanet';
import { quarterSpacing } from 'ui/theme/themeConstants';

interface Props {
  children: React.ReactNode;
  explanation?: string;
  shape?: 'circle' | 'square';
  height?: number;
  width?: number;
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
}

const Button = styled.button(
  (props: { isCircular: boolean; height?: number; width?: number }) => `
  background-color: ${bluePlanetTheme.bluePlanetPalette.grey.medium};
  border: 1px dashed ${bluePlanetTheme.bluePlanetPalette.grey.main};
  border-radius: ${props.isCircular ? '50%' : `${bluePlanetTheme.shape.borderRadius}px`};
  font-size: ${fontSizeTiny};
  font-family: ${FontRedHat.text};
  color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  width: ${props.width ? `${props.width}px` : props.isCircular ? `${props.height}px` : '100%'};
  min-height: ${props.height ? `${props.height}px` : undefined};
  padding: 3px 15px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition:  ${bluePlanetTheme.transitions.create(['background-color', 'border-color', 'color'], {
    duration: bluePlanetTheme.transitions.duration.standard,
    easing: bluePlanetTheme.transitions.easing.easeOut,
  })};
  
  &:hover,
  &:focus {
    outline: none;
    color: ${darken(bluePlanetTheme.bluePlanetPalette.blue.main, 0.3)};
    border-color: ${bluePlanetTheme.bluePlanetPalette.blue.main};
    background-color: ${alpha(bluePlanetTheme.bluePlanetPalette.grey.medium, 0.6)};
  }
`,
);

const ExplanationText = styled.span`
  margin: ${quarterSpacing};
  color: ${bluePlanetTheme.bluePlanetPalette.indigo.veryLight};
  font-weight: ${bluePlanetTheme.typography.fontWeightRegular};
  max-width: 520px;
`;

export default function UploadCtaButton({ children, explanation, shape = 'square', height, width, ...rest }: Props) {
  return (
    <Button isCircular={shape === 'circle'} height={height || 25} width={width} {...rest}>
      {children}
      {explanation && <ExplanationText>{explanation}</ExplanationText>}
    </Button>
  );
}
