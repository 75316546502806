import { useMediaQuery } from '@mui/material';
import useContainerWidth from 'hooks/useContainerWidth';
import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { bluePlanetTheme } from 'ui/theme';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import MuiFullscreenDialog, { Content } from 'ui/views/dialogs/FullscreenDialog';
import IconButton from 'ui/elements/icons/IconButton';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import styles from './styles.scss';
import cx from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import useDebouncedIntersectionObserver from 'hooks/useDebouncedIntersectionObserver';

interface Props {
  pitchDeckPath: string;
  pageNumber: number;
  onPageChange: (num: number) => void;
  onClose: () => void;
}

export default function PitchDeckPDF({ pitchDeckPath, pageNumber, onPageChange, onClose }: Props) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const containerRef = React.useRef<HTMLDivElement>(null);
  const pageRefs = useMemo(
    () => Array.from({ length: numPages ?? 0 }, () => React.createRef<HTMLDivElement>()),
    [numPages],
  );

  const width = useContainerWidth(containerRef, [window.innerWidth, pitchDeckPath, numPages]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const closeDocument = () => {
    onClose();
  };

  useEffect(() => {
    if (numPages && pageNumber && pageRefs[pageNumber - 1].current) {
      setTimeout(() => {
        pageRefs[pageNumber - 1].current?.scrollIntoView({ behavior: 'instant', block: 'center' });
      }, 100);
    }
  }, [numPages, pageNumber, pageRefs]);

  useDebouncedIntersectionObserver(pageRefs, onPageChange, 500);

  const renderPDF = () => (
    <Document
      file={pitchDeckPath}
      onLoadSuccess={onDocumentLoadSuccess}
      externalLinkTarget="_blank"
      loading={<CircularProgress />}
      noData=""
    >
      {Array.from(new Array(numPages), (el, index) => (
        <div key={`page_${index + 1}`} ref={pageRefs[index]}>
          <Page
            className="u-section-spacing-bottom"
            pageNumber={index + 1}
            renderAnnotationLayer={true}
            renderTextLayer={false}
            width={width}
          />
        </div>
      ))}
    </Document>
  );

  return isMobile ? (
    <MuiFullscreenDialog open onClose={closeDocument}>
      <div className={styles.mobileCloseButtonWrapper}>
        <IconButton
          className={cx('u-half-spacing', styles.mobileCloseButton)}
          color="indigo"
          onClick={closeDocument}
          aria-label="Close"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Content padding="none">
        <div ref={containerRef}>{renderPDF()}</div>
      </Content>
    </MuiFullscreenDialog>
  ) : (
    <CenteredModalDialog
      open
      onClose={closeDocument}
      disableEnforceFocus
      overflowY="scroll" // always show scrollbar to prevent window from jumping
      width="wide"
    >
      <div ref={containerRef}>{renderPDF()}</div>
    </CenteredModalDialog>
  );
}
