import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import CompanyAPI from 'apis/CompanyAPI';
import React, { useState } from 'react';
import SupportEmailLink from 'ui/domain/SupportEmailLink';
import Loading from 'ui/elements/Loading';
import Button from 'ui/elements/buttons/Button';
import LinkButton from 'ui/elements/buttons/LinkButton';
import { bluePlanetTheme } from 'ui/theme';
import FontRedHat from 'ui/theme/FontRedHat';
import { ContactUsIfProblem } from 'util/i18n';
import useLazyResource from 'util/resource/useLazyResource';

const P = styled.p`
  color: ${bluePlanetTheme.bluePlanetPalette.grey.main};
  font-family: ${FontRedHat.text};
`;

export default function VerifyEmailMessage({ email }: { email?: string | null }) {
  const { logout } = useAuth0();

  return (
    <div>
      <div className="u-content-spacing-top">
        {email ? (
          <div className="u-section-spacing-bottom">
            <P>You registered with the email {email}.</P>
            <P className="u-flex">
              Not correct?
              <LinkButton className="u-quarter-spacing-left" size="small" onClick={() => logout({})}>
                Log out
              </LinkButton>
            </P>
          </div>
        ) : (
          <>
            <LinkButton onClick={() => logout({})}>Log in with a different account</LinkButton>
          </>
        )}
      </div>
      {email && <ResendConfirmation email={email} />}
      {!email && (
        <P>
          If you experience any problems, you can contact us directly at <SupportEmailLink />.
        </P>
      )}
    </div>
  );
}

function ResendConfirmation({ email }: { email: string }) {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [send, isSending] = useLazyResource(
    (email: string) => {
      return CompanyAPI.identity.resendVerificationEmail(email);
    },
    {
      onSuccess: () => {
        setSent(true);
        setError(false);
      },
      onFailure: () => {
        setError(true);
      },
    },
  );

  if (error) {
    return (
      <div className="text-small">
        {error && (
          <P className="text-body">
            Request failed. It could be because the previous email is still in transit. Please wait a few minutes before
            you
            <LinkButton
              className="u-quarter-spacing-left"
              onClick={() => send(email)}
              style={{ display: 'inline-block' }}
            >
              {isSending ? <Loading className="u-content-spacing-x" size={15} /> : 'try again'}
            </LinkButton>
            . <ContactUsIfProblem />
          </P>
        )}
      </div>
    );
  }

  return (
    <>
      {sent ? (
        <P className="text-weight-medium">
          Confirmation email has been resent and should appear in your inbox shortly!
        </P>
      ) : (
        <>
          <P className="u-content-spacing-bottom text-weight-medium">Haven&apos;t received any email? </P>
          <Button kind="primary" onClick={() => send(email)} isLoading={isSending} isSuccess={sent}>
            Resend email
          </Button>
        </>
      )}
    </>
  );
}
