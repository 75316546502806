import React from 'react';
import { Link } from 'react-router-dom';
import { DetailedUserProfile, investorTypes } from 'types/user';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import SkillChip from 'ui/domain/Chips/SkillChip';
import Avatar from 'ui/elements/avatars/Avatar';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import { userUrls } from 'urls';
import { formatThousands } from 'util/numbers';
import KeyUserInfo from './KeyUserInfo';
import Button from 'ui/elements/buttons/Button';
import EditIcon from 'ui/elements/icons/EditIcon';
import { formatCurrencyRange, getCurrencySymbol } from 'util/currency';
import InvolvementList from './InvolvementList';
import { useMediaQuery, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { quarterSpacing } from 'ui/theme/themeConstants';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import isEmpty from 'ramda/src/isEmpty';

const Label = styled.label`
  display: block;
  margin-bottom: ${quarterSpacing};
`;

export default function UserProfile({ user, showEdit }: { user: DetailedUserProfile; showEdit: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasInvestmentPreferences =
    user.isInvestor &&
    (user.investorType != null ||
      user.industryInvestmentPreferences.length > 0 ||
      (user.investmentExperience && user.investmentExperience.length > 0) ||
      user.investmentCapacity?.value ||
      user.preferredInvestmentSize.rangeFrom ||
      user.preferredInvestmentSize.rangeTo);

  const hasInvolvementSection =
    (user.involvement && Object.values(user.involvement).includes(true)) || !isEmpty(user.investmentPreference);

  const chipsLimit = isMobile ? 4 : Infinity;

  return (
    <>
      {showEdit && (
        <Link to={userUrls.profile.edit} className="u-flex-end">
          <Button kind="secondary">
            <EditIcon className="u-quarter-spacing-right"></EditIcon>Edit profile
          </Button>
        </Link>
      )}
      <div className="u-flex-align-center u-flex--column">
        <Avatar
          size={isMobile ? 144 : 200}
          resize={isMobile ? 288 : 400}
          imageUrl={user.imageUrl}
          userName={user.name}
          style={{
            display: 'block',
            position: 'relative',
            top: isMobile ? '77px' : '100px',
            zIndex: 1,
            marginTop: isMobile ? '-60px' : '-65px', // remove dead space above user avatar
          }}
        />
      </div>
      <KeyUserInfo className="u-content-spacing-bottom" user={user} />
      {user.isInvestor && (
        <Card>
          {hasInvestmentPreferences && (
            <>
              <SectionHeading heading="Investment profile"></SectionHeading>
              {user.investorType && (
                <div className="u-section-spacing-right u-content-spacing-bottom">
                  <Label className="text-metadata">Type of investor</Label>
                  <span className="text-h4">{investorTypes[user.investorType].name}</span>
                </div>
              )}
              <div className="u-flex u-content-spacing-bottom">
                {user.preferredInvestmentSize && (
                  <div className="u-section-spacing-right">
                    <Label className="text-metadata">Investment size</Label>
                    <span className="text-h4">
                      {formatCurrencyRange(
                        user.preferredInvestmentSize.currency,
                        user.preferredInvestmentSize.rangeFrom,
                        user.preferredInvestmentSize.rangeTo,
                      )}
                    </span>
                  </div>
                )}

                {(user.investmentCapacity?.value ?? 0) > 0 && (
                  <div>
                    <Label className="text-metadata">Capacity next 3 years</Label>
                    <span className="text-h4">
                      {getCurrencySymbol(user.investmentCapacity?.currency)}
                      {formatThousands(user.investmentCapacity?.value)}
                    </span>
                  </div>
                )}
              </div>

              {user.industryInvestmentPreferences.length > 0 && (
                <div className="u-content-spacing-bottom">
                  <h6 className="text-metadata u-half-spacing-bottom">Industry investment preference</h6>
                  <ChipList>
                    <TruncatedList
                      values={user.industryInvestmentPreferences}
                      limit={chipsLimit}
                      renderItem={label => <IndustryChip label={label.name} />}
                      renderExpandButton={(label, onClick) => <IndustryChip label={label.name} onClick={onClick} />}
                    />
                  </ChipList>
                </div>
              )}

              {user.investmentExperience && user.investmentExperience.length > 0 && (
                <div>
                  <h6 className="text-metadata u-half-spacing-bottom">Investment experience</h6>
                  <ChipList>
                    <TruncatedList
                      values={user.investmentExperience.map(x => ({ name: x }))}
                      limit={chipsLimit}
                      renderItem={label => <SkillChip label={label.name} />}
                      renderExpandButton={(label, onClick) => <SkillChip label={label.name} onClick={onClick} />}
                    />
                  </ChipList>
                </div>
              )}
            </>
          )}
          {hasInvolvementSection && (
            <div className="u-section-spacing-top">
              <SectionHeading heading="Investor involvement" />
              <InvolvementList involvement={user.involvement} />
              {user.investmentPreference && (
                <div className="u-content-spacing-top">
                  <p className="text-body">{user.investmentPreference}</p>
                </div>
              )}
            </div>
          )}
        </Card>
      )}
    </>
  );
}
