import { Menu, MenuItem, PopoverOrigin } from '@mui/material';
import React, { CSSProperties } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { Link } from 'react-router-dom';
import IconButton from 'ui/elements/icons/IconButton';
import NestedMenuItem from './Submenu';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import { IconColor } from 'ui/elements/icons/SvgIcon';
import Tooltip from 'ui/elements/Tooltip';

export interface PopMenuItem {
  onClick?: () => void;
  href?: string;
  icon?: JSX.Element;
  text: JSX.Element;
  selected?: boolean;
  subMenu?: PopMenuItem[];
}

interface Props {
  style?: CSSProperties;
  className?: string;
  items?: PopMenuItem[];
  icon?: JSX.Element;
  tooltip?: string;
  iconColor?: IconColor;
  staticItems?: React.ReactNode;
  renderAnchor?: (onClick: (e: React.MouseEvent<HTMLElement>) => void, isOpen: Boolean) => JSX.Element;
  anchorOrigin?: PopoverOrigin;
}

export default function PopMenu({
  style,
  className,
  icon,
  tooltip,
  iconColor,
  renderAnchor,
  staticItems,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  items = [],
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  return (
    <div className={className} style={style}>
      {renderAnchor ? (
        renderAnchor(handleClick, open)
      ) : tooltip ? (
        <Tooltip title={tooltip}>
          <span>
            <IconButton color={iconColor ?? 'indigo'} onClick={handleClick}>
              {icon ?? <EllipsisVertical />}
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton color={iconColor ?? 'indigo'} onClick={handleClick}>
          {icon ?? <EllipsisVertical />}
        </IconButton>
      )}
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin}>
        {items.map((item, i) =>
          item.subMenu ? (
            <NestedMenuItem
              key={i}
              parentMenuOpen={open}
              subMenu={item.subMenu.map((item, i) => (
                <MenuItem
                  key={`submenu-${i}`}
                  selected={item.selected}
                  onClick={() => {
                    item.onClick && item.onClick();
                    handleClose();
                  }}
                >
                  {item.text}
                </MenuItem>
              ))}
            >
              {item.text}
            </NestedMenuItem>
          ) : (
            <NestedMenuItem
              parentMenuOpen={open}
              key={i}
              onClick={() => {
                item.onClick && item.onClick();
                handleClose();
              }}
            >
              <MenuItemChild item={item} />
            </NestedMenuItem>
          ),
        )}
        {staticItems && <div style={{ padding: bluePlanetTheme.spacing(1) }}>{staticItems}</div>}
      </Menu>
    </div>
  );
}

function MenuItemChild({ item }: { item: PopMenuItem }) {
  if (item.href && item.onClick) {
    throw Error(`PopMenu: PopMenu.item ${item.text} can not have an href and a onClick at the same time.`);
  }
  if (!item.href && !item.onClick) {
    throw Error(`PopMenu: PopMenu.item ${item.text} needs a href or a onClick.`);
  }
  if (item.href) {
    return (
      <Link style={{ display: 'flex' }} to={item.href}>
        {item.icon && <span className="u-flex u-half-spacing-right">{item.icon}</span>}
        {item.text}
      </Link>
    );
  }

  return (
    <>
      {item.icon && <span className="u-flex u-half-spacing-right">{item.icon}</span>}
      {item.text}
    </>
  );
}
