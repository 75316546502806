import { CircularProgress, Fade, FormHelperText } from '@mui/material';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import IconButton from '../icons/IconButton';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import CloseIcon from '../icons/CloseIcon';
import Label from './Label';
import TextField from './TextField';

interface Props<T> {
  label: string;
  name: string;
  value: T;
  placeholder?: string;
  originalValue?: T;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onCancel: () => void;
  onConfirm: (value: T) => Promise<any> | void;
  error?: string;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  className?: string;
}

export default function QuickEditField<T>({
  label,
  name,
  value,
  placeholder,
  originalValue,
  onChange,
  onCancel,
  onConfirm,
  error,
  maxLength,
  minRows,
  maxRows,
  className,
}: Props<T>) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  async function handleSubmit() {
    if (!error) {
      setIsSubmitting(true);
      await onConfirm(value);
      setIsSubmitting(false);
    }
  }

  return (
    <div className={className}>
      <Label>{label}</Label>
      <div className="u-flex-align-stretch u-relative">
        <TextField
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={e => onChange(e)}
          multiline={!!minRows || !!maxRows}
          maxLength={maxLength}
          minRows={minRows}
          maxRows={maxRows}
          error={!!error}
        />
        {((!originalValue && value) || originalValue !== value) && (
          <div
            style={{
              border: `1px solid ${
                error ? bluePlanetTheme.bluePlanetPalette.red.main : bluePlanetTheme.bluePlanetPalette.grey.main
              }`,
              background: 'white',

              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              marginLeft: -4,
            }}
            className="u-quarter-padding-x u-flex-align-center z-layer-1"
          >
            <IconButton
              size="small"
              color="blue"
              disabled={!!error}
              onClick={handleSubmit}
              className="u-quarter-spacing-right"
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Fade in={isSubmitting} unmountOnExit exit={false}>
                <CircularProgress disableShrink size={16} color="inherit" />
              </Fade>
              {!isSubmitting && <CheckmarkIcon fontSize="inherit" />}
            </IconButton>
            <IconButton
              size="small"
              color="grey"
              onClick={onCancel}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </div>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}
