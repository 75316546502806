import React, { useEffect, useState } from 'react';

import Card from 'ui/views/cards/Card';
import SectionHeading from 'ui/elements/SectionHeading';
import EditAction from 'ui/elements/actions/EditAction';
import { ICompany, VideoListDTO } from 'types/company';
import Videos from './videos';
import PitchDocuments from './pitchDocuments';
import { getOrUndefined, IResource } from 'util/resource';
import { DocumentList } from 'types/documents';
import { KeyedMutator } from 'swr';
import { CompanyViewAs } from 'domain/companies/roleUtils';

interface Props {
  company: ICompany;
  canEditCompany: boolean;
  videos: VideoListDTO;
  documents: IResource<DocumentList>;
  setDocuments: KeyedMutator<DocumentList>;
  reloadVideos: () => void;
  viewAs: CompanyViewAs;
}

export default function PitchAndPresentation({
  company,
  videos,
  canEditCompany,
  documents,
  setDocuments,
  reloadVideos,
  viewAs,
}: Props) {
  const showEmptyPlaceholder = canEditCompany && videos.values.length === 0;
  const hasDocuments = (getOrUndefined(documents)?.documents.length ?? 0) > 0;
  const hasContent = videos.values.length > 0 || hasDocuments;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [editDialogSource, setEditDialogSource] = useState<'videos' | 'documents' | null>(null);

  const [videoList, setVideoList] = useState<VideoListDTO>({ values: [] });

  const updateVideoList = (videos: VideoListDTO) => {
    setVideoList(() => {
      return videos;
    });
    reloadVideos && reloadVideos();
  };

  useEffect(() => {
    setVideoList(videos);
  }, [company.id]);

  return (
    <div>
      {(canEditCompany || hasContent) && (
        <SectionHeading heading="Pitch and presentation" addMobileMargin>
          {canEditCompany && (
            <button
              onClick={() => {
                setIsEditDialogOpen(true);
                setEditDialogSource('documents');
              }}
              aria-label="Edit section"
            >
              <EditAction tooltip="Edit section" />
            </button>
          )}
        </SectionHeading>
      )}
      {(videoList.values.length > 0 || showEmptyPlaceholder) && (
        <Card
          onClick={
            showEmptyPlaceholder && !isEditDialogOpen
              ? () => {
                  setIsEditDialogOpen(true);
                  setEditDialogSource('videos');
                }
              : undefined
          }
          type={showEmptyPlaceholder ? 'dashed' : 'default'}
          className={hasDocuments || canEditCompany ? 'u-content-spacing-bottom' : undefined}
        >
          <Videos
            companyId={company.id}
            videos={videoList}
            canEditCompany={canEditCompany}
            openEditDialog={() => {
              setIsEditDialogOpen(true);
              setEditDialogSource('videos');
            }}
            playerMaxWidth="695px"
          />
        </Card>
      )}
      <PitchDocuments
        company={company}
        userCanUploadDocuments={canEditCompany}
        documents={documents}
        setDocuments={setDocuments}
        videos={videoList}
        onSaveVideos={updateVideoList}
        isEditDialogOpen={isEditDialogOpen}
        setIsEditDialogOpen={isOpen => {
          setIsEditDialogOpen(isOpen);
          setEditDialogSource(null);
        }}
        shouldAutofocusVideos={editDialogSource === 'videos'}
        viewAs={viewAs}
      />
    </div>
  );
}
