import React, { createRef, useState } from 'react';
import { UserProfile } from 'types/user';
import UserElement from 'ui/domain/Users/UserElement';
import Card from 'ui/views/cards/Card';
import { useDebouncedCallback } from 'use-debounce';
import styles from './styles.scss';
import UserChip from 'ui/domain/Chips/UserChip';
import { useDispatch } from 'react-redux';
import { fetchUserProfiles } from 'globalstate/reducers/userProfiles';
import Loading from 'ui/elements/Loading';
import SearchIcon from 'ui/elements/icons/SearchIcon';
import useWindowWidth from 'hooks/useWindowWidth';
import { bluePlanetTheme } from 'ui/theme';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';

type Props = {
  initialUsers: UserProfile[];
  onChange: (selectedUsers: UUID[]) => void;
  sharedCompanyId?: number;
  noResultsText?: string;
};

export default function UserSearch(props: Props) {
  const [selectedUsers, setSelectedUsers] = useState<UserProfile[]>([]);
  const width = useWindowWidth();
  const isMobile = width < bluePlanetTheme.breakpoints.values.sm;

  const [filter, setFilter] = useState('');
  const [fetchedUsers, setUsers] = useState<UserProfile[]>([]);
  const availableUsers = fetchedUsers.filter(
    user => ![...props.initialUsers, ...selectedUsers].some(u => u.cwUserId === user.cwUserId),
  );
  const [isFetched, setIsFetched] = useState(false);
  const inputRef = createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  const fetchUsers = useDebouncedCallback(async (filter: string) => {
    const promises = [usersApi.myNetwork(filter)];
    if (props.sharedCompanyId) {
      promises.push(companiesApi.network.users(props.sharedCompanyId, filter));
    }

    Promise.all(promises).then(([myNetworkUsers, companyNetworkUsers]) => {
      const allUsers = (myNetworkUsers?.values ?? []).concat(companyNetworkUsers?.values ?? []);
      setUsers(allUsers);
      dispatch(fetchUserProfiles({ values: allUsers }));
      setIsFetched(true);
    });
  }, 250);

  return (
    <div className={styles.container}>
      {props.initialUsers?.map(u => (
        <UserChip className="u-quarter-spacing-right u-quarter-spacing-y" key={u.cwUserId} label={u.name} />
      ))}
      {selectedUsers.map(u => (
        <UserChip
          className="u-quarter-spacing-right u-quarter-spacing-y"
          key={u.cwUserId}
          label={u.name}
          onDelete={() => {
            const users = selectedUsers.filter(user => user.cwUserId !== u.cwUserId);
            setSelectedUsers(users);
            props.onChange([...props.initialUsers, ...users].map(u => u.cwUserId));
            inputRef.current?.focus();
          }}
        />
      ))}
      <div className={styles.inputContainer}>
        {!selectedUsers.length && <SearchIcon className="u-quarter-spacing-right" color="grey" fontSize="small" />}
        <input
          className={styles.input}
          type="text"
          ref={inputRef}
          value={filter}
          autoFocus={!isMobile}
          placeholder={!selectedUsers.length ? 'Search' : ''}
          onChange={e => {
            fetchUsers(e.currentTarget.value);
            setFilter(e.currentTarget.value);
          }}
        />
        {filter && (
          <Card padding="none" elevation={4} className={styles.dropdown}>
            {!isFetched ? (
              <div className="u-content-spacing u-flex-center">
                <Loading />
              </div>
            ) : availableUsers.length > 0 ? (
              availableUsers.map(user => (
                <button
                  className={styles.user}
                  key={`user-search-${user.cwUserId}`}
                  onClick={() => {
                    const users = [...selectedUsers, user];
                    setSelectedUsers(users);
                    props.onChange([...props.initialUsers, ...users].map(u => u.cwUserId));
                    setFilter('');
                    setIsFetched(false);
                    inputRef.current?.focus();
                  }}
                >
                  <UserElement user={user} />
                </button>
              ))
            ) : (
              <div className="u-flex-center u-half-spacing-y text-weight-regular text-medium">
                {props.noResultsText ?? 'No results'}
              </div>
            )}
          </Card>
        )}
      </div>
    </div>
  );
}
