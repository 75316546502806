import useDialogHandler from 'hooks/useDialogHandler';
import React, { useState } from 'react';
import { ICompany } from 'types/company';
import UnfollowDialog from 'domain/companies/Follow/UnfollowDialog';
import { CircularProgress, Fade, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import MinusIcon from 'ui/elements/icons/MinusIcon';
import TimerIcon from 'ui/elements/icons/TimerIcon';
import PlusBoxIcon from 'ui/elements/icons/PlusBoxIcon';
import CheckmarkBoxIcon from 'ui/elements/icons/CheckmarkBoxIcon';
import { IResource } from 'util/resource';

interface Props {
  company: ICompany;
  isFollowingCompany: boolean;
  onFollow: () => void;
  onUnfollow: () => Promise<IResource<void>>;
  isUnfollowing: boolean;
  isSendingFollowRequest: boolean;
  isPendingFollowerRequest: boolean;
  pendingStateIsDisabled: boolean;
  setPendingStateIsDisabled: (isDisabled: boolean) => void;
  trackingKey?: string;
}

export default function FollowMenuItem(props: Props) {
  const [isHoveringPending, setHoveringPending] = useState(false);
  const unfollowDialogHandler = useDialogHandler();

  if (props.isFollowingCompany) {
    return (
      <>
        <MenuItem onClick={unfollowDialogHandler.open}>
          <ListItemIcon>
            <CheckmarkBoxIcon strokeWidth={1} fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Following</ListItemText>
        </MenuItem>
        <UnfollowDialog
          dialogHandler={unfollowDialogHandler}
          onUnfollow={props.onUnfollow}
          isUnfollowing={props.isUnfollowing}
          companyName={props.company.name}
          requestIsPending={props.isPendingFollowerRequest}
        />
      </>
    );
  } else if (props.isPendingFollowerRequest) {
    return (
      <>
        <MenuItem
          onClick={unfollowDialogHandler.open}
          // We don't want unfollow button to appear immediately after following
          onMouseEnter={() => setHoveringPending(true)}
          onMouseLeave={() => {
            props.setPendingStateIsDisabled(false);
            setHoveringPending(false);
          }}
        >
          <ListItemIcon>
            {isHoveringPending ? <MinusIcon fontSize="medium" /> : <TimerIcon strokeWidth={1} fontSize="medium" />}
          </ListItemIcon>
          <ListItemText>
            {isHoveringPending && !props.pendingStateIsDisabled ? 'Withdraw' : 'Waiting for approval'}
          </ListItemText>
        </MenuItem>
        <UnfollowDialog
          dialogHandler={unfollowDialogHandler}
          isUnfollowing={props.isUnfollowing}
          onUnfollow={props.onUnfollow}
          companyName={props.company.name}
          requestIsPending={props.isPendingFollowerRequest}
        />
      </>
    );
  } else {
    return (
      <MenuItem onClick={props.onFollow} className={props.trackingKey ? `data-track-${props.trackingKey}` : undefined}>
        <ListItemIcon>
          {props.isSendingFollowRequest ? (
            <Fade in={props.isSendingFollowRequest} unmountOnExit exit={false}>
              <CircularProgress disableShrink size={17} color="inherit" />
            </Fade>
          ) : (
            <PlusBoxIcon fontSize="medium" />
          )}
        </ListItemIcon>
        <ListItemText>Follow</ListItemText>
      </MenuItem>
    );
  }
}
