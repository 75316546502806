import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import React, { CSSProperties, MouseEventHandler, useEffect, useRef } from 'react';
import styles from './styles.scss';
import CheckmarkIcon from '../icons/CheckmarkIcon';

export type ButtonColors =
  | 'blue'
  | 'blueLight'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'greenDark'
  | 'indigo'
  | 'white'
  | 'grey'
  | 'purpleLight'
  | 'greyLight';

export interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
  color?: ButtonColors;
  autoFocus?: boolean;
  kind: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  shape?: 'square' | 'rounded';
  buttonRef?: React.RefObject<HTMLButtonElement>;
  isLoading?: boolean;
  isSuccess?: boolean;
  setSuccess?: (isSuccess: boolean) => void;
  style?: CSSProperties;
  tabIndex?: number;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  hasSubtleHoverStyle?: boolean;
}

export default function Button(props: ButtonProps) {
  const color = props.color ?? 'blue';
  const size = props.size ?? 'medium';
  const shape = props.shape ?? 'square';
  const { isLoading, isSuccess, setSuccess, buttonRef, hasSubtleHoverStyle, ...buttonProps } = props;
  const successTimerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      successTimerRef.current = setTimeout(() => (!!setSuccess ? setSuccess(false) : undefined), 4000);
    }
    return () => {
      if (successTimerRef.current) {
        clearTimeout(successTimerRef.current);
      }
    };
  }, [isSuccess]);

  return (
    <button
      {...buttonProps}
      className={cx(props.className, styles.button, styles[color], styles[props.kind], styles[size], styles[shape], {
        [styles.success]: isSuccess,
        [styles.inactive]: props.disabled && !isLoading,
        [styles.subtleHoverStyle]: hasSubtleHoverStyle,
      })}
      ref={buttonRef}
      disabled={props.disabled || isLoading}
      type={props.type || 'button'}
      onMouseLeave={props.onMouseLeave}
    >
      <Fade in={isLoading} unmountOnExit exit={false}>
        <CircularProgress className={styles.spinner} disableShrink size={17} color="inherit" />
      </Fade>
      <span
        className={cx({
          [styles.isLoading]: isLoading,
        })}
        style={{ display: 'inline-flex', alignItems: 'center', minHeight: 18 }}
      >
        {isSuccess && <CheckmarkIcon className="u-quarter-spacing-right" fontSize="small" />}
        {isSuccess ? 'Saved' : props.children}
      </span>
    </button>
  );
}
