import { DashboardData } from 'apis/DashboardAPI';
import dashboardApi from 'apis/DashboardAPI';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';

export const userDashboardKey = dashboardApi.getUserDashboardData();

export const useUserDashboardData = () => {
  const swrResult = useSWR<DashboardData>(userDashboardKey);
  return useSWRResource(swrResult);
};
