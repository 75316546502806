import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material';
import MuiTab, { TabProps } from '@mui/material/Tab';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { quarterSpacing } from 'ui/theme/themeConstants';

interface Props {
  onClick?: () => void;
  value: string;
  label?: React.ReactNode;
  icon?: React.ReactElement;
  iconPosition?: TabProps['iconPosition'];
  hasActivityIndicator?: boolean;
  sx?: SxProps<Theme>;
}

const ActivityDot = styled.span`
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: ${bluePlanetTheme.bluePlanetPalette.orange.main};
  border-radius: 50%;
  right: -8px;
  top: 0;
`;

function Label({ hasActivityIndicator, children }: { hasActivityIndicator?: boolean; children?: React.ReactNode }) {
  return hasActivityIndicator ? (
    <span style={{ position: 'relative' }}>
      {children}
      <ActivityDot />
    </span>
  ) : (
    <>{children}</>
  );
}

export default function Tab(props: Props) {
  const { value, hasActivityIndicator, ...rest } = props;
  const label = props.label ?? value;
  return (
    <MuiTab
      sx={{
        '.MuiTab-iconWrapper': {
          marginRight: quarterSpacing,
        },
      }}
      value={value}
      {...rest}
      label={<Label hasActivityIndicator={hasActivityIndicator}>{label}</Label>}
    />
  );
}
