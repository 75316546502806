import React, { CSSProperties } from 'react';

import Button, { ButtonColors, ButtonProps } from 'ui/elements/buttons/Button';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import cx from 'classnames';

interface Props {
  onClick: (e: React.MouseEvent) => void;
  kind: ButtonProps['kind'];
  numUnreadMessages: number;
  buttonText?: string;
  color?: ButtonColors;
  icon?: 'default' | 'none';
  style?: CSSProperties;
  className?: string;
}

export default function ChatButton(props: Props) {
  const { kind, onClick } = props;
  const hasUnread = props.numUnreadMessages > 0;
  return (
    <Button
      kind={hasUnread ? 'primary' : kind}
      color={hasUnread ? 'orange' : props.color ?? 'blue'}
      className={cx('data-track-message-company', props.className)}
      onClick={onClick}
      style={props.style}
    >
      {props.icon !== 'none' && <ChatBubblesIcon className="u-quarter-spacing-right" fontSize="small" />}
      {props.buttonText ?? 'Message'}
      {hasUnread ? ` (${props.numUnreadMessages})` : ''}
    </Button>
  );
}
