import React, { useRef } from 'react';
import Logo from 'ui/domain/Logo';
import Card from 'ui/views/cards/Card';
import cx from 'classnames';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import Button from 'ui/elements/buttons/Button';
import FollowButton from 'domain/companies/Follow/FollowButton';
import Chip from 'ui/elements/Chip';
import { CompanyCardDTO } from 'types/company/community';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import styles from './styles.scss';
import CompanyDetails from './CompanyDetails';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import useDialogHandler from 'hooks/useDialogHandler';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import { Shortlist } from 'types/user';
import { KeyedMutator } from 'swr';
import { List } from 'types/api';
import CompanyShortlistPicker from 'domain/Shortlists/CompanyShortlistPicker';
import useRoute from 'hooks/useRoute';
import HrefOrOnClickWrapper, { HrefOrOnClick } from './HrefOrOnClickWrapper';
import { companyUrls, staticFileLocations } from 'urls';
import { resize } from 'util/cloudinary';
import { CARD_IMAGE_WIDTH } from 'util/constants';
import LastUpdated from '../profile/LastUpdated';
import useContainerWidth from 'hooks/useContainerWidth';
import SendIcon from 'ui/elements/icons/SendIcon';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getIframeCode } from 'pages/CompanySettings/utils';
import useNotify from 'hooks/useNotify';
import PopMenu from 'ui/modules/PopMenu';
import QrCodeIcon from 'ui/elements/icons/QrCodeIcon';
import { QrCodeDialog } from '../profile/ShareCompany';
import config from 'config';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import CodeIcon from 'ui/elements/icons/CodeIcon';

type Props = {
  action: HrefOrOnClick;
  company: CompanyCardDTO;
  className?: string;
  shortlists: Shortlist[];
  setShortlists: KeyedMutator<List<Shortlist>>;
};

export default function CompanyCard({ company, className, action, shortlists, setShortlists }: Props) {
  const { push } = useRoute();
  const shareDialogHandler = useDialogHandler();
  const qrCodeDialogHandler = useDialogHandler();

  const companyImageURL = resize(company.details.imageURL || staticFileLocations.defaultCompanyImage, {
    width: CARD_IMAGE_WIDTH,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const width = useContainerWidth(containerRef, []) ?? 0;

  const notify = useNotify();

  const {
    copy: copyProfileLink,
    showCopiedSuccess: showProfileLinkCopiedSuccess,
    clear: clearProfileLinkCopiedSuccess,
  } = useCopyToClipboard(
    `${location.origin}${companyUrls.overview(
      company.details.slug,
      'profile',
    )}?utm_source=direct&utm_medium=copy-link&utm_campaign=company&utm_term=card`,
  );
  const {
    copy: copyEmbedCode,
    showCopiedSuccess: showEmbedCodeCopiedSuccess,
    clear: clearEmbedCodeCopiedSuccess,
  } = useCopyToClipboard(getIframeCode(company.details.slug, 'detailed'));

  const sharingMenuItems = [
    {
      icon: <SendIcon />,
      text: <>Send in chat</>,
      onClick: () => {
        shareDialogHandler.open();
      },
    },
    {
      icon: showProfileLinkCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <LinkIcon />,
      text: <>Copy link to profile</>,
      onClick: () => {
        clearEmbedCodeCopiedSuccess();
        copyProfileLink();
        notify('success', `Profile link of ${company.details.name} copied to clipboard`);
      },
    },
    {
      icon: showEmbedCodeCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <CodeIcon />,
      text: <>Copy embed code</>,
      onClick: () => {
        clearProfileLinkCopiedSuccess();
        copyEmbedCode();
        notify('success', `Embed code of ${company.details.name} copied to clipboard`);
      },
    },
    {
      icon: <QrCodeIcon />,
      text: <>Show QR</>,
      onClick: () => {
        qrCodeDialogHandler.open();
      },
    },
  ];

  return (
    <Card elevation={1} className={cx(styles.card, className)} style={{ overflow: 'hidden' }} hover="drop-shadow">
      <HrefOrOnClickWrapper
        company={company.details}
        action={action}
        trackingKey="data-track-companies-grid-open-pitch"
      >
        <div
          style={{
            position: 'relative',
            marginLeft: `-${contentSpacing}`,
            marginRight: `-${contentSpacing}`,
            marginTop: `-${contentSpacing}`,
            marginBottom: `${halfSpacing}`,
          }}
        >
          <div
            className={styles.bannerImage}
            style={{
              backgroundImage: `url(${companyImageURL})`,
            }}
          ></div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} ref={containerRef}>
          <Logo size={45} className={cx('u-content-spacing-right')} type="company" url={company.details.logoURL} />
          <div>
            <h3 className={'text-h4 ' + (width < 300 ? 'text-medium' : '')} style={{ lineHeight: '1.1' }}>
              {company.details.name}
            </h3>
            <LastUpdated
              className="u-quarter-spacing-top"
              companyId={company.details.id}
              lastUpdated={company.details.lastUpdated}
              hideInactive
              size={width < 300 ? 'micro' : 'tiny'}
            ></LastUpdated>
          </div>
        </div>
        <CompanyDetails
          location={company.location}
          established={company.details.established}
          fundingStage={company.fundingStage}
        />
        <div style={{ minHeight: 29 }}>
          <ChipList>
            {company.hasActiveFundingRound && (
              <Chip fontSize="small" label="Raising now" color="orange" className={styles.limitLabelWidth} />
            )}
            <TruncatedList
              values={company.industries}
              limit={2}
              renderItem={label => <IndustryChip label={label.name} />}
              renderExpandButton={label => <IndustryChip label={label.name} />}
            />
          </ChipList>
        </div>
        <p className={cx('u-half-spacing-y', 'text-body', styles.mission)}>{company.details.mission}</p>
      </HrefOrOnClickWrapper>

      <div style={{ marginTop: 'auto' }} className="u-flex-space-between">
        <div className="u-flex u-flex-align-center">
          <Button
            kind="primary"
            onClick={() => {
              action.type === 'href' ? push(action.handler(company.details)) : action.handler(company.details.id);
            }}
            className={cx('data-track-companies-grid-open-pitch', 'u-quarter-spacing-right', 'u-ellipsis')}
          >
            View
          </Button>
          <FollowButton
            mode="compact"
            kind="tertiary"
            company={company.details}
            trackingKey="community-pitch-request-to-follow"
          />
        </div>
        <div className="u-flex u-flex-align-center">
          <PopMenu tooltip="Share" icon={<ShareIcon fontSize="small" />} iconColor="blue" items={sharingMenuItems} />
          <CompanyShortlistPicker
            company={company.details}
            shortlists={shortlists}
            setShortlists={setShortlists}
            menuItemHeight={'50px'}
            dropdownWidth={'250px'}
          />
        </div>
      </div>
      {shareDialogHandler.isOpen && (
        <UserConversationDialog
          sharedContent={{ type: 'company', companyId: company.details.id }}
          conversation={{ type: 'new' }}
          closeConversation={shareDialogHandler.close}
        />
      )}
      {qrCodeDialogHandler.isOpen && (
        <QrCodeDialog
          companyName={company.details.name}
          url={`${config.PORTAL_URL}/companies/${company.details.slug}`}
          handler={qrCodeDialogHandler}
          filename={company.details.name}
        />
      )}
    </Card>
  );
}
