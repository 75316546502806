import React, { useState } from 'react';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import Hidden from '@mui/material/Hidden';
import Tooltip from 'ui/elements/Tooltip';
import { UpdateVisibility } from 'types/content';
import { visibilityDisplayString as visibilityDisplayString } from './utils';

interface Props {
  visibility: UpdateVisibility;
  communityName?: string;
}

export default function VisibleFor({ visibility: visibleFor, communityName }: Props) {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const tooltip = visibilityDisplayString(visibleFor, communityName);

  return (
    <>
      <Hidden mdUp>
        <Tooltip
          title={tooltip}
          placement="bottom"
          open={tooltipIsOpen}
          onClose={() => setTooltipIsOpen(false)}
          onOpen={() => setTooltipIsOpen(true)}
          disableHoverListener
          arrow={true}
        >
          <span>
            <button onClick={() => setTooltipIsOpen(!tooltipIsOpen)} aria-label="Who is this visible for?">
              <EyeIcon fontSize="small" />
            </button>
          </span>
        </Tooltip>
      </Hidden>
      <Hidden mdDown>
        <Tooltip title={tooltip} placement="bottom" arrow={true}>
          <span>
            <EyeIcon fontSize="small" />
          </span>
        </Tooltip>
      </Hidden>
    </>
  );
}
