import { CircularProgress } from '@mui/material';
import useDownloadWarning from '../useDownloadWarning';
import { BoardroomDocumentColumnContent } from 'pages/Boardroom/Documents/DocumentListing';
import { RouteContext } from 'pages/CompanyContainer';
import React, { ReactEventHandler, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from 'types/user';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import Tooltip from 'ui/elements/Tooltip';
import { sharedUrls } from 'urls';
import { dateMonthYear } from 'util/dateUtils';
import { getDescription } from 'util/trustLevelUtils';
import { DocumentColumnContent } from '../DocumentListing';
import useLazyResource from 'util/resource/useLazyResource';
import DocumentAPI from 'apis/DocumentAPI';
import { downloadBlob } from 'domain/shared/Files/actions';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import { useDownloadDocument } from '../useDownloadDocument';
import { boardroomDocumentsKey, refetchUnreadBoardroomDocuments } from 'apis/DocumentAPI/useBoardroomDocuments';
import { ICompany } from 'types/company';

interface ClickableCellProps {
  children: React.ReactNode;
  style: object;
  className?: string;
  onClick: ReactEventHandler<HTMLButtonElement>;
}

function ClickableCell({ style, onClick, children, className }: ClickableCellProps) {
  return (
    <button
      style={{ ...style, display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center' }}
      onClick={onClick}
      className={className}
    >
      {children}
    </button>
  );
}

export function NameCell({
  original,
  canUploadDocuments,
  companyId,
  folderNavigation,
  userProfiles,
  context,
}: {
  original: DocumentColumnContent;
  canUploadDocuments: boolean;
  companyId: number;
  folderNavigation: string | ((folderId: string) => void);
  userProfiles: UserProfile[];
  context: 'community' | 'company';
}) {
  const [downloadDocument, isDownloading] = useDownloadDocument(companyId);

  const folderId = original.type === 'document' ? original.folderId : undefined;
  const { onClick, WarningDialog } = useDownloadWarning(original.value.name, () =>
    downloadDocument({ documentId: original.value.id, folderId }),
  );

  if (original.type === 'folder') {
    const suffix = original.value.personalization.numUnreadDocuments > 0 && (
      <Indicator className="u-half-spacing-left" value={original.value.personalization.numUnreadDocuments} />
    );

    const visibleFor =
      original.value.accesses &&
      getDescription(
        original.value.accesses.map(a => a.trustLevel),
        context,
      );
    const byLine = canUploadDocuments ? `Visible to ${visibleFor}` : '';

    return typeof folderNavigation === 'string' ? (
      <Link to={folderNavigation} style={{ display: 'block', fontWeight: 400 }}>
        <Tooltip className="u-ellipsis" title={original.value.name}>
          <>{original.value.name}</>
        </Tooltip>
        {suffix}
        {byLine && (
          <div className="text-metadata u-ellipsis">
            <Tooltip title={byLine}>
              <span>{byLine}</span>
            </Tooltip>
          </div>
        )}
      </Link>
    ) : (
      <button onClick={() => folderNavigation(original.value.id)} style={{ display: 'block', fontWeight: 400 }}>
        <Tooltip className="u-ellipsis" title={original.value.name}>
          <>{original.value.name}</>
        </Tooltip>
        {suffix}
        {byLine && (
          <div className="text-metadata u-ellipsis">
            <Tooltip title={byLine}>
              <span>{byLine}</span>
            </Tooltip>
          </div>
        )}
      </button>
    );
  }

  const creator = userProfiles.find(u => u.cwUserId === original.value.createdByCwUserId);
  const creatorLine = creator ? ` by ${creator.name}` : '';
  const byLine = original.mobile ? `${dateMonthYear(original.value.createdAt)}${creatorLine}` : '';
  const fw = original.value.personalization?.hasRead ? 400 : 500;
  return (
    <>
      {isDownloading ? (
        <CircularProgress size={24} />
      ) : (
        <ClickableCell style={{ fontWeight: fw }} onClick={() => onClick && onClick()}>
          <Tooltip title={original.value.name}>
            <div className="u-ellipsis">{original.value.name}</div>
          </Tooltip>
          <div className="text-metadata">{byLine}</div>
        </ClickableCell>
      )}
      {WarningDialog}
    </>
  );
}

export function NameCellForBoardroomDocuments({
  original,
  company,
  userProfiles,
}: {
  original: BoardroomDocumentColumnContent;
  company: ICompany;
  userProfiles: UserProfile[];
}) {
  const routeContext = useContext(RouteContext);
  const urls = sharedUrls(routeContext.value);
  const notify = useNotify();

  const [downloadDocument, isDownloading] = useLazyResource(
    (documentId: string) =>
      DocumentAPI.boardroom.download(company.id, documentId).then(response => downloadBlob(response)),
    {
      onSuccess: () => {
        refetchUnreadBoardroomDocuments(company.id);
        original.type === 'document' &&
          original.folderId &&
          invalidate(boardroomDocumentsKey(company.id, original.folderId));
      },
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );

  if (original.type === 'folder') {
    const suffix = original.value.personalization.numUnreadDocuments > 0 && (
      <Indicator className="u-half-spacing-left" value={original.value.personalization.numUnreadDocuments} />
    );

    return (
      <Link
        to={urls.boardroom.documents.folder(company.slug, original.value.id)}
        style={{ display: 'block', fontWeight: 400 }}
      >
        <Tooltip className="u-ellipsis" title={original.value.name}>
          <>{original.value.name}</>
        </Tooltip>
        {suffix}
      </Link>
    );
  }
  const creator = userProfiles.find(u => u.cwUserId === original.value.createdByCwUserId);
  const creatorLine = creator ? ` by ${creator.name}` : '';
  const byLine = original.mobile ? `${dateMonthYear(original.value.createdAt)}${creatorLine}` : '';
  const fw = original.value.personalization?.hasRead ? 400 : 500;
  return isDownloading ? (
    <CircularProgress size={24} />
  ) : (
    <ClickableCell
      style={{ fontWeight: fw }}
      onClick={e => {
        e.preventDefault();
        downloadDocument(original.value.id);
      }}
    >
      <Tooltip title={original.value.name}>
        <div className="u-ellipsis">{original.value.name}</div>
      </Tooltip>
      <div className="text-metadata">{byLine}</div>
    </ClickableCell>
  );
}
