import { alpha } from '@mui/material';
import React, { ReactNode } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { contentSpacing, halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';
import FontRedHat from 'ui/theme/FontRedHat';
import { shape } from 'ui/theme/themeBluePlanet';

interface Props {
  title: string | React.ReactNode;
  subTitle?: React.ReactNode;
  children?: ReactNode;
  className?: string;
}

const StyledCard = styled.div`
  background-color: ${bluePlanetTheme.bluePlanetPalette.orange.main};
  color: ${bluePlanetTheme.bluePlanetPalette.white};
  font-weight: ${bluePlanetTheme.typography.fontWeightMedium};
  padding: ${halfSpacing} ${contentSpacing};
  font-family: ${FontRedHat.display};
  border-radius: ${shape.borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBody = styled.div`
  ${bluePlanetTheme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
  }
`;
const Divider = styled.span`
  margin: 0 ${quarterSpacing};
  ${bluePlanetTheme.breakpoints.down('sm')} {
    display: none;
  }
`;

export default function CardInfoCard(props: Props) {
  return (
    <StyledCard className={props.className}>
      <StyledBody>
        {props.title}
        {props.subTitle && (
          <span
            className="text-small"
            style={{
              color: alpha(bluePlanetTheme.bluePlanetPalette.white, 0.7),
            }}
          >
            <Divider>-</Divider>
            {props.subTitle}
          </span>
        )}
      </StyledBody>
      {props.children}
    </StyledCard>
  );
}
