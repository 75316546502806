import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

export type AnalyticsContext = // Add more here as needed

    | 'communities-explore'
    | 'communities-mentor-network'
    | 'community-feed'
    | 'community-overview'
    | 'community-updates'
    | 'community-member-overview'
    | 'community-member-explore'
    | 'communityManagment'
    | 'companies-widget'
    | 'dashboard-pending-follower-requests'
    | 'innovasjon-norge-overview'
    | 'via-matchmaking'
    | 'via-profile'
    | 'featured-matches'
    | 'dashboard-feed'
    | 'collection-page';

export const ANONYMOUS_USER_ID_KEY = 'anonymous-user-id';

export default function useProfileViewLogger(
  companyId: number | string,
  context?: AnalyticsContext,
  fromCommunityId?: number,
) {
  const { isAuthenticated } = useAuth0();
  const initialValue = uuidv4();
  const [anonymousId, setAnonymousId] = useLocalStorage<UUID>(ANONYMOUS_USER_ID_KEY, initialValue);

  useEffect(() => {
    if (!isAuthenticated) {
      setAnonymousId(anonymousId);
    }
  }, []);

  useEffect(() => {
    const startTime = new Date();
    const profileViewPromise = companiesApi.profile.logView(companyId, {
      fromCommunityId,
      context,
      anonymousId: isAuthenticated ? undefined : anonymousId,
    });

    const logDuration = async () => {
      const durationInSeconds = Math.round((new Date().getTime() - startTime.getTime()) / 1000);
      const profileView = await profileViewPromise;
      companiesApi.profile.logDuration(companyId, profileView.ref, {
        duration: durationInSeconds,
        anonymousId: isAuthenticated ? undefined : anonymousId,
      });
    };

    // Cleanup function will patch log entry with duration when closing pitch
    return () => {
      logDuration();
    };
  }, [companyId, context, fromCommunityId]);
}
