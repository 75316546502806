import { SingleConversationMeDTO, conversationApiUrls } from 'apis/ContentAPI/conversationApi';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';

const companyConveationAsMeKey = (companyId: number) => conversationApiUrls.company.me(companyId);

export function useCompanyConversationAsMe(companyId?: number) {
  const swrResult = useSWR<SingleConversationMeDTO>(companyId ? companyConveationAsMeKey(companyId) : undefined);
  return useSWRResource(swrResult);
}
