import { useState, useEffect, useRef } from 'react';
import { Timeout } from 'react-number-format/types/types';

export default function useCopyToClipboard(text: string, showSuccessDuration = 5000) {
  const [showCopiedSuccess, setShowCopiedSuccess] = useState(false);
  const timeoutIdRef = useRef<Timeout | null>(null);

  const copy = () => {
    if (typeof window !== 'undefined') {
      navigator.clipboard.writeText(text);
      setShowCopiedSuccess(true);

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        setShowCopiedSuccess(false);
      }, showSuccessDuration);
    }
  };

  const clear = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
      setShowCopiedSuccess(false);
    }
  };

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  return {
    copy,
    showCopiedSuccess,
    clear, // Expose the function to manually clear the timeout and set success to false
  };
}
