import { useAuth0 } from '@auth0/auth0-react';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import classnames from 'classnames';
import useDialogHandler from 'hooks/useDialogHandler';
import { NavItem } from 'domain/shared/Navigation/types';
import React, { useState } from 'react';
import Logo from 'ui/domain/Logo';
import Avatar from 'ui/elements/avatars/Avatar';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import { userUrls, communityUrls, companyUrls } from 'urls';
import styles from './styles.scss';
import PreferencesIcon from 'ui/elements/icons/PreferencesIcon';
import DashboardIcon from 'ui/elements/icons/DashboardIcon';
import { halfSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { getOrElse, getOrUndefined } from 'util/resource';
import { emptyPaginatedResult } from 'util/paginationUtils';
import useRoute from 'hooks/useRoute';
import { useHistory } from 'react-router';
import HelpIcon from 'ui/elements/icons/HelpIcon';
import { CompanyListItem } from 'types/company';
import MobileHeader from './MobileHeader';
import { useSentFollowerRequests } from 'apis/CompanyAPI/users/useSentFollowerRequests';
import fuzzysearch from 'fuzzysearch';
import SearchInput from 'ui/elements/form/SearchInput';
import Button from 'ui/elements/buttons/Button';
import uniqBy from 'ramda/src/uniqBy';
import ListIcon from 'ui/elements/icons/ListIcon';

type Props = {
  isOpen: boolean;
  inContextLinks: NavItem[];
  inContextLogo?: (onClick: (url: string) => void) => JSX.Element;
  globalLinks: NavItem[];
  icons: {
    logo: React.ReactNode;
    logOutIcon: React.ReactElement;
  };
  closeMenu: () => void;
  toggleMenu: () => void;
  userPreview: {
    name?: string;
    imageUrl?: string;
  };
  mobileHeader?: string;
};

const paperStyle = {
  '.MuiPaper-root': {
    width: '70%',
  },
};

const DrawerHeader = styled.h3`
  padding: ${halfSpacing};
  text-align: center;
`;

const DEFAULT_VISIBLE_COMPANIES = 12;
const DEFAULT_VISIBLE_COMMUNITIES = 8;

export default function MobileNavigation(props: Props) {
  const { push } = useRoute();

  const { isOpen, inContextLinks, inContextLogo, globalLinks } = props;
  const dialogHandler = useDialogHandler(false);
  const toggleContext = () => {
    if (dialogHandler.isOpen) {
      dialogHandler.close();
      setTimeout(props.toggleMenu, 100);
    } else {
      props.closeMenu();
      setTimeout(dialogHandler.open, 100);
    }
  };

  const navigate = (url: string) => {
    dialogHandler.close();
    props.closeMenu();
    push(url);
  };

  const { logout } = useAuth0();

  const companiesResource = useCompanies();
  const communitiesResource = useCommunities();
  const { resource: pendingFollowerRequests } = useSentFollowerRequests();
  const pendingCompanies = (getOrUndefined(pendingFollowerRequests)?.values ?? []).map(r => r.company);

  const companies = uniqBy(
    c => c.id,
    [...getOrElse(companiesResource.resource, emptyPaginatedResult()).values, ...pendingCompanies],
  );
  const communities = getOrUndefined(communitiesResource.resource)?.values ?? [];

  const history = useHistory();
  const currentPath = history.location.pathname;

  const overviewHref = (company: CompanyListItem) => companyUrls.overview(company.slug, 'updates');

  const hasCompanies = companies.length > 0;
  const hasCommunities = communities.length > 0;

  const [search, setSearch] = useState('');
  const [maxVisibleCompanies, setMaxVisibleCompanies] = useState(DEFAULT_VISIBLE_COMPANIES);
  const [maxVisibleCommunities, setMaxVisibleCommunities] = useState(DEFAULT_VISIBLE_COMMUNITIES);

  const filteredCommunities = !search
    ? communities
    : communities.filter(c => fuzzysearch(search.toLowerCase(), c.details.name.toLowerCase()));
  const filteredCompanies = !search
    ? companies
    : companies.filter(c => fuzzysearch(search.toLowerCase(), c.name.toLowerCase()));
  const showSearch = communities.length + companies.length > 30;

  return (
    <>
      <MobileHeader title={props.mobileHeader ?? ''} />
      <SwipeableDrawer
        sx={paperStyle}
        anchor="left"
        open={isOpen}
        disableSwipeToOpen
        onOpen={() => {}}
        onClose={props.closeMenu}
      >
        <nav>
          <List>
            <div className="u-flex u-flex-space-between">
              <ListItem onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                <DashboardIcon style={{ fontSize: '29px' }} />
                <ListItemText style={{ paddingLeft: 9 }}>Home</ListItemText>
              </ListItem>
              <ListItem style={{ flex: 1 }}>
                <IconButton onClick={toggleContext}>
                  <ListIcon />
                </IconButton>
              </ListItem>
            </div>
            {inContextLogo && inContextLogo(navigate)}
            <div className="u-half-spacing-y">
              {inContextLinks.map(({ href, label, icon }, index) => {
                const queryBegin = href.indexOf('?') >= 0 ? href.indexOf('?') : href.length;
                const hrefPathname = href.substr(0, queryBegin);
                return (
                  <ListItem
                    button
                    key={`context-mobile-link-${index}`}
                    className={classnames(hrefPathname === currentPath && styles.selected)}
                    onClick={() => navigate(href)}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                  </ListItem>
                );
              })}
            </div>
            <Divider />
            <div className="u-half-spacing-y">
              {globalLinks.map(({ href, label, icon }, index) => {
                const queryBegin = href.indexOf('?') >= 0 ? href.indexOf('?') : href.length;
                const hrefPathname = href.substr(0, queryBegin);
                return (
                  <ListItem
                    button
                    key={`global-mobile-link-${index}`}
                    className={classnames(hrefPathname === currentPath && styles.selected)}
                    onClick={() => navigate(href)}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                  </ListItem>
                );
              })}
            </div>
            {globalLinks.length > 0 && <Divider className="u-half-spacing-bottom" />}
            <ListItem
              button
              onClick={() => navigate('/help')}
              className={classnames('u-flex u-flex-align-center', currentPath === '/help' && styles.selected)}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText>Help</ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={() => navigate(userUrls.profile.view)}
              className={classnames('u-flex u-flex-align-center', currentPath === '/profile' && styles.selected)}
              style={{ marginLeft: -9 }}
            >
              <ListItemIcon>
                <Avatar imageUrl={props.userPreview.imageUrl} />
              </ListItemIcon>
              <ListItemText style={{ paddingLeft: 9 }}>{props.userPreview.name}</ListItemText>
            </ListItem>
            <ListItem button onClick={() => navigate(userUrls.profile.communications)}>
              <ListItemIcon>
                <PreferencesIcon />
              </ListItemIcon>
              <ListItemText>Update your preferences</ListItemText>
            </ListItem>
            <ListItem button onClick={() => logout()}>
              <ListItemIcon>{props.icons.logOutIcon}</ListItemIcon>
              <ListItemText>Log out</ListItemText>
            </ListItem>
          </List>
        </nav>
      </SwipeableDrawer>
      <SwipeableDrawer
        sx={paperStyle}
        disableSwipeToOpen
        onOpen={() => {}}
        open={dialogHandler.isOpen}
        onClose={toggleContext}
        anchor="left"
      >
        <div>
          <div style={{ position: 'relative' }}>
            <IconButton style={{ position: 'absolute', right: 16, top: 10 }} onClick={toggleContext}>
              <CloseIcon />
            </IconButton>
          </div>
          {showSearch && (
            <div className="u-section-spacing-top u-content-spacing-x u-content-padding-top">
              <SearchInput onChange={value => setSearch(value)} value={search} />
            </div>
          )}
          {hasCompanies && <DrawerHeader className="text-h3 u-half-spacing-top">Companies</DrawerHeader>}
          {!filteredCompanies.length && hasCompanies && (
            <p className="u-content-spacing-y u-flex-center">No companies matched your search</p>
          )}
          <List>
            {filteredCompanies.slice(0, maxVisibleCompanies).map(c => (
              <ListItem button href={overviewHref(c)} key={`company-${c.id}`} onClick={() => navigate(overviewHref(c))}>
                <ListItemIcon>
                  <Logo size={30} url={c.logoURL} type="company" />
                </ListItemIcon>
                <ListItemText primary={c.name} />
              </ListItem>
            ))}
          </List>
          {filteredCompanies.length > maxVisibleCompanies && (
            <Button
              className="u-half-spacing-left"
              kind="tertiary"
              onClick={() => setMaxVisibleCompanies(maxVisibleCompanies + DEFAULT_VISIBLE_COMPANIES)}
            >
              See more
            </Button>
          )}
          {hasCommunities && <DrawerHeader className="text-h3">Communities</DrawerHeader>}
          {!filteredCommunities.length && hasCommunities && (
            <p className="u-content-spacing-y u-flex-center">No communities matched your search</p>
          )}
          <List>
            {filteredCommunities.slice(0, maxVisibleCommunities).map(c => (
              <ListItem
                button
                href={communityUrls.overview(c.details.slug)}
                key={`company-${c.details.id}`}
                onClick={() => navigate(communityUrls.overview(c.details.slug))}
              >
                <ListItemIcon>
                  <Logo size={30} url={c.details.logoURL} type="company" />
                </ListItemIcon>
                <ListItemText primary={c.details.name} />
              </ListItem>
            ))}
          </List>
          {filteredCommunities.length > maxVisibleCommunities && (
            <Button
              className="u-half-spacing-left"
              kind="tertiary"
              onClick={() => setMaxVisibleCommunities(maxVisibleCommunities + DEFAULT_VISIBLE_COMMUNITIES)}
            >
              See more
            </Button>
          )}
        </div>
      </SwipeableDrawer>
    </>
  );
}
