import { texts } from 'domain/companies/ExecutiveSummary/texts';
import { FormikProps, withFormik } from 'formik';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React, { useRef } from 'react';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';
import styles from './styles.scss';
import AiMenu from 'ui/domain/AiMenu/AiMenu';

interface Props {
  dialogHandler: DialogHandlerProps;
  onSave: (teamDescription?: string) => void;
  isSaving: boolean;
  teamDescription?: string;
  companyId: number;
}

interface TeamDescriptionForm {
  teamDescription?: string;
}

function EditTeamDescription(props: Props & FormikProps<TeamDescriptionForm>) {
  const aiRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>Team description</Title>
      <Content>
        <FormRow>
          <FormikTextField
            formikProps={props}
            name="teamDescription"
            type="text"
            label={
              <>
                {texts['management'].title}
                <span className={styles.multilineLabel}>{texts['management'].label}</span>
              </>
            }
            placeholder={texts['management'].placeholder}
            multiline
            minRows={4}
            maxRows={16}
            suffixPosition="start-bottom"
            suffix={
              <AiMenu
                companyId={props.companyId}
                input={props.values.teamDescription || ''}
                onReplace={value => {
                  props.setFieldValue('teamDescription', value);
                }}
                aiRef={aiRef}
              />
            }
          />
          <div ref={aiRef} />
        </FormRow>
      </Content>
      <DialogActions>
        <Button onClick={() => props.handleSubmit()} kind="primary" isLoading={props.isSaving}>
          Update
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withFormik<Props, TeamDescriptionForm>({
  mapPropsToValues: props => ({
    teamDescription: props.teamDescription,
  }),
  validationSchema: Yup.object().shape({
    teamDescription: Yup.string().notRequired(),
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.onSave(values.teamDescription);
  },
})(EditTeamDescription);
