import FormGroup from '@mui/material/FormGroup';
import React from 'react';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import ButtonDropdown, { ButtonDropdownItem } from 'ui/modules/ButtonDropdown';
import { capitalize } from 'util/stringUtils';
import BoardRoomIcon from 'ui/elements/icons/BoardIcon';
import GroupWorkIcon from 'ui/elements/icons/CompaniesIcon';
import UserIcon from 'ui/elements/icons/UserIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import { TrustLevel } from 'types/company';

type Props = {
  trustLevels: TrustLevel[];
  disabledTrustLevels?: TrustLevel[];
  selectedTrustLevels: TrustLevel[];
  onChange: (audience: TrustLevel, isChecked: boolean) => void;
  onBlur?: () => void;
  companyId: number;
  isCommunity: boolean;
  settingsItem?: {
    onClick: () => void;
  };
  errors?: string | string[];
};

const iconFor = (trustLevel: TrustLevel) => {
  switch (trustLevel) {
    case 'board':
      return <BoardRoomIcon />;
    case 'semi_trusted':
      return <UsersIcon />;
    case 'trusted':
      return <UserIcon />;
    case 'company_admin_in_community':
      return <GroupWorkIcon />;
    default:
      return <UserIcon />;
  }
};

const nameOfAudience = (trustLevel: TrustLevel) => {
  if (trustLevel === 'semi_trusted') return 'followers';
  else if (trustLevel === 'trusted') return 'Investors and board members';
  else if (trustLevel === 'board') return 'Board members';
  else return '';
};

const trustlevelDisplayName = (selectedTrustLevels: TrustLevel[], allAudiences: TrustLevel[]) => {
  const selectedAudiences = allAudiences.filter(trustLevel => selectedTrustLevels.includes(trustLevel));

  if (selectedAudiences.length < 1) return 'Select audience';

  return selectedAudiences.map(audience => nameOfAudience(audience)).join(', ');
};

export default function TrustLevelPicker(props: Props) {
  let items: ButtonDropdownItem[] = props.trustLevels.map(trustLevel => ({
    icon: iconFor(trustLevel),
    text: capitalize(nameOfAudience(trustLevel)),
    onClick: () => props.onChange(trustLevel, !props.selectedTrustLevels.includes(trustLevel)),
    checked: props.selectedTrustLevels.includes(trustLevel),
    disabled: props.disabledTrustLevels?.includes(trustLevel),
    rightIcon: 'radio' as const,
  }));

  if (props.settingsItem) {
    items = [
      ...items,
      {
        icon: <SettingsIcon />,
        text: 'Advanced settings',
        checked: false,
        onClick: props.settingsItem.onClick,
        disabled: false,
        rightIcon: (
          <span className="u-quarter-spacing-right">
            <ChevronRightIcon />
          </span>
        ),
      },
    ];
  }

  return (
    <FormGroup>
      <ButtonDropdown
        items={items}
        onBlur={props.onBlur}
        hasError={!(props.errors == undefined || props.errors.length == 0)}
      >
        <EyeIcon className="u-half-spacing-right" />
        {capitalize(trustlevelDisplayName(props.selectedTrustLevels, props.trustLevels))}
      </ButtonDropdown>
    </FormGroup>
  );
}
