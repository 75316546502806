import DOMPurify from 'dompurify';
import React from 'react';

import RichText from 'ui/elements/text/RichText';

interface Props {
  html: string;
  style?: object;
  className?: string;
}

const basicRichTextEditorTags = {
  ALLOWED_TAGS: ['a', 'img', 'ul', 'li', 'ol', 'em', 'strong', 'p'],
  ALLOWED_ATTR: ['src', 'alt', 'rel', 'width', 'height', 'href', 'class', 'title', 'target'],
};

export default function HTMLContent(props: Props) {
  const cleaned = DOMPurify.sanitize(props.html, basicRichTextEditorTags);
  return (
    <RichText className={props.className} style={props.style}>
      {() => cleaned}
    </RichText>
  );
}
