import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import { alpha, useTheme } from '@mui/material';

import React from 'react';
import { fontSizeMedium } from 'ui/theme/themeBluePlanet';
import classNames from 'classnames';
import { halfSpacing, quarterSpacing } from 'ui/theme/themeConstants';

interface Props {
  className?: string;
  label: FormControlLabelProps['label'];
  offStateLabel?: FormControlLabelProps['label'];
  value?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}
const StyledSwitch = ({
  value,
  disabled,
  onChange,
}: {
  value?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}) => {
  return (
    <MUISwitch
      role="switch"
      sx={{
        marginRight: quarterSpacing,
      }}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      color="primary"
    />
  );
};

export default function Switch({ className, label, offStateLabel, value, disabled, onChange }: Props) {
  const theme = useTheme();
  const colorTransition = 'color 200ms ease-in-out';

  // With only one label, it serves as the state that is turned off and on
  if (!offStateLabel) {
    return (
      <FormControlLabel
        disabled={disabled}
        className={className}
        control={<StyledSwitch value={value} disabled={disabled} onChange={onChange} />}
        label={label}
      />
    );
  }

  // With two lables, we show the switch between the states more explicitly with a label on each side
  return (
    <div className={classNames(className, 'u-flex-align-center')}>
      <button
        style={{
          cursor: 'pointer',
          fontFamily: theme.typography.fontFamily,
          fontSize: fontSizeMedium,
          fontWeight: theme.typography.fontWeightMedium,
          color: !value ? theme.bluePlanetPalette.grey.main : alpha(theme.bluePlanetPalette.grey.main, 0.5),
          transition: colorTransition,
          marginRight: halfSpacing,
        }}
        onClick={onChange}
        disabled={disabled}
      >
        {offStateLabel}
      </button>
      <FormControlLabel
        sx={{
          '.MuiTypography-root': {
            color: value ? theme.bluePlanetPalette.blue.main : alpha(theme.bluePlanetPalette.grey.main, 0.5),
            transition: colorTransition,
          },
        }}
        control={<StyledSwitch value={value} disabled={disabled} onChange={onChange} />}
        label={label}
        disabled={disabled}
      />
    </div>
  );
}
