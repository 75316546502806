import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

export default function useClickableMentions(
  containerRef: React.RefObject<HTMLElement>,
  quillRef?: React.RefObject<ReactQuill>,
  deps: any[] = [],
) {
  const [userProfileIdToShow, setUserProfileIdToShow] = useState<string>();
  const [companyProfileIdToShow, setCompanyProfileIdToShow] = useState<number>();

  useEffect(() => {
    const handleClick = (event: Event) => {
      const target = event.currentTarget as HTMLElement;
      const mentionType = target.getAttribute('data-type');
      if (mentionType === 'user') {
        setUserProfileIdToShow(target.getAttribute('data-id') || '');
      } else if (mentionType === 'company') {
        setCompanyProfileIdToShow(parseInt(target.getAttribute('data-id') || ''));
      }
    };
    const attachEventListeners = () => {
      if (containerRef.current === null) return;
      const activeElements = containerRef.current.getElementsByClassName('mention');

      Array.from(activeElements).forEach(element => {
        element.removeEventListener('click', handleClick);
      });

      Array.from(activeElements).forEach(element => {
        element.addEventListener('click', handleClick);
      });
    };

    attachEventListeners();

    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.on('editor-change', (eventName: any) => {
        if (eventName === 'text-change') {
          attachEventListeners();
        }
      });
    }

    return () => {
      const activeElements = document.getElementsByClassName('mention');
      Array.from(activeElements).forEach(element => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, deps);

  return { userProfileIdToShow, setUserProfileIdToShow, companyProfileIdToShow, setCompanyProfileIdToShow };
}
