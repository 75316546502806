import React from 'react';
import { DOCUMENT_UPLOAD_LIMIT } from 'util/constants';
import DocumentDropzone from 'domain/shared/Files/Upload/Dropzone';
import styles from './styles.scss';
import UploadedDocument from './UploadedDocument';
import useFileUpload from 'domain/shared/Files/Upload/useFileUpload';
import { notEmpty } from 'util/arrayUtils';
import useNotify from 'hooks/useNotify';
import { FileRejection } from 'react-dropzone';
import cx from 'classnames';

interface Props {
  uploadDocument: (document: File) => Promise<UUID>;
  onSuccess?: (documentId: string, name: string) => void;
  children: (props: OutProps) => JSX.Element;
  fileLimit?: 'multiple' | 'single';
  align?: 'left' | 'center';
}

interface OutProps {
  numberOfSuccessfullyUploadedDocuments: number;
  documentsBeingUploaded: string[];
  dropzone: JSX.Element;
  uploadedDocuments: JSX.Element[];
  reset: () => void;
}
export default function DocumentUploader(props: Props) {
  const notify = useNotify();
  const [documents, onDropFile, , reset] = useFileUpload(props.uploadDocument, {
    onSuccess: files => {
      files.forEach(f => {
        notify('success', `"${f.name}" uploaded`);
        props.onSuccess && props.onSuccess(f.documentId, f.name);
      });
    },
  });

  function onDrop(accepted: File[], rejected: FileRejection[]) {
    if (props.fileLimit === 'single') {
      onDropFile([accepted[0]].filter(notEmpty), rejected);
    } else {
      onDropFile(accepted, rejected);
    }
  }

  const { children } = props;
  return children({
    numberOfSuccessfullyUploadedDocuments: documents.filter(d => d.status === 'COMPLETED').length,
    documentsBeingUploaded: documents.filter(d => d.status === 'UPLOADING').map(d => d.file.name),
    reset: reset,
    dropzone: (
      <div className="u-flex">
        <div className={styles.dropWrapper}>
          <DocumentDropzone onDrop={onDrop}>
            <div
              className={cx(styles.dropzone, {
                [styles.leftAlign]: props.align === 'left',
              })}
            >
              <div
                className={cx(styles.dropText, {
                  [styles.leftAlign]: props.align === 'left',
                })}
              >
                Drag {props.fileLimit === 'single' ? 'a file' : 'files'} here or{' '}
                <span className="text-link">browse</span>
                <div className="text-metadata" style={{ textTransform: 'none' }}>
                  File size limit is {DOCUMENT_UPLOAD_LIMIT.human}
                </div>
              </div>
            </div>
          </DocumentDropzone>
        </div>
      </div>
    ),
    uploadedDocuments: documents.map(doc => (
      <UploadedDocument
        key={doc.tempId}
        document={doc.file}
        uploadErrorMessage={doc.errorMessage}
        status={doc.status}
      ></UploadedDocument>
    )),
  });
}
