import 'normalize-css/normalize.css';
import 'styles/global/main.scss';
import * as Sentry from '@sentry/browser';
import React, { Suspense, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Auth0User } from 'types';
import { currentRelativePath, extractUserId, uriToCurrentPath } from 'auth/util';
import loader from 'ui/illustrations/CW_line_loader.svg';
import { SWRConfig } from 'swr';
import { getFetcher } from 'apis/utils';
import Navigation, { NavigationContextProvider } from 'domain/shared/Navigation';
import LoadingBar from 'ui/elements/Loading/LoadingBar';
import { setRegistrationDetails } from 'pages/Registration/common/CompleteRegistration';
import { useConfigureAccessToken } from './useConfigureAccessToken';

export const GET_NEW_TOKEN_BEFORE_EXPIRATION_MINUTES = 1;

export function LoadingScreen() {
  return (
    <div className="u-flex-center u-flex-align-center" style={{ height: '80vh' }}>
      <img style={{ height: '10vh', maxHeight: 80, minHeight: 50 }} src={loader} />
    </div>
  );
}

function AuthenticatedRoutes({ children }: { children: React.ReactNode }) {
  const { user } = useAuth0();
  const userId = extractUserId(user as Auth0User);

  const { isAccessTokenReady } = useConfigureAccessToken();

  useEffect(() => {
    Sentry.getCurrentScope().setUser({
      email: user?.email,
      id: userId?.toString(),
    });
  }, [isAccessTokenReady]);

  if (!isAccessTokenReady) {
    return <LoadingScreen />;
  }

  return (
    <div className="u-flex-space-between" style={{ height: '100%' }}>
      <SWRConfig
        value={{
          fetcher: getFetcher,
        }}
      >
        <NavigationContextProvider>
          <Navigation />
          {/* If you remove this <Suspense> your are officially an idiot: https://github.com/mui/material-ui/issues/33748 */}
          <Suspense fallback={<LoadingBar isComplete={false} />}>{children}</Suspense>
        </NavigationContextProvider>
      </SWRConfig>
    </div>
  );
}

export default withAuthenticationRequired(AuthenticatedRoutes, {
  returnTo: currentRelativePath,
  onRedirecting: () => {
    setRegistrationDetails({ type: 'user', originUrl: window.location.toString() });
    return <LoadingScreen />;
  },
  loginOptions: {
    redirect_uri: uriToCurrentPath(),
  },
});
