import ApiBase from 'apis';
import { List } from 'types/api';
import { UserProfile } from 'types/user';
import { Mention } from '.';
import { roomAPIUrls } from 'apis/CompanyAPI/rooms/roomsApi';
import { CompanyId, staticFileLocations } from 'urls';
import { DebouncedFunc } from 'lodash';
import 'styles/global/overrides/quill-editor-overrides.scss';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { UpdateVisibility } from 'types/content';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { CompanyCardDTO } from 'types/company/community';
import { DeltaOperation } from 'quill';

export const isUrl = (dirty: string) => /^https?:\/\//i.test(dirty);

export const isIframe = (dirty: string) => /^\<iframe.*\<\/iframe\>$/i.test(dirty);

export const stripEmbed = (dirty: string) => dirty.trim().replace(/(?:\r\n|\r|\n)/g, '');

export const parseEmbed = (dirtyEmbed: string) => {
  const embed = stripEmbed(dirtyEmbed);
  if (isUrl(embed) || isIframe(embed)) return embed;
  return false;
};

export const removeMentions = (structuredContent: any) => {
  return structuredContent.map((content: DeltaOperation) => {
    if (content.insert && content.insert['styled-mention']) {
      return { insert: content.insert['styled-mention'].value };
    } else {
      return content;
    }
  });
};

export const companyUserMentionSearchFunction = async (
  searchQuery: string,
  visibility: UpdateVisibility,
  companyId: CompanyId,
) => {
  const promises = [
    ApiBase().get<List<UserProfile>>(
      companiesApi.users.list(companyId, { limit: 8 }, { name: searchQuery, audience: visibility }),
    ),
  ];

  const [users] = await Promise.all(promises);

  const userMentions: Mention[] = users.values.map(user => ({
    id: (user as UserProfile).cwUserId,
    value: user.name,
    imageURL: (user as UserProfile).imageUrl,
    type: 'user',
  }));
  const mentions = userMentions.sort((a, b) => a.value.localeCompare(b.value)).slice(0, 8);
  return mentions;
};

export const communityMentionSearchFunction = async (
  searchQuery: string,
  visibility: UpdateVisibility,
  communityId: number,
) => {
  const companyPromise =
    visibility === 'CommunityMembersExceptFounders'
      ? Promise.resolve({ values: [] })
      : ApiBase().get<List<CompanyCardDTO>>(
          communityAPIUrls.companies.list(communityId, 'alphabetically', { limit: 8 }, { name: searchQuery }),
        );
  const userPromise = ApiBase().get<List<UserProfile>>(
    communityAPIUrls.users.list(communityId, { limit: 8 }, { name: searchQuery, audience: visibility }),
  );

  const [companies, users] = await Promise.all([companyPromise, userPromise]);
  const companyMentions: Mention[] = companies.values.map((company: CompanyCardDTO) => ({
    id: company.details.id,
    value: company.details.name,
    imageURL: company.details.logoURL,
    type: 'company',
  }));
  const userMentions: Mention[] = users.values.map(user => ({
    id: user.cwUserId,
    value: user.name,
    imageURL: user.imageUrl,
    type: 'user',
  }));
  const mentions = [...companyMentions, ...userMentions].sort((a, b) => a.value.localeCompare(b.value)).slice(0, 8);
  return mentions;
};

export const roomMentionSearchFunction = async (searchQuery: string, roomId: string): Promise<Mention[]> => {
  const results = await ApiBase().get<List<UserProfile>>(roomAPIUrls.searchUsers(searchQuery, roomId));
  return results.values.map(user => ({
    id: user.cwUserId,
    value: user.name,
    imageURL: user.imageUrl,
    type: 'user',
  }));
};

export const mentionModule = (
  debouncedMentionFunc: DebouncedFunc<
    (
      searchTerm: string,
      renderList: (
        data: (Mention & {
          disabled?: boolean;
        })[],
        searchTerm: string,
      ) => void,
    ) => Promise<void>
  >,
) => ({
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  source: debouncedMentionFunc,
  dataAttributes: ['id', 'value', 'type', 'active'],
  blotName: 'styled-mention',
  renderItem: (item: Mention, searchTerm: string) => {
    const container = document.createElement('div');
    container.classList.add('ql-styled-mention-container');
    container.innerHTML =
      item.id === 'header'
        ? `<div class="ql-mention-divider">in your network</div>`
        : `
        <div class="u-flex u-flex-align-center">
          <div class="u-half-spacing-right ql-styled-mention-avatar"><img alt="${item.value}" src="${
            item.imageURL ||
            (item.type === 'user'
              ? staticFileLocations.defaultProfileImage
              : staticFileLocations.defaultCompanyProfileImage)
          }" /></div>
          <div class="u-flex u-flex--column">
            <span class="u-ellipsis text-large text-weight-medium">${item.value}</span>
            <span class="u-ellipsis text-metadata">${item.type === 'user' ? 'Person' : 'Company'}</span>
          </div>
        </div>
    `;
    return container;
  },
});
