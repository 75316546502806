import { Author, asUserAuthor } from 'domain/shared/author';
import React, { useState } from 'react';
import { UserProfile } from 'types/user';

import { getOrUndefined } from 'util/resource';

import EditUpdateDialog from '../Post/EditUpdateDialog';
import { RoomUpdateDTO } from '../types';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { deleteUpdate } from '../actions';
import UpdateHeadingActions from './UpdateHeadingActions';
import Update from 'domain/Updates/shared/Show';
import useNotify from 'hooks/useNotify';
import { Auth0User } from 'types';
import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import useDialogHandler from 'hooks/useDialogHandler';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import Button from 'ui/elements/buttons/Button';
import roomUpdateAPI from '../RoomUpdateAPI';

interface Props {
  update: RoomUpdateDTO;
  creator?: UserProfile;
  canEditPost: boolean;
  onChange: () => void;
  author?: Author;
  canPinToFeed?: boolean;
}

export default function ShowUpdate(props: Props) {
  const { update, creator } = props;
  const [editUpdate, setEditUpdate] = useState(false);
  const deleteDialogHandler = useDialogHandler();
  const [isDeleting, setDeleting] = useState(false);

  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const notify = useNotify();

  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  const isAuthor = creator?.cwUserId === userProfile?.cwUserId;

  const author = asUserAuthor(creator);

  const onDelete = () => {
    setDeleting(true);
    deleteUpdate(notify, props.update.roomId, update.update.id, () => {
      setDeleting(false);
      deleteDialogHandler.close();
      props.onChange();
    });
  };

  return (
    <>
      <Update
        className="u-content-spacing-bottom"
        update={update.update}
        author={props.author ?? author}
        creator={creator}
        updateContext={{ type: 'room', roomId: props.update.roomId }}
        api={roomUpdateAPI(props.update.roomId)}
        canEditUpdate={isAuthor}
        actions={
          isAuthor || canAccessBackoffice ? (
            <UpdateHeadingActions
              onEdit={isAuthor ? () => setEditUpdate(true) : undefined}
              onDelete={canAccessBackoffice ? deleteDialogHandler.open : undefined}
            />
          ) : null
        }
      />
      {editUpdate && (
        <EditUpdateDialog
          roomId={props.update.roomId}
          setActive={setEditUpdate}
          update={update}
          user={userProfile}
          onComplete={props.onChange}
        />
      )}
      <ConfirmDialog
        isOpen={deleteDialogHandler.isOpen}
        onClose={deleteDialogHandler.close}
        title="You are about to delete this post"
        confirmButton={
          <Button color="red" kind="primary" onClick={onDelete} isLoading={isDeleting}>
            Yes, delete
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
        <p>Are you sure?</p>
      </ConfirmDialog>
    </>
  );
}
