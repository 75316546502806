import React from 'react';
import { Link } from 'react-router-dom';
import Registration from 'ui/views/layouts/Registration';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import CompanyAPI from 'apis/CompanyAPI';
import InfoMessage from 'ui/views/messages/InfoMessage';
import Resource from 'util/resource/Resource';
import WarningIcon from 'ui/elements/icons/WarningIcon';
import OnboardingWalkthrough from 'pages/Registration/Company/OnboardingWalkthrough';
import { userUrls } from 'urls';
import Loading from 'ui/elements/Loading';
import { RegistrationDetails } from './CompleteRegistration';
import { IResource } from 'util/resource';
import { CommunityRegistrationToken } from 'types/company/registration';
import LinkButton from 'ui/elements/buttons/LinkButton';
import { useAuth0 } from '@auth0/auth0-react';
import { JoinCommunityAsUserMessage } from '../User/UserRegistration/JoinCommunityAsUserMessage';

const Error = () => {
  const { logout } = useAuth0();

  return (
    <div className="u-section-spacing">
      <h1 className="text-h3 u-content-spacing-bottom">We can&apos;t find the invitation</h1>
      <p className="text-body u-content-spacing-bottom">
        Most likely, this means that you have already completed this step by creating a user account and and a company.
      </p>
      <p className="text-body u-content-spacing-bottom">
        The next step is to confirm your email address by clicking a link in an email we&apos;ve sent you, with the
        subject {"'"}Complete registration{"'"}. This is to ensure that we have gotten your email address right, so that
        we don&apos;t run in the problems in the future.
      </p>
      <p className="text-body u-section-spacing-bottom">
        When you have confirmed your email address through the email we sent you, you may{' '}
        <Link className="text-link" to={userUrls.dashboard.home()}>
          login
        </Link>{' '}
        using the password you created.
      </p>
      <p className="text-body u-content-spacing-bottom">
        If you are sure you haven&apos;t already completed the steps above, but still end up here - ask for a new
        invitation.
      </p>
      <div>
        <LinkButton className="u-section-spacing-top" onClick={() => logout({})}>
          Log out
        </LinkButton>
      </div>
    </div>
  );
};

function JoinCommunity({
  communityProfileResource,
  children,
}: {
  communityProfileResource: IResource<CommunityRegistrationToken | undefined>;
  children: React.ReactNode;
}) {
  return (
    <Resource
      resource={communityProfileResource}
      renderLoading={() => (
        <div className="u-align-center u-section-spacing">
          <Loading />
        </div>
      )}
      renderError={() => <Error />}
    >
      {communityProfile => {
        return communityProfile ? (
          <Registration
            heading={
              <p className="text-x-large text-weight-medium">
                Showcase your company and connect with new and existing investors by joining the{' '}
                <b>{communityProfile.name}</b> community on CrowdWorks.
              </p>
            }
            title={`Welcome to ${communityProfile.name} on Crowdworks`}
          >
            {communityProfile.communityType && <OnboardingWalkthrough communityType={communityProfile.communityType} />}
            {children}
          </Registration>
        ) : (
          <Error />
        );
      }}
    </Resource>
  );
}

function JoinWithSharedInvite(props: { token: string; children: React.ReactNode }) {
  const [invitationToken] = useResourceLegacy(() => CompanyAPI.identity.getInvitationToken(props.token), [props.token]);

  return (
    <Resource
      resource={invitationToken}
      renderError={() => (
        <>
          <InfoMessage color="yellow" icon={<WarningIcon />}>
            The invitation has expired. You can still complete the registration, but your account won&apos;t be
            associated with the company that invited you. You probably want to ask for a new invitation link.
          </InfoMessage>
          <p className="text-body u-content-spacing-y">
            You are only a few steps away from beginning your journey at CrowdWorks. To get started you need to create a
            personal account.
          </p>
        </>
      )}
    >
      {token => (
        <Registration title={`Welcome to ${token.companyName} on Crowdworks`}>
          <JoinCommunityAsUserMessage token={token} />
          {props.children}
        </Registration>
      )}
    </Resource>
  );
}

export default function CompleteRegistrationLayout(props: {
  registerAs: RegistrationDetails;
  communityRegistrationToken: IResource<CommunityRegistrationToken | undefined>;
  children: React.ReactNode;
}) {
  if (props.registerAs.type === 'user' && props.registerAs.sharedInviteToken) {
    return <JoinWithSharedInvite token={props.registerAs.sharedInviteToken}>{props.children}</JoinWithSharedInvite>;
  } else if (props.registerAs.type === 'company' && props.registerAs.communityCode) {
    return <JoinCommunity communityProfileResource={props.communityRegistrationToken}>{props.children}</JoinCommunity>;
  } else {
    return (
      <Registration title="Complete registration" heading="Welcome to CrowdWorks">
        {props.children}
      </Registration>
    );
  }
}
