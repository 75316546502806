import { Menu, MenuItem } from '@mui/material';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/elements/buttons/Button';
import ChatAddIcon from 'ui/elements/icons/ChatAddIcon';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import UpdateIcon from 'ui/elements/icons/UpdateIcon';
import { companyUrls } from 'urls';

interface Props {
  shareCompanyDialogHandler: DialogHandlerProps;
  companySlug: string;
}

export default function FundingRoundShareMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShareInChat = () => {
    props.shareCompanyDialogHandler.open();
    handleClose();
  };

  const { copy, showCopiedSuccess } = useCopyToClipboard(
    `${location.origin}${companyUrls.overview(
      props.companySlug,
      'profile',
    )}?utm_source=direct&utm_medium=copy-link&utm_campaign=company&utm_term=funding-round`,
  );

  return (
    <div>
      <Button
        kind="tertiary"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleOpen}
      >
        Share company profile
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={isOpen}>
        <Link to={companyUrls.overview(props.companySlug, 'updates', { isPostingUpdate: true })} onClick={handleClose}>
          <MenuItem style={{ height: '45px' }} className="u-fullWidth u-fullHeight u-flex u-flex-align-center">
            <UpdateIcon fontSize="small" className="u-half-spacing-right" />
            Create update
          </MenuItem>
        </Link>
        <MenuItem onClick={copy} style={{ height: '45px' }}>
          {showCopiedSuccess ? (
            <CheckMarkCircleIcon strokeWidth={2} className="u-half-spacing-right" fontSize="small" color="green" />
          ) : (
            <LinkIcon fontSize="small" className="u-half-spacing-right" />
          )}
          <span>Copy link to company profile</span>
        </MenuItem>
        <MenuItem style={{ height: '45px' }} onClick={handleShareInChat}>
          <ChatAddIcon fontSize="small" className="u-half-spacing-right" /> <span>Share in chat</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
