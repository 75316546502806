import React, { useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CompanyListItem, ICompany } from 'types/company';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import ChevronUpIcon from 'ui/elements/icons/ChevronUpIcon';
import Logo from 'ui/domain/Logo';
import { FixedSizeList } from 'react-window';
import { TinyCommunity } from 'types/company/community';
import { bluePlanetTheme } from 'ui/theme';
import styled from '@emotion/styled';
import { transtionEase } from 'ui/theme/themeConstants';
import { alpha } from '@mui/material';

interface Props {
  onSelect: (value?: CompanyListItem | TinyCommunity) => void;
  values: (CompanyListItem | TinyCommunity)[];
  value: CompanyListItem | TinyCommunity;
}

const Container = styled.div`
  width: fit-content;
  border: 1px solid transparent;
  border-radius: ${bluePlanetTheme.shape.borderRadius}px;
  &:hover {
    border-color: ${alpha(bluePlanetTheme.bluePlanetPalette.grey.main, 0.25)};
  }
  transition: all 0.2s ${transtionEase};
`;

export default function CompanySelector(props: Props) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const companies = props.values;
  return (
    <>
      <Container>
        <button ref={ref} className="u-flex-align-center u-flex--row u-half-padding" onClick={() => setOpen(!open)}>
          <CompanyElement {...props.value} />
          {open ? (
            <ChevronUpIcon className=" u-half-spacing-x" fontSize="small" color="grey" />
          ) : (
            <ChevronDownIcon className="u-half-spacing-x" fontSize="small" color="grey" />
          )}
        </button>
      </Container>

      <Menu open={open} anchorEl={ref.current} onClose={() => setOpen(false)}>
        {companies.length > 10 ? (
          <FixedSizeList width={350} height={600} itemCount={companies.length} itemSize={58}>
            {({ index, style }) => {
              return (
                <MenuItem
                  style={style}
                  key={index}
                  onClick={() => {
                    props.onSelect(companies[index]);
                    setOpen(false);
                  }}
                >
                  {<CompanyElement {...companies[index]} />}
                </MenuItem>
              );
            }}
          </FixedSizeList>
        ) : (
          companies.map(company => (
            <MenuItem
              key={company.id}
              onClick={() => {
                props.onSelect(company);
                setOpen(false);
              }}
            >
              {<CompanyElement {...company} />}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
}

function CompanyElement(company: ICompany | TinyCommunity) {
  return (
    <div style={{ minWidth: '14rem' }} className="u-flex-align-center u-flex--row ">
      <Logo className="u-half-spacing-right" size={38} type="company" url={company.logoURL} />
      <span className="text-metadata-regular-black">{company.name}</span>
    </div>
  );
}
