import useResourceLegacy from 'util/resource/useResourceLegacy';
import React from 'react';
import Card from 'ui/views/cards/Card';
import { DocumentDownloadDTO, DocumentList } from 'types/documents';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import { bluePlanetTheme } from 'ui/theme';
import DocumentListItem from 'domain/documents/DocumentListItem';

interface Props {
  contentId: string;
  attachmentIds: string[];
  listAttachments: (contentId: string) => Promise<DocumentList>;
  downloadAttachment: (contentId: string, documentId: string) => Promise<DocumentDownloadDTO>;
  isPreview: boolean;
  onReadMore: () => void;
}

export default function Attachments(props: Props) {
  const [attachments] = useResourceLegacy(async () => {
    if (props.attachmentIds.length > 0 && !props.isPreview) {
      return props.listAttachments(props.contentId);
    } else {
      return Promise.resolve({ documents: [] });
    }
  }, [props.attachmentIds, props.isPreview, props.contentId]);

  if (props.isPreview) {
    return props.attachmentIds.length > 0 ? (
      <Card>
        <button
          style={{ color: bluePlanetTheme.bluePlanetPalette.grey.dark }}
          className="u-flex u-flex-align-center text-small"
          onClick={props.onReadMore}
          data-track-content-excerpt-readmore
        >
          <FileIcon name="" fontSize="small" className="u-half-spacing-right" />
          {props.attachmentIds.length} attachment{props.attachmentIds.length === 1 ? '' : 's'}
        </button>
      </Card>
    ) : null;
  }

  return attachments.state === 'fetched' && attachments.resource.documents.length > 0 ? (
    <Card>
      {attachments.resource.documents.map(doc => (
        <DocumentListItem
          key={doc.name}
          document={doc}
          onDownload={docId => props.downloadAttachment(props.contentId, docId)}
          iconSize="small"
        />
      ))}
    </Card>
  ) : null;
}
