import React from 'react';
import CompanyOverviewProfileSection from 'domain/companies/profile/content';
import Tabs from 'ui/modules/tabs/Tabs';
import Tab from 'ui/modules/tabs/Tab';
import { CompanyProfile } from 'types/company';
import { useCompanyFeatureToggles } from 'apis/CompanyAPI/companies/useCompanyFeatureToggles';
import { getOrElse } from 'util/resource';
import Team from 'domain/companies/profile/team';
import { HasScope } from 'hooks/useCompanyRole';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import Box from 'ui/views/containers/Box';
import LockIcon from 'ui/elements/icons/LockIcon';
import RestrictedSection from 'pages/Company/Overview/sections/RestrictedSection';
import CompanyOverviewActivitySection from 'pages/Company/Overview/sections/updates';
import Documents from 'pages/Company/Overview/sections/Documents';
import GoalsList from 'pages/Company/Overview/sections/goals';
import Kpis from 'pages/Company/Overview/sections/Kpis';
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { CompanyOverviewSection, companyOverviewSections } from 'urls';
import Sticky from 'ui/views/containers/Sticky';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { useUnreadDocuments } from 'apis/DocumentAPI/useDocuments';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { UnreadDocumentDTO } from 'types/documents';

interface Props {
  companyProfile: CompanyProfile;
  hasScope: HasScope;
  viewAs: CompanyViewAs;
  disableSubRouting?: boolean;
  initialTab?: CompanyOverviewSection;
}

function Section({
  path,
  render,
  disableSubRouting,
  value,
  activeTab,
}: {
  path?: string | string[];
  render: () => React.ReactNode;
  disableSubRouting?: boolean;
  value: CompanyOverviewSection;
  activeTab: CompanyOverviewSection;
}) {
  return disableSubRouting ? value === activeTab ? render() : null : <Route exact path={path} render={render} />;
}

export default function ProfileSections({ companyProfile, hasScope, viewAs, disableSubRouting, initialTab }: Props) {
  const history = useHistory();
  const tabs = companyOverviewSections;
  const match = useRouteMatch();

  // Split url into irrelevant and relevant (sub route) part
  const urlParts = history.location.pathname.split(match.url);
  // Should always be the first case - just ensuring we don't get an index out of bounds crash
  const profilePartOfUrl = urlParts.length > 1 ? urlParts[1] : '🤷‍♂️';

  const activeTab =
    initialTab ??
    (tabs.filter(tab =>
      profilePartOfUrl
        .slice(1) // Drop the / at the start
        .startsWith(tab),
    )[0] ||
      'profile');

  const ft = useCompanyFeatureToggles(companyProfile.slug, hasScope('Read', 'Company'));
  const companyFeatures = getOrElse(ft.resource, []);

  const unreadDocuments = getOrElse(
    useUnreadDocuments(companyProfile.id).resource,
    emptyPaginatedResult<UnreadDocumentDTO>(),
  );

  const isVisitor = !hasScope('Read', 'Company');

  // we don't want admins to see the admin view in the company profile from communities
  const actualViewAs = viewAs === 'Admin' ? 'Shareholder' : viewAs;

  const isDesktopUp = useMediaQuery(bluePlanetTheme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const [activeTabWithoutSubRouting, setActiveTab] = React.useState(activeTab);
  const handleTabChange = (section: CompanyOverviewSection) =>
    disableSubRouting ? setActiveTab(section) : history.push(`${match.url}/${section}`);
  const canViewUpdates = hasScope('Read', 'Update');

  return (
    <>
      <Sticky top={isDesktopUp ? -20 : 0} className="u-half-spacing-bottom">
        <Box>
          <Tabs
            activeTab={disableSubRouting ? activeTabWithoutSubRouting : activeTab}
            className={isMobile ? 'u-half-spacing-x' : undefined}
          >
            <Tab
              data-intercom-target="nav-company-profile"
              onClick={() => handleTabChange('profile')}
              value="profile"
              label="Profile"
            />
            <Tab
              data-intercom-target="nav-updates"
              onClick={() => handleTabChange('updates')}
              value="updates"
              label="Updates"
              icon={!canViewUpdates ? <LockIcon fontSize="small" /> : undefined}
              iconPosition="start"
            />
            <Tab data-intercom-target="nav-team" onClick={() => handleTabChange('team')} value="team" label="Team" />
            {companyFeatures.includes('documents') && (
              <Tab
                data-intercom-target="nav-documents"
                hasActivityIndicator={unreadDocuments.total > 0}
                onClick={() => handleTabChange('documents')}
                value="documents"
                label="Documents"
                icon={isVisitor ? <LockIcon fontSize="small" /> : undefined}
                iconPosition="start"
              />
            )}
            {hasScope('Read', 'KPI') && (
              <Tab
                data-intercom-target="nav-kpis"
                onClick={() => handleTabChange('kpis')}
                value="kpis"
                label="KPIs"
                iconPosition="start"
              />
            )}
            {companyFeatures.includes('goals') && (
              <Tab
                data-intercom-target="nav-goals"
                onClick={() => handleTabChange('goals')}
                value="goals"
                label="Goals"
                icon={isVisitor ? <LockIcon fontSize="small" /> : undefined}
                iconPosition="start"
              />
            )}
          </Tabs>
        </Box>
      </Sticky>
      <Section
        value="profile"
        activeTab={activeTabWithoutSubRouting}
        disableSubRouting={disableSubRouting}
        path={`${match.path}/profile`}
        render={() => (
          <CompanyOverviewProfileSection companyProfile={companyProfile} viewAs={actualViewAs} hideRevenues />
        )}
      />
      <Section
        value="team"
        activeTab={activeTabWithoutSubRouting}
        disableSubRouting={disableSubRouting}
        path={`${match.path}/team`}
        render={() => <Team company={companyProfile} canEditCompany={false} />}
      />

      <Section
        value="updates"
        activeTab={activeTabWithoutSubRouting}
        disableSubRouting={disableSubRouting}
        path={`${match.path}/updates`}
        render={() =>
          !canViewUpdates ? (
            <RestrictedSection company={companyProfile} section="Updates" />
          ) : (
            <CompanyOverviewActivitySection
              company={companyProfile}
              viewAs={actualViewAs}
              onChangeSection={section => {
                if (disableSubRouting) {
                  return () => {
                    setActiveTab(section);
                  };
                } else {
                  return `${match.url}/${section}`;
                }
              }}
            />
          )
        }
      />
      {!disableSubRouting && <Redirect exact from={match.path} to={`${match.url}/profile`} />}
      <Section
        value="documents"
        activeTab={activeTabWithoutSubRouting}
        disableSubRouting={disableSubRouting}
        path={[`${match.path}/documents/folders/:folderId`, `${match.path}/documents`]}
        render={() =>
          viewAs === 'Visitor' ? (
            <RestrictedSection company={companyProfile} section="Documents" />
          ) : (
            <Documents
              navigation={disableSubRouting ? { type: 'state' } : { type: 'href', href: `${match.url}/documents` }}
              company={companyProfile}
              viewAs={actualViewAs}
            />
          )
        }
      />
      <Section
        value="goals"
        activeTab={activeTabWithoutSubRouting}
        disableSubRouting={disableSubRouting}
        path={`${match.path}/goals`}
        render={() =>
          viewAs === 'Visitor' ? (
            <RestrictedSection company={companyProfile} section="Goals" />
          ) : (
            <GoalsList company={companyProfile} viewAs={actualViewAs} />
          )
        }
      />
      {hasScope('Read', 'KPI') && (
        <Section
          value="kpis"
          activeTab={activeTabWithoutSubRouting}
          disableSubRouting={disableSubRouting}
          path={`${match.path}/kpis`}
          render={() => <Kpis company={companyProfile} viewAs={actualViewAs} />}
        />
      )}
    </>
  );
}
