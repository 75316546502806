import React from 'react';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import DOMPurify from 'dompurify';
import useNotify from 'hooks/useNotify';
import { PatchShortlist, Shortlist } from 'types/user';
import RichText from 'ui/elements/text/RichText';
import useLazyResource from 'util/resource/useLazyResource';
import { insertLinksForUrls } from 'util/stringUtils';

export const useUpdateShortlist = (
  shortlistId: string,
  options?: { onSuccess?: (updatedShortlist: Shortlist) => void; onFailure?: (e?: string) => void },
) => {
  const notify = useNotify();

  return useLazyResource(
    (payload: PatchShortlist) =>
      usersApi.shortlists.update(shortlistId, payload.name, payload.description, payload.isPublic),
    {
      onSuccess: updatedShortlist => {
        options?.onSuccess && options.onSuccess(updatedShortlist);
      },
      onFailure: e =>
        options?.onFailure
          ? options.onFailure(e)
          : () => notify('error', `Something unexpected happened when trying to update the collection.`),
    },
  );
};

export function ShortlistDescription({ description }: { description: string | undefined }) {
  if (!description) return null;
  const allowedHTML = {
    ALLOWED_TAGS: ['a'],
    ALLOWED_ATTR: ['href', 'class', 'target'],
  };
  const textWithUrls = insertLinksForUrls(description);
  const cleaned = DOMPurify.sanitize(textWithUrls, allowedHTML);
  return <RichText>{() => cleaned}</RichText>;
}
