import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';
import React from 'react';
import FileIcon from 'domain/shared/Files/File/FileIcon';
import styles from './styles.scss';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import Chip from 'ui/elements/Chip';
import useDownloadWarning from 'domain/documents/useDownloadWarning';
import { DocumentDTO } from 'types/documents';
import { useDownloadPitchDocument } from 'domain/documents/useDownloadDocument';

interface Props {
  document: DocumentDTO;
  companyId: number;
  className?: string;
  isPitchDeck?: boolean;
}

export default function PitchDocumentTile({ document, companyId, className, isPitchDeck }: Props) {
  const [downloadDocument, isDownloading] = useDownloadPitchDocument(companyId);

  const { onClick, WarningDialog } = useDownloadWarning(document.name, () => downloadDocument(document.id));

  return (
    <>
      <button onClick={onClick} className={cx(className, styles.document, 'u-content-padding-y')}>
        <div className="u-flex u-flex-space-between u-flex-align-center">
          <div className={cx(styles.tile, `u-flex u-flex-align-center`)}>
            {isDownloading ? <CircularProgress size={24} /> : <FileIcon name={document.name} fontSize="medium" />}
            <div className={cx(styles.text, 'u-ellipsis text-small u-align-center u-half-spacing-left')}>
              {document.name}
            </div>
            {isPitchDeck && <Chip className="u-content-spacing-x" label="pitch deck" color="blue-light" />}
          </div>
          <div>
            <ChevronRightIcon strokeWidth={2} />
          </div>
        </div>
      </button>
      {WarningDialog}
    </>
  );
}
