import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import FormRow from 'ui/elements/form/FormRow';
import CurrencyInput from 'ui/elements/form/numbers/CurrencyInput';
import SettingsAccordion from 'ui/modules/Accordion/SettingsAccordion';
import currencyMap, { DEFAULT_CURRENCY } from 'util/currency';
import * as Yup from 'yup';
import { SUCCESS_WIDTH } from '../utils';
import Select from 'ui/elements/form/Select/Select';
import Label from 'ui/elements/form/Label';
import styles from '../styles.scss';
import DatePicker from 'ui/elements/form/DatePicker';
import { DATE_FORMAT } from 'util/constants';
import TextField from 'ui/elements/form/TextField';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { getOrUndefined } from 'util/resource';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import LoadableResource from 'util/resource/Resource';
import { asDayMonthNameYear } from 'util/dateUtils';
import { contentSpacing } from 'ui/theme/themeConstants';
import { useMediaQuery, useTheme } from '@mui/material';
import { useCloseFundingRound, useNewFundingRound, useUpdateFundingRound } from './actions';
import SectionHeading from 'ui/elements/SectionHeading';
import FundingRoundArchive from './FundingRoundArchive';
import Card from 'ui/views/cards/Card';
import LinkButton from 'ui/elements/buttons/LinkButton';
import MaxWidth from 'ui/views/containers/MaxWidth';
import useDialogHandler from 'hooks/useDialogHandler';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { CloseFundingRoundDTO, CompanyProfile, FullFundingRound } from 'types/company';
import addDays from 'date-fns/addDays';
import CloseFundingRoundDialog from './CloseFundingRoundDialog';
import { Route } from 'react-router';
import UpdateArchivedFundingRoundDialog from './UpdateArchivedFundingRoundDialog';
import useRoute from 'hooks/useRoute';
import { useFundingStages } from 'apis/CompanyAPI/labels/useFundingStages';
import { BODY_ID } from 'ui/views/layouts/BaseLayout/ScrollableBody';
import { useCompanyUserSummary } from 'apis/CompanyAPI/companies/useCompanyUserSummary';
import { companyUrls } from 'urls';
import { Link } from 'react-router-dom';
import InfoMessage from 'ui/views/messages/InfoMessage';
import LightbulbIcon from 'ui/elements/icons/BulbIcon';
import Chip from 'ui/elements/Chip';

interface Props {
  company: CompanyProfile;
}

export const fundingRoundTypes = ['equity', 'other'];
type FundingRoundsSections = 'current' | 'archive';

export const fundingRoundSchema = Yup.object().shape({
  capitalSeeking: Yup.number()
    .required('* required field')
    .lessThan(1000000000000000, 'Capital seeking must be less than 1 000 000 000 000 000'),
  capitalSeekingCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  preMoneyValuation: Yup.number()
    .nullable()
    .required('* required field')
    .lessThan(1000000000000000, 'Pre-money valuation must be less than 1 000 000 000 000 000'),
  preMoneyValuationCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  previousValuation: Yup.number()
    .nullable()
    .required('* required field')
    .lessThan(1000000000000000, 'Previous valuation must be less than 1 000 000 000 000 000'),
  previousValuationCurrency: Yup.string().oneOf(Object.keys(currencyMap)),
  fundingStage: Yup.object().required(),
  leadInvestor: Yup.object().notRequired().nullable(),
  type: Yup.string().required('* required field'),
  plannedClosingDate: Yup.string().required('* required field'),
});

export default function CompanyFundingRound(props: Props) {
  const route = useRoute();
  const [success, setSuccess] = useState(false);
  const [active, setActive] = useState(false);
  const [isActiveRoundExpanded, setActiveRoundExpanded] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeSection, setActiveSection] = useState<FundingRoundsSections>('current');
  const { resource: userSummaryResource } = useCompanyUserSummary(props.company.id);
  const userSummary = getOrUndefined(userSummaryResource);
  const connectionCount =
    (userSummary?.numOfMentors || 0) +
    (userSummary?.numOfFollowers || 0) +
    (userSummary?.numOfInvestors || 0) +
    (userSummary?.numOfEmployees || 0);

  const dialogHandler = useDialogHandler();

  const fundingRoundRef = useRef<HTMLDivElement>(null);
  const archiveRef = useRef<HTMLDivElement>(null);

  const [fundingRoundResource, setFundingRoundResource, setFundingRoundResourceState, reloadFundingRound] =
    useResourceLegacy(() => companiesApi.fundingRound.getActive(props.company.slug), [props.company.id]);
  const [archiveResource, setArchiveResource] = useResourceLegacy(
    () => companiesApi.fundingRound.listArchive(props.company.id),
    [props.company.id],
  );
  const [companyInvestors] = usePaginatedResourceLegacy(
    paginationOptions =>
      companiesApi.users.search(props.company.slug, {
        role: 'investors',
        ...(paginationOptions || {
          limit: 20,
        }),
      }),
    [props.company.id],
  );
  const { resource: fundingStageOptionsResource } = useFundingStages();

  const [onNewFundingRound, isSavingNewFundingRound] = useNewFundingRound(props.company.id, props.company.slug, {
    onSuccess: () => {
      reloadFundingRound();
      setSuccess(true);
    },
    onFailure: () => {
      () => setSuccess(false);
    },
  });

  const [onUpdateFundingRound, isSavingUpdateFundingRound] = useUpdateFundingRound(
    props.company.id,
    props.company.slug,
    {
      onSuccess: result => {
        result && setFundingRoundResource(() => result);
        setSuccess(true);
      },
      onFailure: () => {
        () => setSuccess(false);
      },
    },
  );

  const [onCloseFundingRound, isSavingCloseFundingRound] = useCloseFundingRound(props.company.id, props.company.slug, {
    onSuccess: () => {
      setFundingRoundResourceState({ state: 'idle' });
      setSuccess(true);
      dialogHandler.close();
    },
    onFailure: () => {
      () => setSuccess(false);
    },
  });

  const fundingRound = getOrUndefined(fundingRoundResource) as FullFundingRound | undefined;
  const fundingStageOptions =
    getOrUndefined(fundingStageOptionsResource)?.values?.map(s => ({ ...s, value: s.id })) ?? [];

  const saving = isSavingCloseFundingRound || isSavingNewFundingRound || isSavingUpdateFundingRound;

  const formik = useFormik({
    initialValues: {
      capitalSeeking: fundingRound?.capitalSeeking?.value,
      capitalSeekingCurrency: fundingRound?.capitalSeeking?.currency ?? DEFAULT_CURRENCY,
      preMoneyValuation: fundingRound?.preMoneyValuation?.value,
      preMoneyValuationCurrency: fundingRound?.preMoneyValuation?.currency ?? DEFAULT_CURRENCY,
      previousValuation: fundingRound?.previousValuation?.value,
      previousValuationCurrency: fundingRound?.previousValuation?.currency ?? DEFAULT_CURRENCY,
      fundingStage: fundingRound?.fundingStage ?? props.company.fundingStage,
      leadInvestor: fundingRound?.leadInvestor,
      type: fundingRound?.type,
      openingDate: fundingRound?.openingDate,
      plannedClosingDate: fundingRound?.plannedClosingDate,
    },
    onSubmit: values => {
      fundingRound
        ? onUpdateFundingRound({
            capitalSeeking: values.capitalSeeking,
            capitalSeekingCurrency: values.capitalSeekingCurrency,
            preMoneyValuation: values.preMoneyValuation,
            preMoneyValuationCurrency: values.preMoneyValuationCurrency,
            previousValuation: values.previousValuation,
            previousValuationCurrency: values.previousValuationCurrency,
            leadInvestor: values.leadInvestor?.cwUserId ?? null,
            type: values.type ?? 'Other',
            plannedClosingDate: values.plannedClosingDate,
            fundingStage: values.fundingStage?.id,
          })
        : onNewFundingRound({
            capitalSeeking: values.capitalSeeking ?? 0,
            capitalSeekingCurrency: values.capitalSeekingCurrency,
            preMoneyValuation: values.preMoneyValuation ?? 0,
            preMoneyValuationCurrency: values.preMoneyValuationCurrency,
            previousValuation: values.previousValuation ?? 0,
            previousValuationCurrency: values.previousValuationCurrency,
            leadInvestor: values.leadInvestor?.cwUserId,
            type: values.type ?? 'Other',
            plannedClosingDate: values.plannedClosingDate,
            fundingStage: values.fundingStage?.id,
          });
    },
    validationSchema: fundingRoundSchema,
    enableReinitialize: true,
  });

  function closeFundingRound(id: UUID, fundingRound: CloseFundingRoundDTO, shouldUpdateValuation?: boolean) {
    if (fundingRound) {
      onCloseFundingRound({ fundingRound: fundingRound, shouldUpdateValuation }).then(() => {
        setFundingRoundResourceState({ state: 'idle' });
        setArchiveResource(prev => ({
          fundingRounds: [
            {
              id: id,
              visibility: 'full',
              capitalSeeking: {
                value: fundingRound.capitalSeeking,
                currency: fundingRound.capitalSeekingCurrency,
              },
              capitalRaised: {
                value: fundingRound.capitalRaised,
                currency: fundingRound.capitalRaisedCurrency,
              },
              preMoneyValuation: {
                value: fundingRound.preMoneyValuation,
                currency: fundingRound.preMoneyValuationCurrency,
              },
              previousValuation: {
                value: formik.values.previousValuation,
                currency: formik.values.previousValuationCurrency,
              },
              fundingStage: fundingStageOptions.find(f => f.id === fundingRound.fundingStage),
              type: fundingRound.type,
              openingDate: formik.values.openingDate!,
              plannedClosingDate: fundingRound.plannedClosingDate,
              actualClosingDate: new Date().toISOString(),
              leadInvestor: formik.values.leadInvestor,
            },
            ...prev.fundingRounds,
          ],
        }));
        formik.resetForm();
      });
    }
  }

  useEffect(() => {
    if (formik.dirty) {
      setSuccess(false);
    }
  }, [formik.dirty]);

  useEffect(() => {
    setActive(!!fundingRound);
  }, [fundingRound]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  function handleSelectSection(section: FundingRoundsSections) {
    if (!section) {
      return;
    }

    setActiveSection(section);
    const el = document.getElementById(section);

    if (el) {
      // The 20px offset from the top of the element, makes the section we scroll to
      // align with the navigation menu box at the left (visible on desktop)

      const body = document.getElementById(BODY_ID);
      body?.scrollTo({ top: el.offsetTop - 20 ?? 0, behavior: 'smooth' });
    }
  }

  function handleScroll() {
    if (fundingRoundRef.current && archiveRef.current) {
      const nearestSection = [fundingRoundRef.current, archiveRef.current].reduce((prev, curr) => {
        return Math.abs(prev.getBoundingClientRect().top) < Math.abs(curr.getBoundingClientRect().top) ? prev : curr;
      });
      setActiveSection(nearestSection.id as FundingRoundsSections);
    }
  }

  function handleSelectDate(date: Date): void {
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    formik.setFieldValue('plannedClosingDate', newDate.toISOString());
  }

  return (
    <div className="u-flex u-flex--align-items-start">
      {fundingRound && (
        <CloseFundingRoundDialog
          dialogHandler={dialogHandler}
          fundingRound={fundingRound}
          onCloseFundingRound={closeFundingRound}
          isSaving={isSavingCloseFundingRound}
        />
      )}
      <LoadableResource resource={archiveResource} renderLoading="Nothing" renderError="Nothing">
        {fundingRounds =>
          fundingRounds.fundingRounds.length > 0 && (
            <Card className={styles.profileSidebar}>
              <LinkButton
                size="small"
                className={`u-ellipsis ${activeSection === 'current' ? styles.activeTab : undefined}`}
                onClick={() => handleSelectSection('current')}
              >
                Funding round
              </LinkButton>
              <LinkButton
                size="small"
                className={`u-ellipsis ${activeSection === 'archive' ? styles.activeTab : undefined}`}
                onClick={() => handleSelectSection('archive')}
              >
                Archive
              </LinkButton>
            </Card>
          )
        }
      </LoadableResource>
      <MaxWidth width="xl">
        <div ref={fundingRoundRef} id="current">
          <SettingsAccordion
            title={
              <div className="u-flex-align-center u-flex-space-between" style={{ width: '95%' }}>
                <span style={{ whiteSpace: 'nowrap', marginRight: contentSpacing }} className="u-flex-align-center">
                  Funding round{' '}
                  {fundingRound && <Chip className="u-half-spacing-left" color={'orange'} label={'Active now'} />}
                </span>
                {!isActiveRoundExpanded && fundingRound?.plannedClosingDate && (
                  <span className={styles.activeRoundText}>
                    {!isMobile && <span>Closing date: {asDayMonthNameYear(fundingRound.plannedClosingDate)}</span>}
                  </span>
                )}
              </div>
            }
            iconColor="indigo"
            showPill={true}
            onActivate={() => setActive(true)}
            onSave={formik.handleSubmit}
            onExpand={isExpanded => setActiveRoundExpanded(isExpanded)}
            saving={saving}
            isSuccess={success}
            setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
            description="By activating the funding round module and filling in the basic details, you advertise on your pitch to potential investors that your company is currently seeking funding. The details are only available to your followers, but anyone viewing your pitch will see that you have an active funding round."
            isActivated={active}
            isExpanded={active}
            isDirty={formik.dirty}
          >
            {connectionCount < 10 && (
              <InfoMessage
                className="u-section-spacing-bottom"
                icon={!isMobile ? <LightbulbIcon /> : undefined}
                color="blue-light"
                dismissKey={`has-read-funding-round-invite-audience-tip-${props.company.id}`}
              >
                {!isMobile ? (
                  <p className="text-small">
                    Supercharge your campaign by inviting your current{' '}
                    <Link
                      className="text-link text-small"
                      to={companyUrls.settings.inviteUser(props.company.slug, 'investor')}
                    >
                      shareholders
                    </Link>
                    , as well as{' '}
                    <Link
                      className="text-link text-small"
                      to={(companyUrls.settings.inviteUser(props.company.slug), 'prospective_investor')}
                    >
                      connections
                    </Link>{' '}
                    who’ve shown interest in your company. They are your greatest ambassadors, and can help you get more
                    attention and a bigger reach!
                  </p>
                ) : (
                  <p className="text-small">
                    Invite your current{' '}
                    <Link className="text-link text-small" to={companyUrls.settings.inviteUser(props.company.slug)}>
                      shareholders and connections
                    </Link>{' '}
                    to increase your visibility and chances of success!
                  </p>
                )}
              </InfoMessage>
            )}
            <FormRow className={styles.formRow}>
              <div className="u-fullWidth">
                <CurrencyInput
                  name="capitalSeeking"
                  value={formik.values.capitalSeeking}
                  error={formik.touched.capitalSeeking ? formik.errors.capitalSeeking : undefined}
                  onChange={v => formik.setFieldValue('capitalSeeking', v)}
                  label="Capital seeking"
                  placeholder="5 000 000"
                  currency={formik.values.capitalSeekingCurrency}
                  onCurrencyChange={v => formik.setFieldValue('capitalSeekingCurrency', v)}
                  touched={formik.touched.capitalSeeking}
                />
              </div>
              <LoadableResource resource={companyInvestors} renderError="Nothing">
                {investors => (
                  <div className="u-fullWidth">
                    <Label>{'Lead investor (Optional)'}</Label>
                    <Select
                      name="leadInvestor"
                      placeholder="Search"
                      value={formik.values.leadInvestor}
                      onChange={user => formik.setFieldValue('leadInvestor', user)}
                      onInputChange={input => (!input ? formik.setFieldValue('leadInvestor', '') : null)}
                      getOptionLabel={investor => investor.name}
                      options={investors.values}
                      error={formik.touched.leadInvestor && !!formik.errors.leadInvestor}
                      helperText={formik.touched.leadInvestor ? formik.errors.leadInvestor : undefined}
                    />
                  </div>
                )}
              </LoadableResource>
            </FormRow>
            <FormRow className={styles.formRow}>
              <div className="u-fullWidth">
                <CurrencyInput
                  name="preMoneyValuation"
                  value={formik.values.preMoneyValuation}
                  error={formik.touched.preMoneyValuation ? formik.errors.preMoneyValuation : undefined}
                  onChange={v => formik.setFieldValue('preMoneyValuation', v)}
                  label="Pre-money valuation"
                  placeholder="10 000 000"
                  currency={formik.values.preMoneyValuationCurrency}
                  onCurrencyChange={v => formik.setFieldValue('preMoneyValuationCurrency', v)}
                  touched={formik.touched.preMoneyValuation}
                  nullable={true}
                />
              </div>
              <div className="u-fullWidth">
                <Label>{'Funding stage'}</Label>
                <Select
                  value={formik.values.fundingStage}
                  placeholder="Select current funding stage"
                  onChange={label => (label ? formik.setFieldValue('fundingStage', label) : undefined)}
                  getOptionLabel={i => i.name}
                  options={fundingStageOptions}
                  error={formik.touched.fundingStage && !!formik.errors.fundingStage}
                  helperText={formik.touched.fundingStage ? formik.errors.fundingStage : undefined}
                />
              </div>
            </FormRow>
            <FormRow className={styles.formRow}>
              <div className="u-fullWidth">
                <CurrencyInput
                  name="previousValuation"
                  value={formik.values.previousValuation}
                  error={formik.touched.previousValuation ? formik.errors.previousValuation : undefined}
                  onChange={v => formik.setFieldValue('previousValuation', v)}
                  label="Previous valuation"
                  placeholder="10 000 000"
                  currency={formik.values.previousValuationCurrency}
                  onCurrencyChange={v => formik.setFieldValue('previousValuationCurrency', v)}
                  touched={formik.touched.previousValuation}
                  nullable={true}
                />
              </div>
              <div className="u-fullWidth">
                <Label>{'Type'}</Label>
                <Select
                  name="type"
                  placeholder="Select type"
                  value={formik.values.type}
                  onChange={type => formik.setFieldValue('type', type)}
                  getOptionLabel={type => type.charAt(0).toUpperCase() + type.slice(1)}
                  options={fundingRoundTypes}
                  error={formik.touched.type && !!formik.errors.type}
                  helperText={formik.touched.type ? formik.errors.type : undefined}
                  isClearable={false}
                />
              </div>
            </FormRow>
            <FormRow className={styles.formRow}>
              <div className="u-fullWidth">
                <Label>{'Closing date'}</Label>
                <DatePicker
                  name="plannedClosingDate"
                  dateFormat={DATE_FORMAT}
                  selected={formik.values.plannedClosingDate ? new Date(formik.values.plannedClosingDate) : null}
                  onChange={() => {
                    return formik.handleChange('plannedClosingDate');
                  }}
                  onSelect={handleSelectDate}
                  onBlur={formik.handleBlur}
                  customInput={
                    <TextField
                      error={formik.touched.plannedClosingDate && !!formik.errors.plannedClosingDate}
                      helperText={
                        formik.touched.plannedClosingDate && formik.errors.plannedClosingDate
                          ? formik.errors.plannedClosingDate
                          : 'Closing date can be maximum 90 days in the future'
                      }
                      suffix={<CalendarIcon />}
                    />
                  }
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 90)}
                />
              </div>
            </FormRow>
            <ButtonList align="left" className="u-content-spacing-y">
              <Button
                onClick={() => formik.handleSubmit()}
                className="u-ellipsis"
                kind="primary"
                isLoading={saving}
                isSuccess={success}
                setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
                style={{ width: `${SUCCESS_WIDTH + (!fundingRound ? 20 : 0)}px` }}
                disabled={saving || success || !formik.dirty}
              >
                {fundingRound ? 'Update' : 'Save & Publish'}
              </Button>
              {fundingRound && (
                <Button kind="secondary" color="grey" disabled={saving} onClick={() => dialogHandler.open()}>
                  Close round
                </Button>
              )}
            </ButtonList>
          </SettingsAccordion>
        </div>
        <LoadableResource resource={archiveResource} renderError="Nothing">
          {fundingRounds =>
            fundingRounds.fundingRounds.length > 0 && (
              <>
                <div ref={archiveRef} id="archive">
                  <section className="u-section-spacing-y">
                    <SectionHeading className="u-content-spacing-left" heading="Archive" />
                    <FundingRoundArchive fundingRounds={fundingRounds} />
                  </section>
                </div>
                <Route
                  path={route.match.path + '/edit/:id'}
                  render={() => (
                    <UpdateArchivedFundingRoundDialog
                      companyId={props.company.id}
                      companySlug={props.company.slug}
                      archivedFundingRounds={fundingRounds.fundingRounds}
                      setArchiveResource={setArchiveResource}
                    />
                  )}
                />
              </>
            )
          }
        </LoadableResource>
      </MaxWidth>
    </div>
  );
}
