import React, { useState } from 'react';
import { ExecutiveSummaryDTO, ICompany } from 'types/company';
import EditAction from 'ui/elements/actions/EditAction';
import UploadCtaButton from 'ui/elements/buttons/UploadCtaButton';
import SectionHeading from 'ui/elements/SectionHeading';
import Card from 'ui/views/cards/Card';
import WhyInvestDialog from './WhyInvestDialog';
import AIChip from '../AiChip';
import { useAiOnboardingSessionStorage } from 'domain/onboarding/company/Wizard/AIOnboarding';

interface Props {
  company: ICompany;
  executiveSummary: ExecutiveSummaryDTO;
  canEditCompany: boolean;
  onSave?: () => void;
}

export default function WhyInvestSection({ company, executiveSummary, canEditCompany, onSave }: Props) {
  const [isWhyInvestDialogOpen, setIsWhyInvestDialogOpen] = useState(false);
  const [isAiOnboarding] = useAiOnboardingSessionStorage();

  return (
    <>
      {(canEditCompany || executiveSummary.unfairAdvantage) && (
        <div>
          <SectionHeading
            heading={
              <>
                Why invest in us?
                {isAiOnboarding && (
                  <AIChip className="u-half-spacing-left" onClick={() => setIsWhyInvestDialogOpen(true)} />
                )}
              </>
            }
            addMobileMargin
          >
            {canEditCompany && (
              <button onClick={() => setIsWhyInvestDialogOpen(true)} aria-label="Edit section">
                <EditAction tooltip="Edit section" />
              </button>
            )}
          </SectionHeading>
          <Card>
            {!executiveSummary.unfairAdvantage && canEditCompany ? (
              <UploadCtaButton className="u-half-spacing-y" height={100} onClick={() => setIsWhyInvestDialogOpen(true)}>
                Which competitive advantages do you have that will help you succeed with your vision?
              </UploadCtaButton>
            ) : (
              <p className="u-half-spacing-y text-body">{executiveSummary.unfairAdvantage}</p>
            )}
          </Card>
        </div>
      )}

      <WhyInvestDialog
        company={company}
        executiveSummary={executiveSummary}
        isOpen={isWhyInvestDialogOpen}
        onClose={() => setIsWhyInvestDialogOpen(false)}
        onSuccess={onSave}
      />
    </>
  );
}
