import React, { ReactNode } from 'react';
import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import ScrollableBody from '../BaseLayout/ScrollableBody';

interface Props {
  children: React.ReactNode;
  title: string;
  heading?: string | ReactNode;
}

const Main = styled.main`
  background: ${bluePlanetTheme.bluePlanetPalette.white};
  min-height: 100vh;
  ${bluePlanetTheme.breakpoints.up('sm')} {
    padding: 5%;
  }
  ${bluePlanetTheme.breakpoints.up('md')} {
    padding: 18%;
  }
  ${bluePlanetTheme.breakpoints.up('lg')} {
    padding: ${bluePlanetTheme.spacing(23)} 18% 0 18%;
  }
`;

const Container = styled.div`
  max-width: 650px;
  padding: ${bluePlanetTheme.spacing(2)};
`;

export default function Registration({ children, title, heading }: Props) {
  return (
    <>
      <HeadTags title={title} />
      <ScrollableBody accountForMobileHeader={false}>
        <Main>
          <Container>
            {heading && (
              <h1 style={{ marginTop: 0 }} className="text-h2 u-section-spacing-bottom">
                {heading}
              </h1>
            )}
            {children}
          </Container>
        </Main>
      </ScrollableBody>
    </>
  );
}
