import { Location } from 'apis/MapboxAPI';
import { BusinessDomain, FundingStage, Industry, Stage } from 'types';
import { MentorExpertise } from 'apis/MentorAPI/types';
import { ICompany, Currency, LabelDTO } from './company';
import { Role } from './company/access';
import { RequestFollowStatus } from './follower';
import { List } from './api';
import { Investor } from 'apis/CompanyAPI/insights/types';
import { notEmpty } from 'util/arrayUtils';

export interface TinyUser {
  cwUserId: string;
  name?: string;
  imageUrl?: string;
}

export type UserProfileWithAccess = UserProfile & {
  email: string;
  phone?: string;
  roles: Role[];
  labels: LabelDTO[];
  joinedAt: string;
};

export type InvestorProfile = UserProfile & {
  investorType?: InvestorType;
  preferredInvestmentSize?: PreferredInvestmentSize;
};

export interface UserProfile {
  id: number;
  cwUserId: string;
  name: string;
  position?: string;
  employer?: string;
  imageUrl?: string;
  linkedInUrl?: string;
  phone?: string;
  email?: string;
}

export type SelfUserProfile = DetailedUserProfile & {
  userHash: string;
  location?: Location;
  email: string;
};

export type DetailedUserProfile = UserProfile & {
  description?: string;
  city?: string;
  country?: string;
  phone?: string;
  businessDomains: BusinessDomain[];
  industries: Industry[];
  industryInvestmentPreferences: Industry[];
  involvement: Involvement;
  investorType: InvestorType | null;
  investmentExperience?: InvestmentExperience[];
  investmentPreference?: string;
  educationLevel?: EducationLevel;
  investmentCapacity?: Currency;
  preferredInvestmentSize: PreferredInvestmentSize;
  yearsOfProfessionalExperience: YearsOfProfessionalExperience;
  mentorExpertise: MentorExpertise[];
  companiesInCommon: {
    role: Role;
    company: ICompany;
    isCommunity: boolean;
    isCompanyInCommunity: boolean;
  }[];
  isInvestor: boolean;
  isMentor: boolean;
};

export interface PatchUserPayload {
  name?: string;
  linkedInUrl?: string;
  imageUrl?: string;
  position?: string;
  employer?: string;
  description?: string;
  location?: Location | null;
  phone?: string;
  businessDomains?: number[];
  industries?: number[];
  industryInvestmentPreferences?: number[];
  involvement?: Involvement;
  investorType?: InvestorType;
  investmentExperience?: InvestmentExperience[];
  investmentPreference?: string;
  educationLevel?: string;
  investmentCapacity?: Currency;
  preferredInvestmentSize?: PreferredInvestmentSize;
  yearsOfProfessionalExperience?: string;
  mentorExpertise?: MentorExpertise[];
  isInvestor?: boolean;
  isMentor?: boolean;
}

export interface UserProfileDeleteRequest {
  userId: number;
  cwUserId: string;
  name: string;
  email: string;
  imageUrl?: string;
  deleteRequestAt: DateString;
}

export interface PreferredInvestmentSize {
  rangeFrom?: number;
  rangeTo?: number;
  currency: string;
}

interface InvestorTypeDescription {
  name: string;
  description: string;
}

export const investorTypes: { [key: string]: InvestorTypeDescription } = {
  angel: {
    name: 'Business Angel',
    description: 'High-net-worth individual investing in startups, also bringing his or her network and competence',
  },
  vc: { name: 'VC fund', description: 'Asset manager who invests in a broad range of companies' },
  retail: {
    name: 'Retail Investor',
    description: 'Private investor placing his or her own money, and typically has limited investment experience',
  },
  institutional: {
    name: 'Institutional investor',
    description:
      'Asset manager who invests in a broad range of companies, for example private equity funds, pension funds, mutual funds, insurance companies, investment banks, hedge funds and similar',
  },
  homeOffice: { name: 'Family Office', description: 'Asset manager representing a family' },
  scout: {
    name: 'Scout',
    description:
      'An advisor who works with various types of investors to identify and discover potential investment targets',
  },
  privateEquity: {
    name: 'Private equity',
    description: 'Larger investment funds specializing in investing in more mature startups and scale-ups',
  },
  analyst: {
    name: 'Analyst',
    description:
      'An advisor who works with different types of investors to identify potential investment targets and provide analysis and insights',
  },
  other: { name: 'Other', description: 'Investor profile does not match any of these categories' },
};

export type InvestorType = Extract<keyof typeof investorTypes, string>;

export interface AnonymousUserDTO {
  investorType?: InvestorType;
  region?: string;
  country?: string;
}

export function getInvestorTypeWithArticle(investorType?: InvestorType) {
  switch (investorType) {
    case 'angel':
      return 'A business angel investor';
    case 'vc':
      return 'An investor from a VC fund';
    case 'retail':
      return 'A retail investor';
    case 'institutional':
      return 'An institutional investor';
    case 'homeOffice':
      return 'A family office investor';
    case 'scout':
      return 'A scout';
    case 'privateEquity':
      return 'A private equity investor';
    case 'analyst':
      return 'An analyst';
    case 'other':
      return 'An investor';
    default:
      return 'Someone';
  }
}

export function getAnonymousUserTitle(user: AnonymousUserDTO) {
  const title = getInvestorTypeWithArticle(user.investorType);
  const locations = [user.region, user.country].filter(v => !!v);
  const location = locations.length > 0 ? ` from ${locations.join(', ')}` : '';
  return `${title}${location}`;
}

export function getInvestorUserTitle(user: Investor) {
  const parts = [
    user.investorDetails.investorType && user.investorDetails.investorType != 'other'
      ? investorTypes[user.investorDetails.investorType].name
      : undefined,
    user.investorDetails.region,
    user.investorDetails.country,
  ].filter(notEmpty);

  return parts.length > 0 ? `, ${parts.join(', ')}` : '';
}

export const investmentExperiences = {
  companyExit: 'Company Exit',
  stockMarketInvestments: 'Stock Market Investments',
  propertyInvestments: 'Property investments',
  growthInvestments: 'Growth investments',
  cash: 'Cash',
  other: 'Other',
};
export type InvestmentExperience = keyof typeof investmentExperiences;

export const educationLevels = {
  none: 'None',
  Bachelor: 'Bachelor',
  Master: 'Master',
  Phd: 'Phd',
  Other: 'Other',
};
export type EducationLevel = keyof typeof educationLevels;

export const yearsOfProfessionalExperiences = {
  none: 'None',
  '0-5': '0-5',
  '5-10': '5-10',
  '10-15': '10-15',
  '15-20': '15-20',
  '20+': '20+',
};
export type YearsOfProfessionalExperience = keyof typeof yearsOfProfessionalExperiences;

export interface InvitedUser {
  email: string;
  inviteDate: string;
  roles: Role[];
}

export interface Involvement {
  provideGeneralFeedback: boolean;
  openForBoardMemberPosition: boolean;
  openForAdvisoryBoardPosition: boolean;
  openForMentorPosition: boolean;
  openForSweatEquityProjects: boolean;
}

export interface ICwUserId {
  cwUserId: string;
}

export interface RegisterUserForm {
  invitationToken?: string;
  companyUserInvitationToken?: string;
  acceptedEmails?: boolean;
  acceptedTerms?: boolean;
  acceptedInvestmentOpportunities?: boolean;
}

export interface VerifyEmailResult {
  email: string;
}

export interface InviteTokenDTO {
  companyId: number;
  companyName: string;
  logoUrl: string;
  role: Role;
  invitationToken: string;
}

export interface CommunityPendingUser {
  userId: number;
  cwUserId: string;
  name: string;
  requestedAt: string;
  status: RequestFollowStatus;
  role: string;
  imageUrl?: string;
}

export interface PendingEmailChangeDTO {
  state: 'idle' | 'pending';
  email?: string;
}

export interface IntercomFeedback {
  feedback?: string;
  anonymous: boolean;
  feedbackContext: FeedbackContext;
}

export type FeedbackContext =
  | 'CompanyStatistics'
  | 'CommunityManagment'
  | 'CommunityGroupFeedbackYes'
  | 'CommunityGroupFeedbackNo'
  | 'InterestedInGreenArena'
  | 'InterestedInFintechArena'
  | 'InterestedInAIArena'
  | 'InterestedInProptechArena'
  | 'InterestedInOceantechArena'
  | 'InterestedInAgritechArena';

export interface VerifyEmailResponseDTO {
  email: string;
}

export type RegistrationResult = 'unverified' | 'registered';

type ShortlistCompany = ICompany & {
  lastUpdated: string;
};

export type ShortlistDetailedCompany = ICompany & {
  type: 'detailed';
  id: number;
  mission: string;
  imageURL: string;
  established: string;
  location: Location;
  industries?: List<Industry>;
  stages?: List<Stage>;
  fundingStage?: FundingStage;
};

export type ShortlistPublicCompany = ICompany & {
  type: 'public';
  id: number;
  imageURL: string;
  established: string;
  location: Location;
};

export type ShortlistPrivateCompany = {
  type: 'private';
};

export type ShortlistPageCompany = ShortlistDetailedCompany | ShortlistPublicCompany | ShortlistPrivateCompany;

export interface Shortlist {
  id: string;
  name: string;
  description?: string;
  isPublic: boolean;
  companies: ShortlistCompany[];
}

export interface DetailedShortlist {
  id: string;
  name: string;
  description?: string;
  isPublic: boolean;
  companies: ShortlistPageCompany[];
  owner: TinyUser;
}

export interface PatchShortlist {
  name?: string;
  description?: string;
  isPublic?: boolean;
}

export interface MfaStatus {
  isEnabled: boolean;
}

export interface ChangeMfaStatus {
  isEnabled: boolean;
  password: string;
  shouldResetEnrollments: boolean;
}
