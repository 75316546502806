import React from 'react';
import { companyUrls } from 'urls';
import { Todo } from './Todo';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { ICompany } from 'types/company';
import { differenceInDays, startOfDay } from 'date-fns';
import themeBluePlanet from 'ui/theme/themeBluePlanet';
import UpdateIcon from 'ui/elements/icons/UpdateIcon';
import Tooltip from 'ui/elements/Tooltip';
import QuestionIcon from 'ui/elements/icons/QuestionIcon';

interface Props {
  company: ICompany;
  lastPostedUpdate?: DateString;
}

export default function UpdateInvestors({ company, lastPostedUpdate }: Props) {
  if (!lastPostedUpdate) {
    return (
      <Todo
        href={companyUrls.overview(company.slug, 'updates', { isPostingUpdate: true })}
        action={<span className="text-link">Post update</span>}
      >
        <div>
          <IconAvatar color="blue" size={{ width: '40px', height: '40px' }}>
            <UpdateIcon />
          </IconAvatar>
        </div>
        <div className="u-flex">
          Post an update to your connections
          <Tooltip
            className="u-half-spacing-left"
            color="light"
            title={
              <p className="text-body" style={{ fontWeight: '400' }}>
                By keeping your shareholders up to date with regular updates, you may build trust over time and increase
                the chances of re-investments from your network.
              </p>
            }
          >
            <div>
              <QuestionIcon fontSize="small"></QuestionIcon>
            </div>
          </Tooltip>
        </div>
      </Todo>
    );
  }

  const timeSinceUpdate = differenceInDays(startOfDay(new Date()), startOfDay(new Date(lastPostedUpdate)));
  const color =
    timeSinceUpdate < 55
      ? themeBluePlanet.bluePlanetPalette.blue.main
      : timeSinceUpdate < 85
      ? themeBluePlanet.bluePlanetPalette.orange.medium
      : themeBluePlanet.bluePlanetPalette.red.main;

  return (
    <Todo
      href={companyUrls.overview(company.slug, 'updates', { isPostingUpdate: true })}
      action={<span className="text-link">Post update</span>}
    >
      <div>
        <IconAvatar color="blue" size={{ width: '40px', height: '40px' }}>
          <b style={{ color: color }}>{timeSinceUpdate}</b>
        </IconAvatar>
      </div>
      Days since last update
    </Todo>
  );
}
