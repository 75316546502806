import React from 'react';
import { ICompany, UserProfilesSummaryDTO } from 'types/company';
import { companyUrls } from 'urls';
import { FollowerRequestDTO } from 'types/follower';
import { PaginatedResult } from 'types';
import { addPossessive, pluralize } from 'util/stringUtils';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import RocketIcon from 'ui/elements/icons/RocketIcon';
import Tooltip from 'ui/elements/Tooltip';
import QuestionIcon from 'ui/elements/icons/QuestionIcon';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import { Todo } from './Todo';
interface Props {
  company: ICompany;
  userSummary: UserProfilesSummaryDTO;
  followerList: PaginatedResult<FollowerRequestDTO>;
}

export default function BuildYourNetwork({ company, userSummary, followerList }: Props) {
  if (followerList.total > 0) {
    return (
      <Todo
        href={companyUrls.settings.users(company.slug, 'requests')}
        action={<span className="text-link">View now</span>}
      >
        <IconAvatar color="blue" size={{ width: '40px', height: '40px' }}>
          <b>{followerList.total}</b>
        </IconAvatar>
        New follower {pluralize(followerList.total, 'request')}
      </Todo>
    );
  }
  // Encourage inviting existing investors and followers if none
  if (userSummary.numOfInvestors === 0 || userSummary.numOfFollowers === 0) {
    return (
      <>
        {userSummary.numOfInvestors === 0 ? (
          <>
            <Todo
              href={companyUrls.settings.inviteUser(company.slug)}
              action={<span className="text-link">Invite existing shareholders</span>}
            >
              <IconAvatar color="blue" size={{ width: '40px', height: '40px' }}>
                <RocketIcon />
              </IconAvatar>
              <div className="u-flex">
                Get started with your investment network
                <Tooltip
                  color="light"
                  className="u-half-spacing-left"
                  title={
                    <p style={{ fontWeight: '400' }} className="text-body">
                      Invite your existing shareholders and network to follow {addPossessive(company.name)} journey on
                      CrowdWorks.
                      <br />
                      By posting regular updates on your progress, you may build trust and increase the chance of
                      getting re-investments and help in the future.
                    </p>
                  }
                >
                  <div>
                    <QuestionIcon fontSize="small"></QuestionIcon>
                  </div>
                </Tooltip>
              </div>
            </Todo>
          </>
        ) : (
          <>
            <Todo
              href={companyUrls.settings.inviteUser(company.slug, 'prospective_investor')}
              action={<span className="text-link">Invite followers</span>}
            >
              <IconAvatar color="blue" size={{ width: '40px', height: '40px' }}>
                <InviteUserIcon />
              </IconAvatar>
              <div className="u-flex">
                Convert acquaintances to followers
                <Tooltip
                  className="u-half-spacing-left"
                  color="light"
                  title={
                    <p className="text-body" style={{ fontWeight: '400' }}>
                      Getting the right investors on board is a continuous process - building trust usually takes time.{' '}
                      <br />
                      Invite potential investors you meet on conferences or through your network, so that they can
                      follow your growth. In the end, they might end up as your future investors.
                    </p>
                  }
                >
                  <div>
                    <QuestionIcon fontSize="small"></QuestionIcon>
                  </div>
                </Tooltip>
              </div>
            </Todo>
          </>
        )}
      </>
    );
  }
  return null;
}
