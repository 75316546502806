import styled from '@emotion/styled';
import { alpha } from '@mui/material';
import classNames from 'classnames';
import React, { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { bluePlanetTheme } from 'ui/theme';
import { DataCell } from './DataCell';
import { TableContext } from './Table';

const StyledRow = styled.tr`
  height: 58px; // height on table elements works as min-height on normal html elements. The row will grow if the content takes up more space
  transform: scale(1); // needed to get box shadow to work on a tr element
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);

  ${(props: { hasClickableRows: boolean }) =>
    props.hasClickableRows &&
    `cursor: pointer;
    &:hover {
      > * {
        transition: background-color 200ms ease-out;
        background-color: ${alpha(bluePlanetTheme.bluePlanetPalette.white, 0.3)};
      }
  }`}
`;

export type RowContextProps = {
  href?: string;
};

type RowProps = {
  menu?: ReactNode;
} & RowContextProps &
  React.HTMLAttributes<HTMLTableRowElement>;

export const RowContext = createContext<RowContextProps | null>(null);

export const Row = ({ menu, href, children, ...rest }: RowProps) => {
  const context = useContext(TableContext);

  if (!context) {
    throw Error('Table.Row: Component must be wrapped in <Table> to access TableContext.');
  }
  const { hasMenu, hasClickableRows, columnClassNames } = context;

  if (hasMenu && !menu) {
    throw Error('Table.Row: Table.hasMenu is set to true, but popMenu is not defined in Row component.');
  }

  if (hasClickableRows && !(href || rest.onClick)) {
    throw Error('Table.Row: Table.hasClickableRows is set to true, but href is not defined in Row component.');
  }

  const memoizedChildren = useMemo(() => {
    if (columnClassNames != null) {
      return React.Children.map(children, (child: ReactElement | null, index) =>
        child != null
          ? React.cloneElement(child, {
              ...child.props,
              className: classNames(child.props.className, columnClassNames[index]),
            })
          : null,
      );
    }
    return children;
  }, [children, columnClassNames]);

  return (
    <RowContext.Provider
      value={{
        href,
      }}
    >
      <StyledRow hasClickableRows={hasClickableRows ?? false} {...rest}>
        {memoizedChildren}
        {hasMenu && (
          <DataCell isLinkDisabled={true} style={{ width: 50, textAlign: 'right' }}>
            {menu}
          </DataCell>
        )}
        {hasClickableRows && (
          <DataCell style={{ width: 50 }} role="link" tabIndex={0} data-href={href}>
            <ChevronRightIcon />
          </DataCell>
        )}
      </StyledRow>
    </RowContext.Provider>
  );
};
