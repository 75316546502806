import ApiBase from 'apis';
import config from 'config';
import { withPagination } from 'apis/utils';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { Comment, CommentId, CommunityUpdate, CompanyUpdate, ContentCreateDTO, Like } from 'types/content';
import { DocumentDownloadDTO, DocumentList } from 'types/documents';
import { ICwUserId } from 'types/user';

const api = ApiBase();

// There are two endpoints for updates, one for company updates and one for room updates
// Main reason is that they have completely different access control
// In order for both room updates and company updates to be rendered the same way, they pass in which api methods they use
export interface UpdateAPI {
  togglePin: (contentId: string, isPinned: boolean) => Promise<void>;
  delete: (contentId: string) => Promise<void>;
  markAsRead: (contentId: string) => Promise<void>;
  attachments: {
    list: (contentId: string) => Promise<DocumentList>;
    download: (updateId: string, fileId: string) => Promise<DocumentDownloadDTO>;
  };
  likes: {
    list: (contentId: string, paginationOptions?: PaginationOptions) => Promise<PaginatedResult<ICwUserId>>;
    like: (contentId: string) => Promise<void>;
    unLike: (contentId: string) => Promise<void>;
  };
  comments: {
    list: (contentId: string, paginationOptions?: PaginationOptions) => Promise<PaginatedResult<Comment>>;
    getReplies: (
      contentId: string,
      commentId: string,
      paginationOptions?: PaginationOptions,
    ) => Promise<PaginatedResult<Comment>>;
    post: (contentId: string, comment: string) => Promise<Comment>;
    replyToComment: (contentId: string, commentId: string, comment: string) => Promise<Comment>;
    delete: (contentId: string, commentId: CommentId) => Promise<void>;
  };

  archive: (contentId: string) => Promise<void>;
  unArchive: (contentId: string) => Promise<void>;
}

const baseUrl = `${config.CONTENT_API_URL}/updates`;

const singleUpdateAPI = {
  attachments: {
    list: (contentId: string) => api.get<DocumentList>(`${baseUrl}/${contentId}/attachments`),
  },
};

interface CompanyUpdateAPI extends UpdateAPI {
  get: (updateId: string) => string;
  list: (isArchived: boolean, paginationOptions: PaginationOptions) => Promise<PaginatedResult<CompanyUpdate>>;
  create: (dto: ContentCreateDTO) => Promise<CompanyUpdate>;
  editUpdate: (updateId: string, dto: ContentCreateDTO) => Promise<CompanyUpdate>;
}

export const companyUpdateAPI = (companyId: number): CompanyUpdateAPI => {
  const baseUrl = `${config.CONTENT_API_URL}/companies/${companyId}`;

  return {
    get: (updateId: string) => `${baseUrl}/updates/${updateId}`,
    list: (isArchived: boolean, paginationOptions: PaginationOptions) =>
      api.get<PaginatedResult<CompanyUpdate>>(`${baseUrl}/updates`, {
        requestParams: { ...paginationOptions, isArchived },
      }),
    create: (dto: ContentCreateDTO) => api.post<CompanyUpdate>(`${baseUrl}/updates`, dto),
    editUpdate: (updateId: UUID, dto: ContentCreateDTO) =>
      api.patch<CompanyUpdate>(`${baseUrl}/updates/${updateId}`, dto),
    togglePin: (updateId: UUID, isPinned: boolean) => api.patch<void>(`${baseUrl}/updates/${updateId}`, { isPinned }),
    delete: (updateId: UUID) => api.deleteRequest(`${baseUrl}/updates/${updateId}`),
    archive: (updateId: UUID) => api.deleteRequest<void>(`${baseUrl}/updates/${updateId}/archive`, {}),
    unArchive: (updateId: UUID) => api.patch<void>(`${baseUrl}/updates/${updateId}/unarchive`, { archived: false }),
    markAsRead: (updateId: string) => api.post<void>(`${baseUrl}/updates/${updateId}/read`, {}),
    likes: {
      list: (updateId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Like>>(withPagination(`${baseUrl}/updates/${updateId}/likes`, paginationOptions)),
      like: (updateId: string) => api.post<void>(`${baseUrl}/updates/${updateId}/likes`, {}),
      unLike: (updateId: string) => api.deleteRequest(`${baseUrl}/updates/${updateId}/likes`),
    },

    attachments: {
      download: (updateId, fileId) =>
        api.get<DocumentDownloadDTO>(`${baseUrl}/updates/${updateId}/attachments/${fileId}`),
      list: (updateId: string) => api.get<DocumentList>(`${baseUrl}/updates/${updateId}/attachments`),
    },
    comments: {
      list: (contentId: string, paginationOptions?: PaginationOptions): Promise<PaginatedResult<Comment>> =>
        api.get<PaginatedResult<Comment>>(
          withPagination(`${baseUrl}/updates/${contentId}/comments`, paginationOptions),
        ),
      getReplies: (updateId: string, commentId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Comment>>(
          withPagination(`${baseUrl}/updates/${updateId}/comments/${commentId}/replies`, paginationOptions),
        ),
      post: (contentId: string, comment: string) =>
        api.post<Comment>(`${baseUrl}/updates/${contentId}/comments`, { content: comment }),
      replyToComment: (updateId: string, commentId: string, comment: string) =>
        api.post<Comment>(`${baseUrl}/updates/${updateId}/comments`, { content: comment, responseTo: commentId }),
      delete: (updateId: string, commentId: CommentId) =>
        api.deleteRequest(`${baseUrl}/updates/${updateId}/comments/${commentId}`),
    },
  };
};

interface CommunityAPI extends UpdateAPI {
  create: (dto: ContentCreateDTO) => Promise<CommunityUpdate>;
  editUpdate: (updateId: string, dto: ContentCreateDTO) => Promise<CommunityUpdate>;
  get: (updateId: string) => string;
}

export const communityUpdateAPI = (communityId: number): CommunityAPI => {
  const baseUrl = `${config.CONTENT_API_URL}/communities/${communityId}`;

  return {
    create: (dto: ContentCreateDTO) => api.post<CommunityUpdate>(`${baseUrl}/updates`, dto),
    delete: (updateId: UUID) => api.deleteRequest(`${baseUrl}/updates/${updateId}`),
    editUpdate: (updateId: UUID, dto: ContentCreateDTO) =>
      api.patch<CommunityUpdate>(`${baseUrl}/updates/${updateId}`, dto),
    get: (updateId: string) => `${baseUrl}/updates/${updateId}`,
    togglePin: (updateId: UUID, isPinned: boolean) => api.patch<void>(`${baseUrl}/updates/${updateId}`, { isPinned }),
    archive: (updateId: UUID) => api.deleteRequest<void>(`${baseUrl}/updates/${updateId}/archive`, {}),
    unArchive: (updateId: UUID) => api.patch<void>(`${baseUrl}/updates/${updateId}/unarchive`, { archived: false }),
    markAsRead: (updateId: string) => api.post<void>(`${baseUrl}/updates/${updateId}/read`, {}),
    likes: {
      list: (updateId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Like>>(withPagination(`${baseUrl}/updates/${updateId}/likes`, paginationOptions)),
      like: (updateId: string) => api.post<void>(`${baseUrl}/updates/${updateId}/likes`, {}),
      unLike: (updateId: string) => api.deleteRequest(`${baseUrl}/updates/${updateId}/likes`),
    },
    attachments: {
      download: (updateId, fileId) =>
        api.get<DocumentDownloadDTO>(`${baseUrl}/updates/${updateId}/attachments/${fileId}`),
      list: (updateId: string) => api.get<DocumentList>(`${baseUrl}/updates/${updateId}/attachments`),
    },
    comments: {
      list: (contentId: string, paginationOptions?: PaginationOptions): Promise<PaginatedResult<Comment>> =>
        api.get<PaginatedResult<Comment>>(
          withPagination(`${baseUrl}/updates/${contentId}/comments`, paginationOptions),
        ),
      getReplies: (updateId: string, commentId: string, paginationOptions?: PaginationOptions) =>
        api.get<PaginatedResult<Comment>>(
          withPagination(`${baseUrl}/updates/${updateId}/comments/${commentId}/replies`, paginationOptions),
        ),
      post: (contentId: string, comment: string) =>
        api.post<Comment>(`${baseUrl}/updates/${contentId}/comments`, {
          content: comment,
        }),
      replyToComment: (updateId: string, commentId: string, comment: string) =>
        api.post<Comment>(`${baseUrl}/updates/${updateId}/comments`, {
          content: comment,
          responseTo: commentId,
        }),
      delete: (updateId: string, commentId: CommentId) =>
        api.deleteRequest(`${baseUrl}/updates/${updateId}/comments/${commentId}`),
    },
  };
};

export default singleUpdateAPI;
