import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useEffect, useState } from 'react';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import Pill from '../../elements/Pill';
import { bluePlanetTheme } from 'ui/theme';
import cx from 'classnames';
import styles from './accordion.scss';
import { contentSpacing } from 'ui/theme/themeConstants';
import { SxProps } from '@mui/material';

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  isExpanded?: boolean;
  fontSize?: 'default' | 'small';
  showPill?: boolean;
  padding?: 'none' | 'default' | 'header-only';
  border?: 'default' | 'bottom' | 'none';
  accordionBackgroundColor?: 'transparent';
  summaryBackgroundColor?: 'grey-light' | 'default' | 'blue-very-light';
  detailsBackgroundColor?: 'grey-light' | 'default';
  accordionDetailsSx?: SxProps;
  onChange?: (e: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => void;
}

export default function Accordion(props: Props) {
  const [expanded, setExpanded] = useState(props.isExpanded ?? false);

  useEffect(() => {
    setExpanded(props.isExpanded ?? expanded);
  }, [props.isExpanded]);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div className={props.className}>
      <MuiAccordion
        sx={{
          backgroundColor:
            props.accordionBackgroundColor === 'transparent' ? 'transparent' : bluePlanetTheme.bluePlanetPalette.white,
        }}
        elevation={0}
        expanded={expanded}
        onChange={e => (props.onChange ? props.onChange(e, expanded) : toggleExpanded())}
        TransitionProps={{ unmountOnExit: true }}
        disableGutters
      >
        <AccordionSummary
          className={cx({
            [styles.accordionSummary]: true,
            'text-small': props.fontSize === 'small',
            [styles.bottomBorder]: props.border === 'bottom',
            [styles.white]: props.summaryBackgroundColor === 'default',
            [styles.greyLight]: props.summaryBackgroundColor === 'grey-light',
            [styles.blueVeryLight]: props.summaryBackgroundColor === 'blue-very-light',
            [styles.headerPadding]: props.padding === 'header-only',
          })}
          expandIcon={<ChevronDownIcon color="indigo" fontSize="small" strokeWidth={2} />}
        >
          {props.showPill && <Pill />} {props.title}
        </AccordionSummary>
        <AccordionDetails
          className={cx({
            [styles.bottomBorder]: props.border === 'bottom',
            [styles.noBorder]: props.border === 'none',
            [styles.greyLight]:
              props.summaryBackgroundColor === 'grey-light' || props.detailsBackgroundColor === 'grey-light',
          })}
          sx={{
            ...props.accordionDetailsSx,
            padding: props.padding === 'none' || props.padding === 'header-only' ? 0 : contentSpacing,
          }}
        >
          {props.children}
        </AccordionDetails>
      </MuiAccordion>
    </div>
  );
}
