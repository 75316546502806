import React from 'react';
import styles from './styles.scss';
import cx from 'classnames';

interface Props {
  className?: string;
  children?: Children;
  icon?: JSX.Element;
  color?: 'default' | 'blue';
}

export default function InfoMessageInline(props: Props) {
  return (
    <div
      className={cx(props.className, styles.infoMessageInline, {
        [styles.colorBlue]: props.color === 'blue',
      })}
    >
      {props.icon && <div className={cx(styles.iconWrapperInline)}>{props.icon}</div>}
      <div className="u-flex-align-center">{props.children}</div>
    </div>
  );
}
