import { useFormik } from 'formik';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import CopyButton from 'ui/elements/CopyButton';
import Switch from 'ui/elements/form/choice/Switch';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import Label from 'ui/elements/form/Label';
import QuickEditField from 'ui/elements/form/QuickEditField';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { userUrls } from 'urls';
import { SHORTLIST_DESCRIPTION_MAX_LENGTH } from 'util/constants';
import * as Yup from 'yup';

interface ShortlistCreateDialogProps {
  dialogHandler: DialogHandlerProps;
  onSubmit: (listName: string, description?: string) => void;
  isSaving: boolean;
}

interface ShortlistDuplicateDialogProps {
  dialogHandler: DialogHandlerProps;
  shortlistName: string;
  description?: string;
  onSubmit: (listName: string, description?: string) => void;
  isSaving: boolean;
}

interface ShortlistShareDialogProps {
  dialogHandler: DialogHandlerProps;
  isPublic: boolean;
  onSubmit: () => void;
  isSaving: boolean;
}

interface ShortlistSettingsDialogProps {
  dialogHandler: DialogHandlerProps;
  shortlistId: string;
  shortlistName: string;
  shortlistDescription?: string;
  isPublicShortlist: boolean;
  onSubmit: (listName?: string, description?: string) => void;
  onDelete: () => void;
  onShareToggle?: () => void;
  isSaving: boolean;
}

interface ShortlistDeleteDialogProps {
  dialogHandler: DialogHandlerProps;
  title: string;
  content: JSX.Element | string;
  onConfirm: () => void;
  isSaving: boolean;
}

const shortlistSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a name for this list'),
  description: Yup.string().notRequired().max(500, 'Description cannot be longer than 500 characters'),
});

export function ShortlistCreateDialog(props: ShortlistCreateDialogProps) {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: undefined,
    },
    onSubmit: values => {
      props.onSubmit(values.name, values.description);
    },
    validationSchema: shortlistSchema,
    enableReinitialize: true,
  });

  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>Create a new list</Title>
      <Content>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="name"
            label="Name"
            placeholder="Name your collection"
            autoFocus
            onFocus={e => {
              e.target.select();
            }}
          />
        </FormRow>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="description"
            label="Description"
            multiline
            minRows={3}
            maxRows={5}
            placeholder="What is your collection about?"
          />
        </FormRow>
      </Content>
      <DialogActions>
        <Button
          onClick={() => formik.handleSubmit()}
          kind="primary"
          isLoading={props.isSaving}
          disabled={props.isSaving}
        >
          Create
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistShareDialog(props: ShortlistShareDialogProps) {
  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>{props.isPublic ? 'Set collection to private' : 'Share link'}</Title>
      <Content>
        {props.isPublic ? (
          <p>If you make this list private, it will no longer be accessible through your previously shared links.</p>
        ) : (
          <p>
            Click the button below to make the collection public and copy its link to the clipboard. Anyone with the
            link will be able to view your collection.
          </p>
        )}
      </Content>
      <DialogActions>
        <Button onClick={props.onSubmit} kind="primary" isLoading={props.isSaving} disabled={props.isSaving}>
          {props.isPublic ? 'Make private' : 'Copy link'}
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistSettingsDialog(props: ShortlistSettingsDialogProps) {
  const formik = useFormik({
    initialValues: {
      name: props.shortlistName,
      description: props.shortlistDescription,
    },
    onSubmit: values => {
      props.onSubmit(values.name, values.description);
    },
    validationSchema: shortlistSchema,
    enableReinitialize: false,
  });

  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>Collection settings</Title>
      <Content>
        <QuickEditField
          className="u-content-spacing-bottom"
          label="Name"
          name="name"
          placeholder="Name your collection"
          onChange={e => {
            formik.setFieldValue('name', e.target.value);
          }}
          onCancel={() => formik.setFieldValue('name', props.shortlistName)}
          value={formik.values.name}
          originalValue={props.shortlistName}
          onConfirm={(name: string) => props.onSubmit(name)}
          error={formik.errors.name}
        />
        <QuickEditField
          label="Description"
          name="description"
          placeholder="What is your collection about?"
          onChange={e => {
            formik.setFieldValue('description', e.target.value);
          }}
          onCancel={() => formik.setFieldValue('description', props.shortlistDescription)}
          value={formik.values.description}
          originalValue={props.shortlistDescription}
          onConfirm={(description: string) => props.onSubmit(undefined, description)}
          maxLength={SHORTLIST_DESCRIPTION_MAX_LENGTH}
          minRows={3}
          maxRows={5}
          error={formik.errors.description}
        />
        {props.onShareToggle && (
          <div className="u-flex u-flex--column">
            <Label className="u-content-spacing-top">Share settings</Label>
            <Switch
              className="u-quarter-spacing-left"
              label={props.isPublicShortlist ? 'Public' : 'Make public'}
              value={props.isPublicShortlist}
              onChange={props.onShareToggle}
            />
            {props.isPublicShortlist && (
              <ButtonList className="u-quarter-spacing-top">
                <LinkAsButton color="blue" kind="tertiary" url={`${userUrls.collections.view(props.shortlistId)}`}>
                  <EyeIcon className="u-quarter-spacing-right" /> View public page
                </LinkAsButton>
                <CopyButton
                  kind="tertiary"
                  copyString={`${location.origin}${userUrls.collections.view(props.shortlistId)}`}
                  displayString="Copy link"
                />
              </ButtonList>
            )}
          </div>
        )}
        <Button className="u-section-spacing-top" kind="tertiary" color="red" onClick={props.onDelete}>
          <TrashIcon className="u-quarter-spacing-right" />
          <span className="u-relative" style={{ top: '2px' }}>
            Delete collection
          </span>
        </Button>
      </Content>
      <DialogActions>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistDeleteDialog(props: ShortlistDeleteDialogProps) {
  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>{props.title}</Title>
      <Content>
        <p>{props.content}</p>
      </Content>
      <DialogActions>
        <Button
          onClick={props.onConfirm}
          kind="primary"
          color="red"
          isLoading={props.isSaving}
          disabled={props.isSaving}
        >
          Delete
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ShortlistDuplicateDialog(props: ShortlistDuplicateDialogProps) {
  const formik = useFormik({
    initialValues: {
      name: props.shortlistName,
      description: props.description,
    },
    onSubmit: values => {
      props.onSubmit(values.name, values.description);
    },
    validationSchema: shortlistSchema,
    enableReinitialize: true,
  });

  return (
    <Dialog onClose={props.dialogHandler.close} open={props.dialogHandler.isOpen} maxWidth="sm">
      <Title onClose={props.dialogHandler.close}>The collection is saved!</Title>
      <Content>
        <p className="text-weight-bold u-section-spacing-bottom">Add your own name and description?</p>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="name"
            label="Name"
            placeholder="Name your collection"
            autoFocus
            onFocus={e => {
              e.target.select();
            }}
          />
        </FormRow>
        <FormRow>
          <FormikTextField
            formikProps={formik}
            name="description"
            label="Description"
            multiline
            minRows={3}
            maxRows={5}
            placeholder="What is your collection about?"
          />
        </FormRow>
      </Content>
      <DialogActions>
        <Button
          onClick={() => formik.handleSubmit()}
          kind="primary"
          isLoading={props.isSaving}
          disabled={props.isSaving}
        >
          Update
        </Button>
        <Button onClick={props.dialogHandler.close} kind="tertiary">
          No, thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
}
