import { Autocomplete as MaterialAutocomplete, TextField } from '@mui/material';
import React, { CSSProperties } from 'react';
import ChevronDownIcon from 'ui/elements/icons/ChevronDownIcon';
import { bluePlanetTheme } from 'ui/theme';

interface Props<T> {
  id?: string;
  name?: string;
  placeholder?: string;
  options: T[];
  onChange: (value?: T) => void;
  onInputChange?: (newInput: string) => void;
  value?: T;
  autoComplete?: string;
  autoFocus?: boolean;
  noOptionsText?: string;
  getOptionLabel?: (option: T) => string;
  helperText?: string;
  error?: boolean;
  style?: CSSProperties;
  isClearable?: boolean;
  className?: string;
  isDisabled?: boolean;
  color?: 'white' | 'transparent';
  isOptionEqualToValue?: (option: T, value: T) => boolean;
}

const disableAutocompleteProps = {
  cursor: 'pointer',
  caretColor: 'transparent',
  '::selection': {
    color: bluePlanetTheme.bluePlanetPalette.indigo.main,
    backgroundColor: 'transparent',
  },
};
export default function Select<T>({
  value,
  name,
  id,
  placeholder,
  options,
  onChange,
  onInputChange,
  noOptionsText,
  getOptionLabel,
  error,
  helperText,
  autoComplete = 'not-active',
  autoFocus,
  style,
  className,
  isClearable = false,
  isDisabled = false,
  color = 'white',
  isOptionEqualToValue,
}: Props<T>) {
  // When there is no async search we don't need the caret
  const looksSearchable = !onInputChange;
  const colorProps =
    color === 'transparent'
      ? {
          backgroundColor: 'rgba(255,255,255,0.2)',
          color: bluePlanetTheme.bluePlanetPalette.white,
        }
      : {};
  return (
    <MaterialAutocomplete
      value={value || null}
      id={id}
      style={style}
      disabled={isDisabled}
      popupIcon={<ChevronDownIcon color={color === 'transparent' ? 'white' : 'indigo'} fontSize="small" />}
      sx={{
        '.MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 12px);',
        },
        '.MuiAutocomplete-inputFocused':
          color === 'transparent'
            ? {
                // make the input text white after a element has been selected
                color: bluePlanetTheme.bluePlanetPalette.white,
              }
            : {},
      }}
      renderInput={params => (
        <TextField
          variant="standard"
          {...params}
          name={name}
          error={error}
          placeholder={placeholder}
          className={className}
          sx={{
            '.MuiInput-root .MuiInput-input::selection':
              color === 'transparent'
                ? {
                    color: `${bluePlanetTheme.bluePlanetPalette.white} !important`, // make the input text white when the dropdown is active
                  }
                : {},
            '.MuiInput-root': {
              ...(looksSearchable ? disableAutocompleteProps : {}),
              ...colorProps,
            },
            '.MuiInput-root .MuiInput-input': {
              padding: 0,
              ...(looksSearchable ? disableAutocompleteProps : {}),
            },
          }}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          helperText={helperText}
        />
      )}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      onInputChange={
        onInputChange
          ? (_, newInputValue) => {
              onInputChange(newInputValue);
            }
          : undefined
      }
      onChange={(_, value) => value && onChange(value)}
      options={options}
      disableClearable={!isClearable}
      openOnFocus={true}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}
