import NotFound from 'pages/common/error/NotFound';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompanyListItem } from 'types/company';
import NetworkHome from './Network';
import Companies from './Companies';
import PageTitle from 'ui/views/layouts/BaseLayout/PageTitle';
import { sortDates } from 'util/dateUtils';
import Communities from './Communities';
import { MyCommunity } from 'types/company/community';
import { scrollToTop } from 'util/scroll';

interface Props {
  companies: CompanyListItem[];
  communities: MyCommunity[];
}

export default function Network({ companies, communities }: Props) {
  const myCompanies = [...companies].sort((a, b) => sortDates(b.lastUpdated, a.lastUpdated));

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <PageTitle title="Network"></PageTitle>
      <Switch>
        <Route exact path="/network" render={() => <NetworkHome communities={communities} companies={myCompanies} />} />
        <Route exact path="/network/companies" render={() => <Companies companies={myCompanies} />} />
        <Route exact path="/network/communities" render={() => <Communities communities={communities} />} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}
