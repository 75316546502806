import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useSentFollowerRequests } from 'apis/CompanyAPI/users/useSentFollowerRequests';
import useJourneyLogger from 'hooks/useJourneyLogger';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { ICompany } from 'types/company';
import { getOrUndefined } from 'util/resource';
import useCompanyRole from 'hooks/useCompanyRole';
import useLocalStorage from 'hooks/useLocalStorage';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import CompanyAPI from 'apis/CompanyAPI';
import { invalidate } from 'hooks/useSWR';
import { companyProfileByIdKey, companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { companyAccessKey } from 'apis/CompanyAPI/companies/useCompanyAccess';

export default function useFollowCompany(company: ICompany) {
  const [log] = useJourneyLogger();
  const notify = useNotify();
  const match = useRouteMatch<{ communitySlug?: string }>();

  const sentFollowerRequests = useSentFollowerRequests();

  const companies = useCompanies();

  const { roles, isFetched: isCompanyRolesFetched } = useCompanyRole(company.slug);

  const [pendingStateIsDisabled, setPendingStateIsDisabled] = useState(false);

  const isPendingFollowerRequest = !!getOrUndefined(sentFollowerRequests.resource)?.values.find(
    fr => fr.company.id === company.id && fr.request.status === 'pending',
  );

  const isFollowingCompany = roles.includes('prospective_investor');
  const hasOtherRoleThanFollower = roles.some(r => r !== 'prospective_investor');

  const [onFollow, isSendingFollowRequest] = useLazyResource(
    async () => {
      log(`sent follow request to company ${company.id}`);
      const context = {
        path: match.path,
        communitySlug: match.params.communitySlug,
      };
      await CompanyAPI.follower.user.requestFollow(company.id, '', context);
    },
    {
      onSuccess() {
        sentFollowerRequests.mutate();
        companies.mutate();
        invalidate(companyAccessKey(company.slug));
        invalidate(companyProfileKey(company.slug));
      },
      onFailure: error => notify('error', error || 'Could not follow company'),
    },
  );

  // Automatically send a follow request to the company if
  // the user has visited the company on an internet public page and clicked “follow” before the user was logged in, this flag is set in localstorage.
  // We use it to make sure that the follow request is sent when they have registered or logged in
  const [shouldAutoFollowCompany, , removeAutoFollowCompany] = useLocalStorage<string | undefined>(
    'auto-follow-company',
    undefined,
  );
  const shouldAutoFollowAndDoesntHaveARoleWithTheCompanyFromBeforeAndDoesntHaveAPendingFollowRequest =
    shouldAutoFollowCompany === company.slug &&
    !isSendingFollowRequest &&
    isCompanyRolesFetched &&
    sentFollowerRequests.resource.state === 'fetched' &&
    !isPendingFollowerRequest &&
    !isFollowingCompany &&
    !hasOtherRoleThanFollower;

  const userRequestedToAutoFollowCompanyAndNeccecaryStateHasBeenFetched =
    shouldAutoFollowCompany === company.slug &&
    isCompanyRolesFetched &&
    sentFollowerRequests.resource.state === 'fetched';

  useEffect(() => {
    if (userRequestedToAutoFollowCompanyAndNeccecaryStateHasBeenFetched) {
      removeAutoFollowCompany();
    }
    if (shouldAutoFollowAndDoesntHaveARoleWithTheCompanyFromBeforeAndDoesntHaveAPendingFollowRequest) {
      onFollow(company);
    }
  }, [shouldAutoFollowCompany, isCompanyRolesFetched, sentFollowerRequests.resource.state, isSendingFollowRequest]);

  const [onUnfollow, isUnfollowing] = useLazyResource(
    async () => {
      log(`unfollowed company ${company.id}`);
      await CompanyAPI.follower.user.unfollow(company.id);
    },
    {
      onSuccess: () => {
        sentFollowerRequests.mutate();
        companies.mutate();
        invalidate(companyProfileKey(company.slug));
        invalidate(companyAccessKey(company.slug));
        invalidate(companyProfileByIdKey(company.id));
      },
      onFailure: error => notify('error', error || 'Something went wrong when trying to unfollow'),
    },
  );

  return {
    onFollow: () => onFollow(company),
    onUnfollow: () => onUnfollow(company),
    isUnfollowing,
    isCompanyRolesFetched,
    isFollowingCompany,
    isSendingFollowRequest,
    isPendingFollowerRequest,
    hasOtherRoleThanFollower,
    pendingStateIsDisabled,
    setPendingStateIsDisabled,
  };
}
