import useLocalStorage from 'hooks/useLocalStorage';
import React, { useState } from 'react';
import AnchorButton from 'ui/elements/buttons/AnchorButton';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';

export default function useDownloadWarning(fileName: string, onDownload?: () => any) {
  const [warningDialogIsOpen, setWarningDialogIsOpen] = useState(false);
  const [hasOptedOutOfDownloadWarning, setHasOptedOutOfDownloadWarning] = useLocalStorage(
    `has-opted-out-of-file-download-warning`,
    false,
  );
  const [checkboxState, setCheckboxState] = useState(hasOptedOutOfDownloadWarning);

  const onClick = onDownload
    ? () => {
        if (hasOptedOutOfDownloadWarning || warningDialogIsOpen) {
          onDownload();
          setWarningDialogIsOpen(false);
          setHasOptedOutOfDownloadWarning(checkboxState);
        } else {
          setWarningDialogIsOpen(true);
        }
      }
    : undefined;

  const WarningDialog = (
    <Dialog open={warningDialogIsOpen} onClose={() => setWarningDialogIsOpen(false)}>
      <Title onClose={() => setWarningDialogIsOpen(false)}>Downloading file</Title>
      <>
        <Content>
          <p className="text-body u-section-spacing-bottom">
            You are downloading a user-uploaded file from CrowdWorks: <b>{fileName}</b>
          </p>
          <AnchorButton onClick={onClick} kind="secondary" size="large">
            Download file
          </AnchorButton>
        </Content>
        <DialogActions>
          <div>
            <p className="text-body u-content-spacing-bottom">
              Be sure you only download files from sources you trust.
            </p>
            <Checkbox
              checked={checkboxState}
              onChange={() => setCheckboxState(!checkboxState)}
              color="primary"
              label="Don't show this again"
            />
          </div>
        </DialogActions>
      </>
    </Dialog>
  );

  return { onClick, WarningDialog };
}
