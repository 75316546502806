import { useFormik } from 'formik';
import React, { useRef } from 'react';
import { ExecutiveSummaryDTO, ICompany } from 'types/company/company';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import Dialog, { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import * as Yup from 'yup';
import useSaveExecutiveSummary from 'domain/companies/ExecutiveSummary/useSaveExecutiveSummary';
import AiMenu from 'ui/domain/AiMenu/AiMenu';

interface Props {
  company: ICompany;
  executiveSummary: ExecutiveSummaryDTO;
  focusableField: 'problem' | 'solution';
  onClose: () => void;
  onSuccess?: () => void;
}
export default function ProblemSolutionDialog({
  company,
  executiveSummary,
  onSuccess,
  onClose,
  focusableField,
}: Props) {
  const [onSave, isSaving] = useSaveExecutiveSummary(company, {
    onComplete: () => {
      onSuccess && onSuccess();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      problem: executiveSummary.problem,
      solution: executiveSummary.solution,
    },
    onSubmit: values => {
      onSave(values);
    },
    validationSchema: Yup.object().shape({
      problem: Yup.string().trim().notRequired().max(2000, 'Problem should be at most 2000 characters long'),
      solution: Yup.string().trim().notRequired().max(2000, 'Solution should be at most 2000 characters long'),
    }),
  });
  const aiRef = useRef<HTMLDivElement>(null);
  return (
    <Dialog onClose={onClose} open maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <Content paddingY="top">
          <FormikTextField
            autoFocus={focusableField === 'problem'}
            formikProps={formik}
            name="problem"
            label="Problem"
            multiline
            minRows={4}
            maxRows={20}
            inputProps={{ maxLength: 725 }}
            suffixPosition="start-bottom"
            suffix={
              <AiMenu
                companyId={company.id}
                input={formik.values.problem || ''}
                maxLength={700}
                onReplace={value => {
                  formik.setFieldValue('problem', value);
                }}
                aiRef={aiRef}
              />
            }
          />
          <FormikTextField
            autoFocus={focusableField === 'solution'}
            formikProps={formik}
            name="solution"
            label="Solution"
            multiline
            minRows={4}
            maxRows={20}
            inputProps={{ maxLength: 725 }}
            suffixPosition="start-bottom"
            suffix={
              <AiMenu
                companyId={company.id}
                input={formik.values.solution || ''}
                maxLength={700}
                onReplace={value => {
                  formik.setFieldValue('solution', value);
                }}
                aiRef={aiRef}
              />
            }
          />
        </Content>
        <div ref={aiRef} />

        <DialogActions>
          <Button kind="primary" onClick={() => formik.handleSubmit()} isLoading={isSaving}>
            Update
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
