import MUIChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import cx from 'classnames';
import React from 'react';
import { Role } from 'types/company';
import { bluePlanetTheme } from 'ui/theme';
import { roleDisplayName } from 'domain/companies/roleUtils';

interface Props {
  role: Role | 'pending_access';
  className?: string;
  ref?: MuiChipProps['ref'];
  title?: MuiChipProps['title'];
  onClick?: MuiChipProps['onClick'];
}

function getColor(props: Props) {
  const palette = bluePlanetTheme.bluePlanetPalette;
  switch (props.role) {
    case 'board':
      return { backgroundColor: palette.purple.light, color: palette.indigo.main };
    case 'investor':
      return { backgroundColor: palette.blue.veryLight, color: palette.indigo.main };
    case 'mentor':
      return { backgroundColor: palette.green.medium, color: palette.indigo.main || '' };
    case 'company_master':
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
    case 'company_admin_in_community':
      return { backgroundColor: palette.red.medium, color: palette.indigo.main };
    case 'pending_access':
      return { backgroundColor: palette.yellow.medium, color: palette.indigo.main };
    case 'prospective_investor':
      return { backgroundColor: palette.orange.medium, color: palette.indigo.main };
    default:
      return { backgroundColor: palette.grey.medium, color: palette.indigo.main };
  }
}

const RoleLabel = (props: Props) => {
  return (
    <MUIChip
      sx={{
        ...getColor(props),
        padding: 0,
        '.MuiChip-label': {
          padding: '0 0.40625rem',
        },
      }}
      className={cx(props.className)}
      label={props.role === 'pending_access' ? 'Pending approval' : roleDisplayName(props.role)}
      onClick={props.onClick}
      ref={props.ref}
    />
  );
};

export function CompanyRoles({ roles }: { roles: Role[] }) {
  return (
    <div className="u-flex u-flex--gap-quarter">
      {roles.length === 0 ? (
        <RoleLabel role={'pending_access'} />
      ) : (
        roles.map(role => <RoleLabel key={`community-${role}`} role={role} />)
      )}
    </div>
  );
}
