import EditIcon from 'ui/elements/icons/EditIcon';
import React, { useState } from 'react';
import { Update } from 'types/content';
import Chip from 'ui/elements/Chip';
import ArchiveIcon from 'ui/elements/icons/ArchiveIcon';
import UnarchiveIcon from 'ui/elements/icons/UnarchiveIcon';
import PinIcon from 'ui/elements/icons/PinIcon';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import useDialogHandler, { DialogHandlerProps } from 'hooks/useDialogHandler';
import Button from 'ui/elements/buttons/Button';
import { ICompany } from 'types/company';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import IconButton from 'ui/elements/icons/IconButton';
import FollowMenuItem from 'domain/companies/Follow/FollowMenuItem';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import useRoute from 'hooks/useRoute';
import useFollowCompany from 'domain/companies/Follow/useFollowCompany';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import Resource from 'util/resource/Resource';
import UserIcon from 'ui/elements/icons/UserIcon';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import useBackoffice from 'hooks/useBackoffice';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import { bluePlanetTheme } from 'ui/theme';
import UpdateStatsDialog from './UpdateStatsDialog';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import { Auth0User } from 'types';
import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { IResource } from 'util/resource';

function UpdateStatsMenuItem({ handleClose, updateId }: { updateId: string; handleClose: () => void }) {
  const [updateStatsDialogIsOpen, setUpdateStatsDialogIsOpen] = useState(false);
  const onClose = () => {
    handleClose();
    setUpdateStatsDialogIsOpen(false);
  };

  const backoffice = useBackoffice();
  return (
    <>
      {backoffice.adminRights.includes('read-everything') && backoffice.showBackofficeInfoOnProfiles ? (
        <MenuItem
          onClick={() => setUpdateStatsDialogIsOpen(true)}
          sx={{ backgroundColor: bluePlanetTheme.bluePlanetPalette.yellow.medium }}
        >
          <ListItemIcon>
            <GraphIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>View stats</ListItemText>
        </MenuItem>
      ) : null}
      {updateStatsDialogIsOpen && <UpdateStatsDialog updateId={updateId} onClose={onClose} />}
    </>
  );
}

interface ContainerProps {
  update: Update;
  companyId: number;
  company?: ICompany;
  companyHref?: string;
  api: UpdateAPI;
  authorId?: string;
  onEdit?: () => void;
  canEditPost?: boolean;
  onArchive?: (contentId: string) => void;
  onDelete?: () => void;
  setPinned: (isPinned: boolean) => void;
  isPinned: boolean;
  canPinToFeed?: boolean;
  trackingKey?: string;
}

interface ChildProps extends ContainerProps {
  archived: boolean;
  onArchive: () => Promise<IResource<void>>;
  onDelete?: () => Promise<void>;
  isDeleting?: boolean;
  pinDialogHandler: DialogHandlerProps;
  deleteDialogHandler: DialogHandlerProps;
  togglePin: () => void;
}

export default function ContentHeadingActionsContainer(props: ContainerProps) {
  const { update: content, isPinned, setPinned } = props;
  const [isDeleting, setDeleting] = useState(false);

  const [archived, setArchived] = useState<boolean>(content.archived || false);
  const notify = useNotify();

  const pinDialogHandler = useDialogHandler();
  const deleteDialogHandler = useDialogHandler();

  const [togglePin] = useLazyResource((isPinned: boolean) => props.api.togglePin(props.update.id, isPinned), {
    onSuccess: () => {
      setPinned(!isPinned);
      notify('success', `Update has been ${isPinned ? 'pinned' : 'unpinned'} `);
    },
    onFailure: () => notify('error', `Could not ${isPinned ? 'pin' : 'unpin'} update. Please try again later.`),
  });
  const [onArchive] = useLazyResource(
    (isArchived: boolean) => (!isArchived ? props.api.archive(props.update.id) : props.api.unArchive(props.update.id)),
    {
      onSuccess: () => {
        setArchived(!archived);
        notify('success', `Update has been ${archived ? 'un' : ''}archived`);
      },
      onFailure: () => notify('error', `Could not ${archived ? 'un' : ''}archive update. Please try again later.`),
    },
  );

  const onDeleteContent = async () => {
    try {
      setDeleting(true);
      await props.api.delete(props.update.id);
      props.onDelete && props.onDelete();
      notify('success', 'Update deleted');
    } catch {
      setDeleting(false);
      notify('error', 'Failed to delete update');
    } finally {
      setDeleting(false);
      deleteDialogHandler.close();
    }
  };

  if (props.company) {
    return (
      <CompanyContentHeadingActions
        {...props}
        company={props.company}
        archived={archived}
        onArchive={() => onArchive(archived)}
        onDelete={props.onDelete ? onDeleteContent : undefined}
        isDeleting={isDeleting}
        pinDialogHandler={pinDialogHandler}
        deleteDialogHandler={deleteDialogHandler}
        togglePin={() => togglePin(!isPinned)}
        canEditPost={props.canEditPost}
      />
    );
  } else {
    return (
      <ContentHeadingActions
        {...props}
        archived={archived}
        onArchive={() => onArchive(archived)}
        onDelete={props.onDelete ? onDeleteContent : undefined}
        isDeleting={isDeleting}
        pinDialogHandler={pinDialogHandler}
        deleteDialogHandler={deleteDialogHandler}
        togglePin={() => togglePin(!isPinned)}
      />
    );
  }
}

function CompanyContentHeadingActions(props: ChildProps & { company: ICompany }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  const {
    onFollow,
    onUnfollow,
    isUnfollowing,
    isCompanyRolesFetched,
    isFollowingCompany,
    isPendingFollowerRequest,
    isSendingFollowRequest,
    hasOtherRoleThanFollower,
    pendingStateIsDisabled,
    setPendingStateIsDisabled,
  } = useFollowCompany(props.company);

  const communities = useCommunities();

  const { push } = useRoute();

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  return isCompanyRolesFetched ? (
    <>
      <Resource resource={communities.resource} renderError={'Nothing'} renderLoading={'Nothing'}>
        {communities => {
          /*
            - We can only follow companies at the moment, not communities.
            - We can't simply check if company.isCommunity because we don't have a list of every company 
              you may see posts from. And content APIs don't give us information that lets us differentiate 
              between companies and communities either.
            - We DO have a list of every community you have access to!
            So in order to make sure it's actually a company you try to follow, we can simply check
            if the companyId is NOT in the list of communities. If it isn't, then it must be a company
            you have direct (through role) or indirect (through a community) access to.
          */
          const shouldRenderFollowMenuItem =
            !communities.values.find(c => c.id === props.company.id) && !hasOtherRoleThanFollower;

          const shouldDisplayActions =
            shouldRenderFollowMenuItem || props.companyHref || props.authorId || props.canEditPost;

          return shouldDisplayActions ? (
            <>
              {props.archived && <Chip color="orange-light" label="Archived" className="u-quarter-spacing-left" />}
              <IconButton color="indigo" onClick={handleOpen}>
                <EllipsisVertical />
              </IconButton>
              <Menu
                disableAutoFocusItem // needed to make the MUI menu keyboard navigation work
                MenuListProps={{ style: { minWidth: '210px' } }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                {shouldRenderFollowMenuItem && (
                  <FollowMenuItem
                    company={props.company}
                    trackingKey={props.trackingKey}
                    isFollowingCompany={isFollowingCompany}
                    isUnfollowing={isUnfollowing}
                    isPendingFollowerRequest={isPendingFollowerRequest}
                    isSendingFollowRequest={isSendingFollowRequest}
                    onFollow={onFollow}
                    onUnfollow={onUnfollow}
                    pendingStateIsDisabled={pendingStateIsDisabled}
                    setPendingStateIsDisabled={setPendingStateIsDisabled}
                  />
                )}
                {props.companyHref && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      push(props.companyHref!);
                    }}
                  >
                    <ListItemIcon>
                      <EyeIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>View company profile</ListItemText>
                  </MenuItem>
                )}
                {props.authorId && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setUserDialogIsOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <UserIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>View author profile</ListItemText>
                  </MenuItem>
                )}
                {props.canEditPost && props.onEdit && (
                  <MenuItem
                    onClick={() => {
                      if (props.onEdit) props.onEdit();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                )}
                {props.canEditPost && props.canPinToFeed && (
                  <MenuItem onClick={props.pinDialogHandler.open}>
                    <ListItemIcon>
                      <PinIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>{props.isPinned ? 'Unpin' : 'Pin to top'}</ListItemText>
                  </MenuItem>
                )}
                {props.canEditPost && (
                  <MenuItem onClick={props.onArchive}>
                    <ListItemIcon>
                      {props.archived ? <UnarchiveIcon fontSize="medium" /> : <ArchiveIcon fontSize="medium" />}
                    </ListItemIcon>
                    <ListItemText>{props.archived ? 'Unarchive' : 'Archive'}</ListItemText>
                  </MenuItem>
                )}
                {props.onDelete && canAccessBackoffice && (
                  <MenuItem onClick={props.deleteDialogHandler.open}>
                    <ListItemIcon>
                      <TrashIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                )}
                <UpdateStatsMenuItem updateId={props.update.id} handleClose={handleClose} />
              </Menu>
              <ConfirmDialog
                isOpen={props.pinDialogHandler.isOpen}
                onClose={props.pinDialogHandler.close}
                title={props.isPinned ? 'Unpin update' : 'Pin to top'}
                confirmButton={
                  <Button kind="primary" onClick={props.togglePin}>
                    {props.isPinned ? 'Unpin' : 'Pin'}
                  </Button>
                }
              >
                {props.isPinned
                  ? 'The update will no longer appear at the top of the feed.'
                  : 'The update will appear at the top of the feed. You can unpin it whenever you like.'}
              </ConfirmDialog>
              {userDialogIsOpen && props.authorId && (
                <UserProfileDialog cwUserId={props.authorId} onClose={() => setUserDialogIsOpen(false)} />
              )}
              <ConfirmDialog
                isOpen={props.deleteDialogHandler.isOpen}
                onClose={props.deleteDialogHandler.close}
                title="You are about to delete this post"
                confirmButton={
                  <Button color="red" kind="primary" onClick={props.onDelete} isLoading={props.isDeleting}>
                    Yes, delete
                  </Button>
                }
                dismissLabel="No, cancel"
              >
                <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
                <p>Are you sure?</p>
              </ConfirmDialog>
            </>
          ) : null;
        }}
      </Resource>
    </>
  ) : null;
}

function ContentHeadingActions(props: ChildProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const shouldDisplayActions = props.authorId || props.canEditPost;

  return shouldDisplayActions ? (
    <>
      {props.archived && <Chip color="orange-light" label="Archived" className="u-quarter-spacing-left" />}
      <IconButton color="indigo" onClick={handleOpen}>
        <EllipsisVertical />
      </IconButton>
      <Menu
        disableAutoFocusItem // needed to make the MUI menu keyboard navigation work
        MenuListProps={{ style: { minWidth: '210px' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {props.authorId && (
          <MenuItem
            onClick={() => {
              setUserDialogIsOpen(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <UserIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>View author profile</ListItemText>
          </MenuItem>
        )}
        {props.canEditPost && props.onEdit && (
          <MenuItem
            onClick={() => {
              handleClose();
              if (props.onEdit) props.onEdit();
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {props.canEditPost && props.canPinToFeed && (
          <MenuItem onClick={props.pinDialogHandler.open}>
            <ListItemIcon>
              <PinIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{props.isPinned ? 'Unpin' : 'Pin to top'}</ListItemText>
          </MenuItem>
        )}
        {props.canEditPost && (
          <MenuItem onClick={props.onArchive}>
            <ListItemIcon>
              {props.archived ? <UnarchiveIcon fontSize="medium" /> : <ArchiveIcon fontSize="medium" />}
            </ListItemIcon>
            <ListItemText>{props.archived ? 'Unarchive' : 'Archive'}</ListItemText>
          </MenuItem>
        )}
        {props.onDelete && canAccessBackoffice && (
          <MenuItem onClick={props.deleteDialogHandler.open}>
            <ListItemIcon>
              <TrashIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
        <UpdateStatsMenuItem updateId={props.update.id} handleClose={handleClose} />
      </Menu>
      <ConfirmDialog
        isOpen={props.pinDialogHandler.isOpen}
        onClose={props.pinDialogHandler.close}
        title={props.isPinned ? 'Unpin update' : 'Pin to top'}
        confirmButton={
          <Button kind="primary" onClick={props.togglePin}>
            {props.isPinned ? 'Unpin' : 'Pin'}
          </Button>
        }
      >
        {props.isPinned
          ? 'The update will no longer appear at the top of the feed.'
          : 'The update will appear at the top of the feed. You can unpin it whenever you like.'}
      </ConfirmDialog>
      {userDialogIsOpen && props.authorId && (
        <UserProfileDialog cwUserId={props.authorId} onClose={() => setUserDialogIsOpen(false)} />
      )}
      <ConfirmDialog
        isOpen={props.deleteDialogHandler.isOpen}
        onClose={props.deleteDialogHandler.close}
        title="You are about to delete this post"
        confirmButton={
          <Button color="red" kind="primary" onClick={props.onDelete} isLoading={props.isDeleting}>
            Yes, delete
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
        <p>Are you sure?</p>
      </ConfirmDialog>
    </>
  ) : null;
}
