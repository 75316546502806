import styled from '@emotion/styled';
import React, { CSSProperties, ReactNode } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import Card from './Card';

interface Props {
  children: ReactNode;
  backgroundImage?: string;
  hideImage?: boolean;
  backgroundColor?: string;
  color?: string;
  style?: CSSProperties;
  className?: string;
  backgroundGradient?: string;
  padding?: 'default' | 'half' | 'double' | 'none';
}

const StyledCard = styled(Card)`
  background-position: right center;
  background-repeat: no-repeat;
`;

export default function ImageCard({
  backgroundColor = bluePlanetTheme.bluePlanetPalette.indigo.dark,
  color = bluePlanetTheme.bluePlanetPalette.white,
  backgroundImage,
  children,
  style,
  backgroundGradient,
  hideImage = false,
  ...rest
}: Props) {
  return (
    <StyledCard
      style={{
        backgroundColor,
        color,
        backgroundImage: hideImage
          ? ''
          : [backgroundImage && `url(${backgroundImage})`, backgroundGradient].filter(Boolean).join(','),
        ...style,
      }}
      {...rest}
    >
      {children}
    </StyledCard>
  );
}
