import { getUserProfiles } from 'actions/userProfiles';
import SeeMore from 'ui/modules/Pagination/SeeMore';
import uniq from 'ramda/src/uniq';
import chain from 'ramda/src/chain';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { PaginatedResult } from 'types';
import { PaginationOptions } from 'types/api';
import { Comment, CommentId } from 'types/content';
import { addItem, addPage, updateInList } from 'util/paginationUtils';
import usePaginatedResourceLegacy from 'util/resource/usePaginatedResourceLegacy';
import LoadableResource from 'util/resource/Resource';
import Card from 'ui/views/cards/Card';
import Button from 'ui/elements/buttons/Button';
import useNotify from 'hooks/useNotify';
import Loading from 'ui/elements/Loading';
import CommentField, { UpdateContext } from './CommentField';
import CommentContainer from './CommentContainer';
import { PostConversationMessage } from 'apis/ContentAPI/conversationTypes';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';

interface DispatchProps {
  getUserProfiles: typeof getUserProfiles;
}

interface Props {
  getComments: UpdateAPI['comments']['list'];
  postComment: UpdateAPI['comments']['post'];
  replyToComment: UpdateAPI['comments']['replyToComment'];
  getReplies: UpdateAPI['comments']['getReplies'];
  deleteComment: UpdateAPI['comments']['delete'];
  updateId: string;
  autoFocus: boolean;
  commentsCount: number;
  context: UpdateContext;
}

export type OnCommentDeleted = (commentId: CommentId, responseTo?: string) => Promise<void>;

const markAsDeleted = (comment: Comment) => ({
  ...comment,
  deleted: true,
  content: 'Comment has been removed',
});

const mapReplies = (comment: Comment) => ({ replyTo: comment.id, replies: { ...comment.replies, page: 0 } });
const compareComment = (commentId: string) => (c: Comment) => c.id === commentId;

const fetchUserProfiles = async (_getUserProfiles: typeof getUserProfiles, comments: PaginatedResult<Comment>) => {
  const allComments = chain(
    (u: Comment[]) => u,
    comments.values.map(c => [c, ...c.replies.values]),
  );
  const uniqueCreators = uniq(allComments.map(u => u.creatorCwUserId));
  await _getUserProfiles(uniqueCreators);
};

const PAGE_SIZE = 10;

function CommentsContainer(props: DispatchProps & Props) {
  const notify = useNotify();

  const fetchComments = async (updateId: string, paginationOptions?: PaginationOptions) => {
    const commentsResp = await props.getComments(updateId, paginationOptions || { limit: PAGE_SIZE });
    fetchUserProfiles(props.getUserProfiles, commentsResp);

    return commentsResp;
  };

  const [replies, setReplies] = useState<{ replyTo: string; replies: PaginatedResult<Comment> }[]>([]);

  const [commentsResource, setCommentsResource, , seeMore] = usePaginatedResourceLegacy(
    async paginationOptions => {
      const r = await fetchComments(props.updateId, paginationOptions);
      setReplies([...replies, ...r.values.map(mapReplies)]);

      return r;
    },
    [props.updateId],
  );

  const onSeeMoreReplies = async (commentId: string, paginationOptions: PaginationOptions) => {
    const replies = await props.getReplies(props.updateId, commentId, paginationOptions);

    fetchUserProfiles(props.getUserProfiles, replies);
    setReplies(prevState =>
      prevState.map(reply => {
        if (reply.replyTo === commentId) {
          return { replyTo: commentId, replies: addPage(reply.replies, replies, (c: Comment) => c.id) };
        }
        return reply;
      }),
    );
  };

  const onReplyComment = async (commentId: string, comment: string) => {
    try {
      const response = await props.replyToComment(props.updateId, commentId, comment);
      const something = !replies.find(reply => reply.replyTo === commentId)
        ? [
            ...replies,
            {
              replyTo: commentId,
              replies: { page: 1, pageSize: 1, total: 1, limit: PAGE_SIZE, values: [response], pages: 1 },
            },
          ]
        : replies.map(reply =>
            reply.replyTo === commentId ? { ...reply, replies: addItem(reply.replies, response, 'after') } : reply,
          );
      setReplies(something);
      notify('success', 'Your reply has been posted');
    } catch (e) {
      notify('error', 'Could not save comment. Please try again later.');
    }
  };

  const onPostComment = async (comment: PostConversationMessage) => {
    try {
      const response = await props.postComment(props.updateId, comment.content);
      if (commentsResource.state === 'fetched') {
        setCommentsResource(prev => addItem(prev, response));
        notify('success', 'Your comment has been posted');
      }
    } catch (e) {
      notify('error', 'Could not save comment. Please try again later.');
    }
  };

  const onCommentDeleted = async (commentId: CommentId, responseTo?: string) => {
    try {
      await props.deleteComment(props.updateId, commentId);

      const updatedReplies = replies.map(reply =>
        reply.replyTo === responseTo
          ? { ...reply, replies: updateInList(reply.replies, compareComment(commentId), markAsDeleted) }
          : reply,
      );

      setCommentsResource(prev => (!responseTo ? updateInList(prev, compareComment(commentId), markAsDeleted) : prev));
      setReplies(updatedReplies);
      notify('success', 'Comment has been removed');
    } catch (error) {
      notify('error', 'Sorry, we were not able to remove this comment at the moment. Please try again later.');
    }
  };

  return (
    <Card>
      <CommentField
        updateId={props.updateId}
        onPost={onPostComment}
        context={props.context}
        autoFocus={props.autoFocus}
      />
      <LoadableResource
        resource={commentsResource}
        renderLoading={
          props.commentsCount > 0
            ? () => (
                <div className="u-flex-center u-section-spacing-top">
                  <Loading size={25} />
                </div>
              )
            : 'Nothing'
        }
      >
        {comments => (
          <>
            {comments.values
              .filter(c => !c.responseTo)
              .map(comment => {
                const currentReplies = replies.find(reply => reply.replyTo === comment.id);
                return (
                  <CommentContainer
                    key={comment.id}
                    comment={comment}
                    onReply={(commentId: string, comment: string) => onReplyComment(commentId, comment)}
                    updateId={props.updateId}
                    context={props.context}
                    onDeleteComment={onCommentDeleted}
                    replies={currentReplies && currentReplies.replies}
                    onSeeMoreReplies={(paginationOptions: PaginationOptions) =>
                      onSeeMoreReplies(comment.id, paginationOptions)
                    }
                  />
                );
              })}
            <SeeMore
              render={fetch => (
                <Button onClick={fetch} kind="tertiary">
                  See more
                </Button>
              )}
              className="u-content-spacing-y"
              resource={comments}
              loadResource={seeMore}
              limit={PAGE_SIZE}
            />
          </>
        )}
      </LoadableResource>
    </Card>
  );
}

export default connect<void, DispatchProps, Props>(undefined, { getUserProfiles })(CommentsContainer);
