import 'styles/global/overrides/quill-richtext.scss';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import styles from './styles.scss';
import { isInternalUrl } from 'util/stringUtils';
import useLocalStorage from 'hooks/useLocalStorage';
import RedirectDialog from 'ui/modules/dialogs/RedirectInterceptDialog';
import useClickableMentions from '../form/RichTextEditor/useClickableMentions';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import PublicProfileDialog from 'domain/companies/ProfileDialog/ProfileDialog';

interface Props {
  className?: string;
  style?: object;
  children: (isExpanded: boolean) => string;
  onClick?: () => void;
  initiallyExpanded?: boolean;
}

export default function RichText({ className, style, children, onClick, initiallyExpanded }: Props) {
  const { host } = window.location;

  const [isExpanded, setIsExpanded] = useState(initiallyExpanded ?? false);
  useEffect(() => {
    if (!isExpanded && initiallyExpanded) {
      setIsExpanded(true);
    }
  }, [initiallyExpanded]);
  const [externalRedirectHref, setExternalRedirectHref] = useState<string | undefined>();
  const [hasOptedOutOfExternalLinkInterception, setHasOptedOutOfExternalLinkInterception] = useLocalStorage(
    `has-opted-out-of-external-link-interception`,
    false,
  );
  const [checkboxState, setCheckboxState] = useState(hasOptedOutOfExternalLinkInterception);

  const contentRef = useRef<HTMLParagraphElement>(null);

  const { userProfileIdToShow, setUserProfileIdToShow, companyProfileIdToShow, setCompanyProfileIdToShow } =
    useClickableMentions(contentRef);

  return (
    <>
      <div
        ref={contentRef}
        onClick={e => {
          setIsExpanded(true);
          onClick && onClick();
          if (e.target instanceof HTMLAnchorElement) {
            const href = e.target.getAttribute('href');
            if (href && !isInternalUrl(host, href) && !hasOptedOutOfExternalLinkInterception) {
              e.preventDefault();
              setExternalRedirectHref(href);
            }
          }
        }}
        style={style}
        className={cx(className, styles.richText, 'ql-custom-richtext')}
        dangerouslySetInnerHTML={{
          __html: children(isExpanded),
        }}
      ></div>

      {externalRedirectHref && (
        <RedirectDialog
          externalRedirectHref={externalRedirectHref}
          setExternalRedirectHref={setExternalRedirectHref}
          checkboxState={checkboxState}
          setCheckboxState={setCheckboxState}
          setHasOptedOutOfExternalLinkInterception={setHasOptedOutOfExternalLinkInterception}
        />
      )}
      {userProfileIdToShow && (
        <UserProfileDialog cwUserId={userProfileIdToShow} onClose={() => setUserProfileIdToShow(undefined)} />
      )}
      {companyProfileIdToShow && (
        <PublicProfileDialog companyId={companyProfileIdToShow} onClose={() => setCompanyProfileIdToShow(undefined)} />
      )}
    </>
  );
}
