import when from 'ramda/src/when';
import uniqBy from 'ramda/src/uniqBy';
import { PaginatedResult, PaginationOptions } from 'types/api';

export function emptyPaginatedResult<T>(paginationOptions?: PaginationOptions): PaginatedResult<T> {
  return {
    values: [],
    page: paginationOptions?.page ?? 1,
    pages: 1,
    limit: paginationOptions?.limit ?? 20,
    pageSize: 0,
    total: 0,
  };
}

export function paginationResultFrom<T>(values: T[], page = 1, limit?: number): PaginatedResult<T> {
  const startIndex = limit ? (page - 1) * limit : 0;
  return {
    values: limit ? values.slice(startIndex, startIndex + limit) : values,
    page: page,
    limit: limit ?? values.length,
    pageSize: limit == null || limit > values.length ? values.length : limit,
    total: values.length,
    pages: limit ? Math.ceil(values.length / limit) : 1,
  };
}

export function addPage<T, U, R extends PaginatedResult<T>>(
  existing: R,
  newPage: R,
  uniqSelector?: (T: T) => U,
  updatePageOptions = true,
): R {
  const merged = [...existing.values, ...newPage.values];
  return Object.assign({}, existing, {
    page: updatePageOptions ? newPage.page : existing.page,
    limit: updatePageOptions ? newPage.limit : existing.limit,
    total: updatePageOptions ? newPage.total : existing.total,
    pageSize: updatePageOptions ? newPage.pageSize : existing.pageSize,
    values: uniqSelector ? uniqBy(uniqSelector, merged) : merged,
  });
}

export function addItem<T, U, R extends PaginatedResult<T>>(
  existing: R,
  item: T,
  position: 'before' | 'after' = 'before',
  uniqFunc?: (T: T) => U,
): R {
  const merged = position === 'before' ? [item, ...existing.values] : [...existing.values, item];
  const values = uniqFunc ? uniqBy(uniqFunc, merged) : merged;
  return Object.assign({}, existing, { values, total: existing.total + 1 });
}

export function updateInList<T, U extends PaginatedResult<T>>(
  resource: U,
  selector: (item: T) => boolean,
  transform: (i: T) => T,
): U {
  return Object.assign({}, resource, {
    values: resource.values.map(item => when(selector, (t: T) => transform(t), item)),
  });
}

export function filterInList<T, U extends PaginatedResult<T>>(resource: U, filter: (item: T) => boolean): U {
  return Object.assign({}, resource, { values: resource.values.filter(filter) });
}
