import { asUserAuthor } from 'domain/shared/author';
import React from 'react';
import { UserProfile } from 'types/user';
import { CommunityUpdate, ContentCreateDTO } from 'types/content/update';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import LoadableResource from 'util/resource/Resource';
import useNotify from 'hooks/useNotify';
import UpdateForm from './UpdateForm';
import useLazyResource from 'util/resource/useLazyResource';
import { TinyCommunity } from 'types/company/community';

interface Props {
  community: TinyCommunity;
  setActive: (v: boolean) => void;
  user?: UserProfile;
  onComplete: () => void;
  update: CommunityUpdate;
}

export default function EditUpdateDialog(props: Props) {
  const api = communityUpdateAPI(props.community.id);
  const { user } = props;

  const author = asUserAuthor(user);
  const notify = useNotify();

  const [editUpdate, isSaving] = useLazyResource(
    (dto: ContentCreateDTO) => api.editUpdate(props.update.update.id, dto),
    {
      onSuccess: () => {
        props.onComplete();
        notify('success', 'Post updated!');
      },
      onFailure: () => {
        notify('error', 'Something went wrong when trying to update post');
      },
    },
  );

  const [attachmentsResource] = useResourceLegacy(
    () =>
      props.update.update.attachments.length > 0
        ? api.attachments.list(props.update.update.id)
        : Promise.resolve({ documents: [] }),
    [props.update.update.id],
  );

  return (
    <LoadableResource resource={attachmentsResource}>
      {attachments => (
        <UpdateForm
          titleDraft={props.update.update.title}
          contentDraft={props.update.update.content}
          bannerImage={props.update.update.bannerImage}
          attachments={attachments}
          author={author}
          selectedVisibility={props.update.visibility}
          linkPreview={props.update.update.linkPreview}
          onSave={(dto, visibility) => editUpdate({ ...dto, visibility })}
          saveButtonText="Save"
          isSaving={isSaving}
          onClose={() => props.setActive(false)}
          companyId={props.update.postedByCompanyId ?? props.update.communityId}
          community={props.community}
          contentId={props.update.update.id}
        />
      )}
    </LoadableResource>
  );
}
