import React, { useMemo } from 'react';
import UploadedFile from 'domain/shared/Files/File/UploadedFile';
import { DocumentDTO, DocumentDownloadDTO } from 'types/documents';
import { FileBeingUploaded, UploadStatus } from 'domain/shared/Files/Upload/useFileUpload';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, quarterSpacing } from 'ui/theme/themeConstants';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import { downloadBlob } from 'domain/shared/Files/actions';

interface Props {
  existingAttachments: DocumentDTO[];
  newAttachments: FileBeingUploaded[];
  attachmentIdList: string[];
  removeExistingAttachment: (documentId: UUID) => Promise<void>;
  removeNewAttachment: (temporaryId: string) => void;
  downloadAttachment: (documentId: string) => Promise<DocumentDownloadDTO>;
}

type AttachmentToRender = {
  id: string;
  name: string;
  status: UploadStatus;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDownload?: () => void;
};

const Container = styled.div`
  display: flex;
  margin-top: ${contentSpacing};
  gap: ${quarterSpacing};
  ${bluePlanetTheme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export default function AttachmentList(props: Props) {
  const notify = useNotify();
  const [downloadAttachment] = useLazyResource(
    (documentId: string) => props.downloadAttachment(documentId).then(response => downloadBlob(response)),
    {
      onFailure: () => notify('error', 'Could not download the document.'),
    },
  );
  const attachmentsToRender = useMemo(
    () =>
      (
        props.existingAttachments
          .filter(doc => props.attachmentIdList.includes(doc.id))
          .map(doc => {
            const res: AttachmentToRender = {
              id: doc.id,
              name: doc.name,
              status: 'COMPLETED' as UploadStatus,
              onClose: e => {
                e.stopPropagation();
                props.removeExistingAttachment(doc.id);
              },
              onDownload: () => downloadAttachment(doc.id),
            };
            return res;
          }) ?? []
      ).concat(
        props.newAttachments.map(att => ({
          id: att.tempId,
          name: att.file.name,
          status: att.status,
          onClose: e => {
            e.stopPropagation();
            props.removeNewAttachment(att.tempId);
          },
        })),
      ),
    [props.newAttachments, props.attachmentIdList],
  );

  return (
    <>
      {attachmentsToRender.length > 0 && (
        <Container>
          {attachmentsToRender.map(att => (
            <UploadedFile
              key={att.id}
              onClose={att.onClose}
              name={att.name}
              status={att.status}
              onDownload={att.onDownload}
            />
          ))}
        </Container>
      )}
    </>
  );
}
