import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import FollowButton from 'domain/companies/Follow/FollowButton';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/elements/buttons/Button';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { CompanyOverviewSection, companyUrls } from 'urls';
import { CompanyProfile } from 'types/company';
import useDialogHandler from 'hooks/useDialogHandler';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import { useAccess } from 'hooks/useAccess';
import SidebarActions from 'domain/companies/profile/SidebarActions';
import CompanyHeader from 'domain/companies/profile/header';
import styled from '@emotion/styled';
import { contentSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import useCompanyRole from 'hooks/useCompanyRole';
import { getViewAs } from 'domain/companies/roleUtils';
import BackofficeCompanyDetails from 'pages/Backoffice/shared/BackofficeCompanyDetails';
import ButtonList from 'ui/elements/buttons/ButtonList';
import ProfileSections from './ProfileSections';
import FundingRound from '../FundingRoundWidget/FundingRound';
import useFundingRound from 'pages/Company/Overview/useFundingRound';
import { useMediaQuery } from '@mui/material';
import CompanyMobileNavigation from 'domain/companies/profile/CompanyMobileNavigation';

interface Props {
  companyProfile: CompanyProfile & { calendlyUrl?: string };
  onClose: () => void;
  renderSidebar?: React.ReactNode;
  disableSubRouting?: boolean;
  initialTab?: CompanyOverviewSection;
}

const Grid = styled.div`
  display: grid;
  column-gap: ${contentSpacing};
  grid-template-columns: minmax(0, 2fr) 400px;
  grid-template-rows: auto;
  grid-template-areas:
    'header sidebar'
    'content sidebar'
    '. sidebar';

  ${bluePlanetTheme.breakpoints.down('lg')} {
    grid-template-columns: minmax(0, 2fr) 300px;
    grid-template-areas:
      'header header'
      'content sidebar';
  }

  ${bluePlanetTheme.breakpoints.down('md')} {
    display: block; // One column only - using display: block because MUI scrollable tab components break inside display: grid 🤷‍♂️
  }
`;

export default function Profile(props: Props) {
  const { companyProfile, disableSubRouting } = props;
  const { hasAccess } = useAccess();
  const { roles, hasScope } = useCompanyRole(props.companyProfile.slug);
  const dialogHandler = useDialogHandler();
  const hasAccessToCompany = hasAccess(companyProfile.id);
  const viewAs = getViewAs(roles, hasScope);
  const { resource: fundingRound } = useFundingRound(companyProfile.slug);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  return (
    <>
      {!disableSubRouting && <HeadTags title={companyProfile.name} />}
      <MaxWidth
        width="xl"
        style={
          isMobile
            ? {
                paddingBottom: '60px', // we need this padding to offset the space taken by the bottom navigation
              }
            : undefined
        }
      >
        <Grid>
          <div style={{ gridArea: 'header' }}>
            <CompanyHeader
              companyProfile={companyProfile}
              onEdit={() => {}}
              onBack={props.onClose}
              canEditCompany={false}
              action={
                <>
                  <ButtonList>
                    {hasAccessToCompany && !isMobile && (
                      <Link to={companyUrls.overview(companyProfile.slug, 'profile')}>
                        <Button size={isMobile ? 'small' : 'medium'} kind="primary">
                          Go to profile
                        </Button>
                      </Link>
                    )}
                    <FollowButton
                      size={isMobile ? 'small' : 'medium'}
                      company={props.companyProfile}
                      trackingKey="community-pitch-request-to-follow"
                      kind="primary"
                    />
                  </ButtonList>
                </>
              }
            />
            <FundingRound
              canEditCompany={false}
              className="u-content-spacing-top u-section-spacing-bottom"
              company={companyProfile}
              fundingRound={fundingRound}
            />
          </div>
          <div style={{ gridArea: 'sidebar' }}>
            {!isMobile && <SidebarActions viewAs="Visitor" company={companyProfile} />}
            {props.renderSidebar}
            <BackofficeCompanyDetails companyId={companyProfile.id} />
          </div>
          <div style={{ gridArea: 'content' }}>
            <ProfileSections
              companyProfile={companyProfile}
              viewAs={viewAs}
              hasScope={hasScope}
              disableSubRouting={disableSubRouting}
              initialTab={props.initialTab}
            />
          </div>
        </Grid>
        {isMobile && (
          <CompanyMobileNavigation
            canEditCompany={false}
            companyProfile={companyProfile}
            viewAs="Visitor"
            setViewAs={() => {}}
            reloadFundingRound={() => {}}
            fundingRoundResource={fundingRound}
          />
        )}
      </MaxWidth>
      {dialogHandler.isOpen && (
        <UserConversationDialog
          sharedContent={{ type: 'company', companyId: props.companyProfile.id }}
          conversation={{ type: 'new' }}
          closeConversation={dialogHandler.close}
        />
      )}
    </>
  );
}
