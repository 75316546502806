import { CompanyListItem } from 'types/company';
import { MyCommunity } from 'types/company/community';

export const companiesCommunitiesByRole = (companies: CompanyListItem[], communities: MyCommunity[]) => {
  const communitiesIManage = communities.filter(c => c.roles.includes('company_master'));
  const companiesIManage = companies.filter(c => c.roles.includes('company_master'));
  const myInvestments = companies.filter(c => c.roles.includes('investor'));
  const myCommunities = communities.filter(c => !c.roles.includes('company_master'));
  const myBoardPositions = companies.filter(c => c.roles.includes('board'));
  const companiesIFollow = companies.filter(c => c.roles.includes('prospective_investor'));

  return {
    communitiesIManage,
    companiesIManage,
    myInvestments,
    myCommunities,
    myBoardPositions,
    companiesIFollow,
  };
};
