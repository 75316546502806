import React, { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import Dialog, { DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { bluePlanetTheme } from 'ui/theme';
import ButtonList from 'ui/elements/buttons/ButtonList';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import Card from 'ui/views/cards/Card';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import IconButton from 'ui/elements/icons/IconButton';
import { notEmpty } from 'util/arrayUtils';
import StreamingText from './StreamingText';
import { pluralize } from 'util/stringUtils';
import { ClickAwayListener, Popper, useMediaQuery } from '@mui/material';
import GlitterIcon from 'ui/elements/icons/GlitterIcon';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { OpenAiTextCompletion, CompletionInput, getTextCompletion } from './completionsAPI';
import useAccessToken from 'auth/useAccessToken';
import { companiesAPIUrls, companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import ConsentsDialog from './ConsentDialog';
import featureToggle from 'featureToggle';

export function concat(chunks: OpenAiTextCompletion[]) {
  return chunks
    .map(d => d.choices[0].text)
    .join('')
    .replace(/$\\n/, '') // remove newline at start of line
    .trim();
}

interface Completions {
  currentIndex: number;
  completionState: {
    isComplete: boolean;
    inProgress: boolean;
  };
  completions: { id: string; value: string }[];
  chosenCompletionType?: CompletionInput;
}

function Body({
  onClose,
  streamData,
  onUse,
  onRetry,
  state,
  menuItems,
  setState,
}: {
  onClose: () => void;
  streamData: OpenAiTextCompletion[];
  onUse: () => void;
  state: Completions;
  menuItems: (inputText: string) => PopMenuItem[];
  onRetry: () => void;
  setState: (value: React.SetStateAction<Completions>) => void;
}) {
  return (
    <>
      {state.completionState.isComplete && state.completions[state.currentIndex]?.value}
      {state.completionState.inProgress && (
        <StreamingText lines={streamData} isComplete={state.completionState.isComplete} />
      )}

      <div className="u-content-spacing-top u-flex u-flex-space-between u-flex-align-center">
        <Button disabled={state.completionState.inProgress} onClick={onUse} kind="tertiary">
          Use suggestion
        </Button>
        <Button
          color="grey"
          kind="tertiary"
          disabled={state.completionState.inProgress || !state.chosenCompletionType}
          onClick={onRetry}
        >
          Try again
        </Button>
        <PopMenu
          renderAnchor={onClick => (
            <Button color="grey" kind="tertiary" onClick={onClick} disabled={state.completionState.inProgress}>
              More..
            </Button>
          )}
          items={menuItems(state.completions[state.currentIndex]?.value)}
        />
        <Button color="red" kind="tertiary" onClick={onClose}>
          Discard
        </Button>
        {state.completions.length > 1 && (
          <div>
            <IconButton
              disabled={state.currentIndex === 0}
              color="grey"
              size="small"
              className="u-quarter-spacing-right"
              onClick={() => setState(prev => ({ ...prev, currentIndex: prev.currentIndex - 1 }))}
            >
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
            <span className="text-small" style={{ color: bluePlanetTheme.bluePlanetPalette.grey.main }}>
              {state.currentIndex + 1} / {state.completions.length}
            </span>
            <IconButton
              disabled={state.currentIndex == state.completions.length - 1}
              size="small"
              color="grey"
              onClick={() => setState(prev => ({ ...prev, currentIndex: prev.currentIndex + 1 }))}
            >
              <ChevronRightIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
}

function AiDialog({
  isOpen,
  aiRef,
  onClose,
  streamData,
  onUse,
  onRetry,
  completions: state,
  menuItems,
  setCompletions: setState,
}: {
  isOpen: boolean;
  aiRef: RefObject<HTMLDivElement> | null;
  onClose: () => void;
  streamData: OpenAiTextCompletion[];
  onUse: () => void;
  completions: Completions;
  menuItems: (inputText: string) => PopMenuItem[];
  onRetry: () => void;
  setCompletions: (value: React.SetStateAction<Completions>) => void;
}) {
  const [showDiscardPrompt, setShowPrompt] = useState(false);

  return (
    <Popper
      open={isOpen}
      style={{ zIndex: 1300 }} // zIndex must be the same as zIndex used by dialogs
      anchorEl={aiRef?.current}
      placement="bottom-start"
    >
      <ClickAwayListener
        onClickAway={e => {
          e.preventDefault();
          e.stopPropagation();
          if (!showDiscardPrompt) {
            setShowPrompt(true);
          }
        }}
      >
        <div>
          {showDiscardPrompt && (
            <Dialog open onClose={() => setShowPrompt(false)} scroll="paper">
              <Title padding="half">
                Do you want to discard the {pluralize(state.completions.length || 1, 'suggestion', 'suggestions')}?
              </Title>
              <DialogActions padding="half">
                <ButtonList>
                  <Button
                    kind="primary"
                    onClick={() => {
                      setShowPrompt(false);
                      onClose();
                    }}
                  >
                    Discard
                  </Button>
                  <Button kind="tertiary" onClick={() => setShowPrompt(false)}>
                    Cancel
                  </Button>
                </ButtonList>
              </DialogActions>
            </Dialog>
          )}
          <Card elevation={1} className="text-body">
            <MaxWidth width="sm" style={{ width: aiRef?.current?.clientWidth }}>
              <Body
                {...{
                  onClose,
                  streamData,
                  onUse,
                  onRetry,
                  state,
                  menuItems,
                  setState,
                }}
              />
            </MaxWidth>
          </Card>
        </div>
      </ClickAwayListener>
    </Popper>
  );
}

export default function AiMenu({
  companyId,
  input,
  maxLength,
  onReplace,
  aiRef,
  style,
}: {
  companyId: number | undefined;
  input: string;
  maxLength?: number;
  onReplace: (value: string) => void;
  style?: CSSProperties;
  aiRef: RefObject<HTMLDivElement> | null;
}) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const consent = useSWR<{ openAiProcessingConsent: boolean }>(companiesAPIUrls.completions.getConsent());
  const consentResource = useSWRResource(consent);
  const [accessToken] = useAccessToken();

  const [isAiDialogOpen, setIsAiDialogOpen] = useState(false);

  const [originalInput] = useState(input);
  const [completions, setCompletions] = useState<Completions>({
    chosenCompletionType: undefined,
    currentIndex: 0,
    completionState: {
      isComplete: false,
      inProgress: false,
    },
    completions: [{ id: '', value: input }],
  });

  const [streamData, setStreamData] = useState<OpenAiTextCompletion[]>([]);
  const popMenuRef = useRef<HTMLButtonElement>(null);

  const closeAi = () => {
    setCompletions({ currentIndex: 0, completions: [], completionState: { isComplete: false, inProgress: false } });
    setIsAiDialogOpen(false);
  };
  const notify = useNotify();

  const [hasUsedSuggestions, setHasUsedSuggestion] = useState(false);
  const useSuggestion = () => {
    onReplace(completions.completions[completions.currentIndex]?.value);
    companiesApi.completions.markAsUsed(completions.completions[completions.currentIndex].id);
    setHasUsedSuggestion(true);

    closeAi();
  };

  const showAiSuggestion = (input: CompletionInput) => {
    setStreamData([]);
    setIsAiDialogOpen(true);
    setCompletions(prev => ({ ...prev, completionState: { inProgress: true, isComplete: false } }));
    getTextCompletion(
      accessToken.state === 'authenticated' ? accessToken.accessToken : undefined,
      companyId,
      input,
      value => setStreamData(prev => [...prev, ...value]),
      result => {
        const asString = concat(result);

        setCompletions(prev => ({
          completionState: {
            inProgress: false,
            isComplete: true,
          },
          chosenCompletionType: input,
          currentIndex: prev.completions.length,
          completions: [...prev.completions, { id: result[0].id, value: asString }],
        }));
      },
      () => notify('error', 'Something went wrong. Please try again later.'),
    );
  };

  const enableMakeItLonger = !maxLength || maxLength > input.length;

  const aiMenuItems = (inputText: string) =>
    [
      {
        onClick: async () => {
          showAiSuggestion({ value: inputText, type: 'Shorter', maxLength });
        },
        text: <>Make it shorter</>,
      },
      enableMakeItLonger
        ? {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Longer', maxLength });
            },
            text: <>Make it longer</>,
          }
        : undefined,
      {
        onClick: async () => {
          showAiSuggestion({ value: inputText, type: 'SimplerLanguage', maxLength });
        },
        text: <>Use simpler langauge</>,
      },
      {
        onClick: async () => {
          showAiSuggestion({ value: inputText, type: 'MoreEngaging', maxLength });
        },
        text: <>Make it more engaging</>,
      },
      {
        subMenu: [
          {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Professional', maxLength });
            },
            text: <>Professional</>,
          },
          {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Casual', maxLength });
            },
            text: <>Casual</>,
          },
          {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Confident', maxLength });
            },
            text: <>Confident</>,
          },
          {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Playful', maxLength });
            },
            text: <>Playful</>,
          },
          {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Straightforward', maxLength });
            },
            text: <>Straigth forward</>,
          },
          {
            onClick: async () => {
              showAiSuggestion({ value: inputText, type: 'Friendly', maxLength });
            },
            text: <>Friendly</>,
          },
        ],
        text: <>Change tone</>,
      },
      hasUsedSuggestions && originalInput !== inputText
        ? {
            onClick: () => {
              onReplace(originalInput);
              closeAi();
            },
            text: <>Revert to original text</>,
          }
        : undefined,
    ].filter(notEmpty);

  const [showVerifyConsent, setShowVerifyConsent] = useState(false);

  const [giveConsent, isGivingConsent] = useLazyResource(
    () => {
      return companiesApi.completions.postConsent(companyId);
    },
    {
      onSuccess: () => {
        consentResource.mutate();
        setShowVerifyConsent(false);
        popMenuRef.current?.click();
      },
      onFailure: () => notify('error', 'Something went wrong. Please try again later.'),
    },
  );

  const [userHasGivenConsent, setUserHasGivenConsent] = useState(false);
  useEffect(() => {
    if (consentResource.resource.state === 'fetched') {
      setUserHasGivenConsent(consentResource.resource.resource.openAiProcessingConsent);
    }
  }, [consentResource.resource.state]);

  if (!featureToggle.isAIMenuEnabled() || isMobile) {
    return null;
  }

  // AI makes up lots of strange text if we send it no input. This should only be used to improve existing texts at the moment, not generate from nothing.
  if (input.length < 20) {
    return null;
  }

  return (
    <>
      <ConsentsDialog
        open={showVerifyConsent}
        isSaving={isGivingConsent}
        onClose={() => setShowVerifyConsent(false)}
        onSuccess={() => {
          setUserHasGivenConsent(true);
          giveConsent(undefined);
        }}
      />
      <AiDialog
        isOpen={isAiDialogOpen}
        onClose={closeAi}
        aiRef={aiRef}
        onUse={useSuggestion}
        streamData={streamData}
        completions={completions}
        menuItems={aiMenuItems}
        onRetry={() => (completions.chosenCompletionType ? showAiSuggestion(completions.chosenCompletionType) : {})}
        setCompletions={setCompletions}
      />

      {userHasGivenConsent ? (
        <PopMenu
          renderAnchor={onClick => (
            <>
              <Button buttonRef={popMenuRef} style={style} kind="tertiary" onClick={onClick}>
                <GlitterIcon fontSize="small" className="u-quarter-spacing-right" />
                Ask AI
              </Button>
            </>
          )}
          items={aiMenuItems(input)}
        />
      ) : (
        <Button buttonRef={popMenuRef} style={style} kind="tertiary" onClick={() => setShowVerifyConsent(true)}>
          <GlitterIcon fontSize="small" className="u-quarter-spacing-right" />
          Ask AI
        </Button>
      )}
    </>
  );
}
