import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';

interface Props {
  parentMenuOpen: boolean;
  subMenu?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  tabIndex?: number;
  rightAnchored?: boolean;
  ContainerProps?: any;
  disabled?: boolean;
  onClick?: () => void;
}

const NestedMenuItem = React.forwardRef((props: Props, ref) => {
  const {
    parentMenuOpen,
    subMenu,
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    rightAnchored,
    ...MenuItemProps
  } = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = React.useRef<HTMLLIElement>(null);
  React.useImperativeHandle(ref, () => menuItemRef.current);

  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = React.useRef<HTMLDivElement>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!subMenu) return;
    setIsSubMenuOpen(true);

    if (ContainerProps?.onMouseEnter) {
      ContainerProps.onMouseEnter(event);
    }
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!subMenu) return;
    setIsSubMenuOpen(false);

    if (ContainerProps?.onMouseLeave) {
      ContainerProps.onMouseLeave(event);
    }
  };

  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement;

    for (const child of menuContainerRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleFocus = (event: React.FocusEvent) => {
    if (event.target === containerRef.current && subMenu) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      return;
    }

    if (isSubmenuFocused()) {
      event.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument?.activeElement;

    if (['ArrowUp', 'ArrowDown'].includes(event.key) && isSubMenuOpen) {
      setIsSubMenuOpen(false);
    }

    if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (event.key === 'ArrowRight' && event.target === containerRef.current && event.target === active) {
      // this should open the submenu, but I couldn't get it to work.
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  let tabIndex;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <MenuItem {...MenuItemProps} data-open={!!open || undefined} className={className} ref={menuItemRef}>
        {children}
        {subMenu && <div style={{ flexGrow: 1 }} />}
        {subMenu && <ChevronRightIcon fontSize="small" />}
      </MenuItem>
      <Menu
        hideBackdrop
        style={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: rightAnchored ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: rightAnchored ? 'right' : 'left',
        }}
        open={!!open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
      >
        {subMenu && (
          <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
            {subMenu}
          </div>
        )}
      </Menu>
    </div>
  );
});

NestedMenuItem.displayName = 'NestedMenuItem';

export default NestedMenuItem;
