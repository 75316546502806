import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import AntifraudAPI, { Report } from 'apis/AntifraudAPI';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import ReportDialog from 'domain/antifraud/ReportDialog';
import React, { useState } from 'react';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import FlagIcon from 'ui/elements/icons/FlagIcon';
import IconButton from 'ui/elements/icons/IconButton';
import Resource from 'util/resource/Resource';

interface Props {
  cwUserId: string;
}

export default function UserProfileDropdown(props: Props) {
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const { resource: userProfileResource } = useSelfUserProfile();

  function handleClose() {
    setMenuAnchorEl(null);
  }

  function handleOpen(e: React.MouseEvent<HTMLElement>) {
    setMenuAnchorEl(menuAnchorEl ? null : e.currentTarget);
  }

  return (
    <Resource resource={userProfileResource}>
      {selfUser =>
        selfUser.cwUserId !== props.cwUserId ? (
          <>
            <IconButton color="indigo" onClick={handleOpen}>
              <EllipsisVertical />
            </IconButton>
            <Menu
              open={isMenuOpen}
              anchorEl={menuAnchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <MenuItem
                onClick={() => {
                  setIsReportDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText>Report user</ListItemText>
              </MenuItem>
            </Menu>
            <ReportDialog
              title="What would you like to report?"
              postReport={(report: Report) => AntifraudAPI.report.user(props.cwUserId, report)}
              isOpen={isReportDialogOpen}
              onClose={() => setIsReportDialogOpen(false)}
              reasons={['Spam', 'Scams or fraud', 'Fake account', 'Incorrect information', 'Representing someone else']}
            />
          </>
        ) : null
      }
    </Resource>
  );
}
