import cx from 'classnames';
import React from 'react';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import IconButton from 'ui/elements/icons/IconButton';
import { quarterSpacing } from 'ui/theme/themeConstants';
import styles from './cards.scss';

interface Props {
  className?: string;
  heading: JSX.Element | string;
  subHeading?: string;
  icon?: JSX.Element;
  onClose?: () => void;
  tooltip?: JSX.Element | false;
}
export default function CardHeading({ className, heading, subHeading, icon, onClose, tooltip }: Props) {
  return (
    <div className={cx('u-flex u-flex-space-between u-flex-align-center u-content-spacing-bottom', className)}>
      <div className={styles.cardHeading}>
        {icon && (
          <IconAvatar className="u-half-spacing-right" color="blue">
            {icon}
          </IconAvatar>
        )}
        <div>
          <h3 className="u-flex text-body text-weight-medium">{heading}</h3>
          {subHeading && <p className="u-quarter-spacing-top text-metadata text-uppercase">{subHeading}</p>}
        </div>
      </div>
      {tooltip}
      {onClose && (
        <IconButton
          color="indigo"
          onClick={onClose}
          aria-label="Close"
          size="small"
          style={{ marginRight: `-${quarterSpacing}`, marginTop: `-${quarterSpacing}` }}
        >
          <CloseIcon fontSize="small" color="grey" />
        </IconButton>
      )}
    </div>
  );
}
