import React, { useState } from 'react';
import PleaseEnableCookiesDialog from 'CookieConsent/PleaseEnableCookiesDialog';

type Lang = 'en';

type Capitalize = 'capitalize' | 'normal';

const filterJoin = (list: (string | undefined)[], separator = ' ') => list.filter(Boolean).join(separator);
export default (lang: Lang) => {
  switch (lang) {
    case 'en':
      return {
        placeholders: {
          noUsersFound: 'No users found',
          noRowsFounds: (resourceName: string) => `No ${resourceName} found`,
          email: 'jane.doe@acme.com',
          fullName: 'Jane Doe',
          date: '31/03/2017',
          phone: '+4711223344',
          address: '1 Wall Street',
          city: 'New York City',
          region: 'New York',
          country: 'USA',
          website: 'https://example.com',
        },
        errors: {
          tryAgain: 'Something unexpected happened. Please try again later.',
          create: (resourceName: string) => `Could not create ${resourceName} right now, please try again later.`,
          save: (resourceName: string) => `Could not save ${resourceName} right now, please try again later.`,
          send: (resourceName: string) => `Could not send ${resourceName} right now, please try again later.`,
        },
        manageUsers: 'Manage users',
        documents: {
          copyLink: 'Copy document link to clipboard',
          linkCopied: 'Document link copied to clipboard!',
          seenBy: 'Number of downloads',
          deleteDocument: 'Delete document',
        },
        community: {
          removeFromCommunity: 'Remove from community',
          removeFromCommunityShortDesc: (companyName: string) => `Remove ${companyName} from community`,
          removeFromCommunityDesc: (companyName: string) =>
            `${companyName} will be removed from the community. They will not be able to post updates to
          your community feed, and will not be visible in the community list anymore.
          If any of your users has direct access to ${companyName}, they will keep that access.`,
          tooManyFilters: {
            msg: "We didn't find any companies with these filters. Did you add too many filters?",
          },
          admin: {
            leaveMessage: function LeaveMessage(communityName: string) {
              return (
                <>
                  Are you sure you want to leave the community, <em>{communityName}</em>?<br />
                  The audience in the community will no longer be able to follow your company, and you will not be able
                  to target content to their users.
                  <br />
                  Users that already follow your company will keep their access independent of communities.
                </>
              );
            },
          },
        },
        common: {
          close: {
            label: 'Close',
            resource: (resourceName: string) => `Close ${resourceName.toLowerCase()}`,
          },
          noCancel: 'No, cancel',
          confirm: 'Confirm',
          saveOrCreate: {
            create: 'Create',
            save: 'Save',
            confirm: (action: 'Save' | 'Create', name?: string) => filterJoin([action, name]),
            loading: (action: 'Save' | 'Create') => (action === 'Save' ? 'Saving…' : 'Creating…'),
            success: (action: 'Save' | 'Create', name?: string, plurality: 'singular' | 'plural' = 'singular') =>
              filterJoin([
                name,
                name && plurality === 'plural' ? 'have been' : 'has been',
                action === 'Save' ? 'saved' : 'created',
              ]),
          },
          seeMore: (resourceName?: string) => filterJoin(['Show more', resourceName]),
          closed: {
            success: (resourceName?: string) => filterJoin([resourceName, 'closed']),
            error: (resourceName?: string) =>
              filterJoin(['Could not close', resourceName, 'right now, please try again later.']),
          },
          email: (capitalize: Capitalize = 'normal', plurality: 'plural' | 'singular' = 'singular') =>
            filterJoin([capitalize === 'capitalize' ? 'Email' : 'email', plurality === 'plural' ? 's' : ''], ''),
          communityOrCompany: (isCommunity = false, capitalize: 'capitalize' | 'normal' = 'normal') =>
            isCommunity
              ? capitalize === 'capitalize'
                ? 'Community'
                : 'community'
              : capitalize === 'capitalize'
              ? 'Company'
              : 'company',
          unsavedChangesPrompt: 'You have unsaved changes, are you sure you want to leave this page?',
        },
        validation: {
          notEmpty: (resourceName: string, suffix?: string) =>
            filterJoin([`${resourceName} cannot be empty`, suffix && ` ${suffix}`]),
        },
        contactCompany: {
          titleUsers: 'Contact',
          titleAdmins: 'Inbox',
        },
        discussions: {
          topic: {
            create: 'Create new discussion topic in',
            edit: (title: string) => `Edit topic "${title}"`,
          },
        },
        dropzone: {
          dropFileHere: 'Drop file here or click to select one',
        },
      };
  }
};

export function ContactUsIfProblem() {
  const [showEnableCookieDialog, setShowEnableCookieDialog] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    const _Intercom: Intercom_.IntercomStatic = (window as any).Intercom;
    if (!_Intercom) {
      e.preventDefault();
      setShowEnableCookieDialog(true);
    }
  };

  return (
    <>
      If the problem persists, don&apos;t hesitate to{' '}
      <button className="intercomChatStarter text-link" onClick={onClick}>
        get in touch
      </button>
      .
      <PleaseEnableCookiesDialog open={showEnableCookieDialog} onClose={() => setShowEnableCookieDialog(false)} />
    </>
  );
}
