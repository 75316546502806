import config from 'config';

const isMentorNetwork = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [259, 260];
    case 'beta':
      return [452, 453, 454, 455, 456, 457, 66, 624];
    case 'production':
      return [452, 453, 454, 455, 456, 457, 66, 624];
    default:
      return [];
  }
};

const isMentorMasterNetwork = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [246, 274];
    case 'beta':
      return [451, 471, 622, 623];
    case 'production':
      return [451, 471, 622, 623, 2317, 2315, 2318, 2316];
    default:
      return [];
  }
};

const isKrisementorNetwork = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [274];
    case 'beta':
      return [471, 623];
    case 'production':
      return [471, 623];
    default:
      return [];
  }
};

const communityHasBoardRoom = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [
        113, // kpy steffen
      ];
    case 'beta':
      return [
        66, // Test Company AS
        119, // Connect Ban Bergen
        971, // VIS startup community
      ];
    case 'production':
      return [
        66, // Test Company AS
        119, // Connect Ban Bergen
        971, // VIS startup community
      ];
    default:
      return [];
  }
};

const communityIsConnectedToDnb = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [
        442, // TNT NXT
      ];
    case 'beta':
      return [
        2867, // DNB NXT 2022
        1788, // DNB NXT Demo community
        1128, // DNB NXT Healthcare Venture Session
        2472, // DNB NXT Healthcare Venture Session 2021
      ];
    case 'production':
      return [
        2867, // DNB NXT 2022
        1788, // DNB NXT Demo community
        1128, // DNB NXT Healthcare Venture Session
        2472, // DNB NXT Healthcare Venture Session 2021
      ];
    default:
      return [];
  }
};

const communityIs100Pitches = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [445, 1118];
    case 'beta':
      return [3541];
    case 'production':
      return [3541, 4328];
    default:
      return [];
  }
};

const communityCanAssignRooms = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [
        323, // [test] investor arena
        434, // use global mentor db
      ];
    case 'beta':
    case 'production':
      return [
        // 157, // Test Investor Arena - test community
        // 869, //	Test Cohort Room
        // 891, //	OUI Program Room - slettet
        // 901, //	KS test1 - test community
        // 996, //	SEB program room - test community, ingen selskaper.
        // 1756, // QCF Class 14 Program - slettet
        // 1209, // Oxford Alumni Community (Demo community) - slettet
        // 1872, // Brinc Foodtech - 1 selskap i community - sit brukt 2021
        // 1873, // Brinc IOT - 1 selskap i community - sist brukt 2021
        // 1943, // RevTech Labs Class 15 Program Room
        // 2531, // RevTech Labs Class 16 Program Room
        // 2321, // Momentum Labs Program Room  - test community
        // 2340, // Colab Larvik - brukt for ett selskap, jan 2022. knut sier det er greit å kille.
        // 2505, // Hi5 - aldri brukt
        // 2506, // GRUNDERIET - aldri brukt
        // 2583, // 6AM T:lab Program Room  - community har ingen selskap
        // 3102, // RevTech Labs Class 17 - slettet
        66, // test community
        3532, // RevTech Labs Class 18
        3930, // RevTech Labs Class 19
        4666, // RevTech Labs Class 21
      ];
    default:
      return [];
  }
};

export const visibleCommunities = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'beta':
    case 'production':
      return {
        nxt: 'dnb-nxt-arena',
        health: 'health-arena',
        green: 'green-arena',
        fintech: 'fintech-arena',
      };
    case 'test':
    default:
      return {
        nxt: 'tnt-nxt',
        health: 'acne-inc',
        green: '420-arena',
        fintech: 'test-fintech-arena',
      };
  }
};

const isAIMenuEnabled = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return true;
    case 'beta':
      return true;
    case 'production':
      return true;
    default:
      return false;
  }
};

const isAiOnboardingEnabled = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return true;
    case 'beta':
      return true;
    case 'production':
      return true;
    default:
      return false;
  }
};

const communityHasDiscussions = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [
        12, // good norwegian
        442, // $TNT NXT,
      ];
    case 'beta':
    case 'production':
      return [
        118, // Connect vest
        119, // Connect ban bergen
        451, // Innovasjon Norges mentorprogram
        452, // Connect Vest mentor
        453, // IKT Norge
        454, // Mentor Sørlandet
        455, // Connect Midt-Norge Mentor
        456, // Kunnskapsparken Bodø
        457, // Fyrin
        471, // INs Covid-19 nasjonale mentorprogram (10 t)
        1864, // Connect Vest kvalifiseringsrom
        1875, //Aleap mentor
        2315, // Innovasjon Norges mentorprogram #2
        2316, // Innovasjon Norges mentorprogram #3
        2317, // Innovasjon Norges mentorprogram #4
        2318, // Innovasjon Norges mentorprogram #5
      ];
    default:
      return [];
  }
};

const communityHasDealLeads = () => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [
        12, // good norwegian
      ];
    case 'beta':
    case 'production':
      return [
        66, // Test Community
        119, // Connect BAN
        451, // Innovasjon Norges mentorprogram
        452, // Connect Vest mentor
        453, // IKT Norge
        454, // Mentor Sørlandet
        455, // Connect Midt-Norge Mentor
        456, // Kunnskapsparken Bodø
        457, // Fyrin
        471, // INs Covid-19 nasjonale mentorprogram (10 t)
        1864, // Connect Vest kvalifiseringsrom
        1875, // Aleap mentor
        2315, // Innovasjon Norges mentorprogram #2
        2316, // Innovasjon Norges mentorprogram #3
        2317, // Innovasjon Norges mentorprogram #4
        2318, // Innovasjon Norges mentorprogram #5
        3047, // BankTech Ventures Pipeline
      ];
    default:
      return [];
  }
};

// Deeptech alliance wants to invite regular users to their community
// They are not investors, mentors or advisors. To avoid introducing yet another role,
// we rename the advisor role for that community. We do want to make communities less role dependent in the future,
// and let them use labels or other tools to group users. Until then, this is a workaround.
const communityHasAdvisors = (communityId: number) => {
  switch (config.BUILD_ENVIRONMENT.toLowerCase()) {
    case 'test':
      return [1135].every(a => a !== communityId);
    case 'beta':
    case 'production':
      // these communities does NOT have advisors
      return [
        4478, // DeepTech Alliance - Clean energy 2024 Applications
        4479, // DeepTech Alliance - Clean Energy 2024
        4480, // Deeptech Alliance
        4522, // DeepTech Alliance - Advanced manufacturing 2024
        4523, // DeepTech Alliance - Public Utility 2024
      ].every(a => a !== communityId);

    default:
      return [];
  }
};

export default {
  isMentorMasterNetwork: (communityId?: number) => isMentorMasterNetwork().some(a => a === communityId),
  isMentorCommunity: (communityId?: number) =>
    isMentorNetwork().some(a => a === communityId) || isMentorMasterNetwork().some(a => a === communityId),
  isKrisementorNetwork: (communityId?: number) => isKrisementorNetwork().some(a => a === communityId),
  communityHasBoardRoom: (communityId?: number) => communityHasBoardRoom().some(a => a === communityId),
  communityIsConnectedToDnb: (communityId?: number) => communityIsConnectedToDnb().some(a => a === communityId),
  communityIs100Pitches: (communityId?: number) => communityIs100Pitches().some(a => a === communityId),
  communityCanAssignRooms: (communityId?: number) => communityCanAssignRooms().some(a => a === communityId),
  communityHasDiscussions: (communityId?: number) => communityHasDiscussions().some(a => a === communityId),
  communityHasDealLeads: (communityId?: number) => communityHasDealLeads().some(a => a === communityId),
  communityHasAdvisors: (communityId: number) => communityHasAdvisors(communityId),
  isAIMenuEnabled: () => isAIMenuEnabled(),
  isAiOnboardingEnabled: () => isAiOnboardingEnabled(),
};
