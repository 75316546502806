import React, { useEffect } from 'react';
import { ICompany } from 'types/company';
import Update from './Update';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import LoadableResource from 'util/resource/Resource';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import useCompanyRole from 'hooks/useCompanyRole';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import IconButton from '@mui/material/IconButton';
import { companyUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { useCompanyUpdate } from 'apis/ContentAPI/useCompanyUpdate';
import useSelector from 'hooks/useSelector';
import { Link } from 'react-router-dom';
import { companyUrls } from 'urls';
import Button from 'ui/elements/buttons/Button';

interface Props {
  company: ICompany;
  dialogHandler: DialogHandlerProps;
  onDelete: () => void;
  updateId: UUID;
}

export default function UpdateDialog(props: Props) {
  const { company, updateId } = props;

  const { resource: updateResource, mutate } = useCompanyUpdate(company.id, updateId);
  const { userProfiles } = useSelector(state => ({
    userProfiles: state.userProfiles.items,
  }));

  const api = companyUpdateAPI(props.company.id);

  useEffect(() => {
    api.markAsRead(updateId);
  }, []);

  const { hasScope } = useCompanyRole(props.company.slug);

  return (
    <Dialog open onClose={props.dialogHandler.close} maxWidth="md">
      <LoadableResource
        resource={updateResource}
        renderError={() => (
          <>
            <Title onClose={props.dialogHandler.close}>Private update</Title>
            <Content>
              Oops! This update isn&apos;t accessible. It might be private or no longer available.
              <div>
                If you think this is a mistake,{' '}
                <Link
                  className="text-link"
                  to={companyUrls.overview(props.company.slug, 'updates', { isContactingCompany: true })}
                >
                  please contact ${company.name}.
                </Link>
              </div>
            </Content>
            <DialogActions>
              <Button kind="primary" onClick={props.dialogHandler.close}>
                {' '}
                Close
              </Button>
            </DialogActions>
          </>
        )}
      >
        {update => {
          const creator = userProfiles.find(cu => cu.cwUserId === update.update.creatorCwUserId);

          return (
            <Update
              key={`update-preview-${update.update.id}`}
              update={update}
              creator={creator}
              company={company}
              onArchive={() => {}}
              onDelete={props.onDelete}
              onEdit={() => mutate()}
              canEditPost={hasScope('Write', 'Update')}
              viewType="read more"
              headingActions={
                <IconButton className="u-half-spacing-left" onClick={props.dialogHandler.close}>
                  <CloseIcon />
                </IconButton>
              }
            />
          );
        }}
      </LoadableResource>
    </Dialog>
  );
}
