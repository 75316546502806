import { Grid, Tooltip } from '@mui/material';
import React from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { Update } from 'types/content';
import { ICwUserId, UserProfile } from 'types/user';
import ChatAddIcon from 'ui/elements/icons/ChatAddIcon';
import LikeIcon from 'ui/elements/icons/LikeIcon';
import Button from 'ui/elements/buttons/Button';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useDialogHandler from 'hooks/useDialogHandler';

interface Props {
  content: Update;
  onCommentClick: () => void;
  onLike: () => void;
  hasLiked: boolean;
  getLikes: (contentId: string, paginationOptions?: PaginationOptions) => Promise<PaginatedResult<ICwUserId>>;
  creator?: UserProfile;
}

export default function UpdateActions(props: Props) {
  const { hasLiked } = props;

  const contactUserDialogHandler = useDialogHandler();

  return (
    <>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Button onClick={props.onLike} color={hasLiked ? 'blue' : 'grey'} kind="tertiary">
          <Tooltip title="Like">
            <>
              <LikeIcon className="u-quarter-spacing-right" /> Like
            </>
          </Tooltip>
        </Button>
        <Button kind="tertiary" onClick={props.onCommentClick} color="grey">
          <span className="u-flex u-flex-align-center">
            <ChatAddIcon className="u-quarter-spacing-right" fontSize="small" />
            Comment
          </span>
        </Button>
        {props.creator && (
          <Button kind="tertiary" color="grey" onClick={contactUserDialogHandler.open}>
            <ChatBubblesIcon className="u-quarter-spacing-right" fontSize="small" />
            Message
          </Button>
        )}
      </Grid>
      {props.creator && contactUserDialogHandler.isOpen && (
        <UserConversationDialog
          conversation={{ type: 'withUsers', withCwUserIds: [props.creator.cwUserId] }}
          closeConversation={contactUserDialogHandler.close}
        />
      )}
    </>
  );
}
