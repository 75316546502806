import { Badge, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import UserListDialog from 'domain/users/UserListDialog';
import DeleteDocumentDialog from 'domain/companies/Documents/Delete/DeleteDocumentDialog';
import useDialogHandler from 'hooks/useDialogHandler';
import React, { useState } from 'react';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { DocumentDTO } from 'types/documents';
import { ICwUserId } from 'types/user';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import UsersIcon from 'ui/elements/icons/UsersIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import i18n from 'util/i18n';
import IconButton from 'ui/elements/icons/IconButton';

interface Props {
  onCopy: (documentId: DocumentDTO['id']) => void;
  document: DocumentDTO;
  mobile: boolean;
  companyId: number;
  listDownloadedBy: (PaginationOptions: PaginationOptions) => Promise<PaginatedResult<ICwUserId>>;
  deleteDocument: (documentId: string) => Promise<any>;
  isDeleting: boolean;
}

function StyleBadge({ downloads }: { downloads: number }) {
  return (
    <Badge badgeContent={downloads > 9999 ? '10k+' : downloads} color="primary">
      <UsersIcon />
    </Badge>
  );
}

export default function DocumentAction(props: Props) {
  const menuDialogHandler = useDialogHandler();
  const downloadedByDialogHandler = useDialogHandler();
  const [showDeleteDocument, setShowDeleteDocument] = useState(false);
  const downloads = props.document.downloads;

  const menu = props.mobile ? (
    <div>
      <IconButton onClick={menuDialogHandler.open}>
        <EllipsisVertical />
      </IconButton>
      <Drawer anchor="bottom" open={menuDialogHandler.isOpen} onClose={menuDialogHandler.close}>
        <List>
          <ListItemButton onClick={downloadedByDialogHandler.open}>
            <ListItemIcon>
              <StyleBadge downloads={downloads} />
            </ListItemIcon>
            <ListItemText primary="Downloaded by" />
          </ListItemButton>
          <ListItemButton onClick={() => props.onCopy(props.document.id)}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Copy link" />
          </ListItemButton>
          <ListItemButton onClick={() => setShowDeleteDocument(true)}>
            <ListItemIcon>
              <TrashIcon />
            </ListItemIcon>
            <ListItemText primary="Delete document" />
          </ListItemButton>
        </List>
      </Drawer>
    </div>
  ) : (
    <>
      <Tooltip title={i18n('en').documents.seenBy} placement="left">
        <IconButton
          color="indigo"
          hoverColor="blue"
          className="u-half-spacing-right"
          onClick={downloadedByDialogHandler.open}
        >
          <StyleBadge downloads={downloads} />
        </IconButton>
      </Tooltip>
      <Tooltip title={i18n('en').documents.copyLink}>
        <IconButton
          color="indigo"
          hoverColor="blue"
          className="u-half-spacing-right"
          onClick={() => props.onCopy(props.document.id)}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={i18n('en').documents.deleteDocument} disableFocusListener>
        <IconButton color="indigo" hoverColor="red" onClick={() => setShowDeleteDocument(true)}>
          <TrashIcon />
        </IconButton>
      </Tooltip>
    </>
  );
  return (
    <div className="u-flex-end">
      {menu}
      <UserListDialog
        isOpen={downloadedByDialogHandler.isOpen}
        closeDialog={downloadedByDialogHandler.close}
        title="Downloaded by"
        fetchResource={props.listDownloadedBy}
      />
      <DeleteDocumentDialog
        isOpen={showDeleteDocument}
        onClose={() => setShowDeleteDocument(false)}
        document={props.document}
        onDelete={() => props.deleteDocument(props.document.id)}
        isDeleting={props.isDeleting}
      />
    </div>
  );
}
